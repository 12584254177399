import React, { useState } from 'react';
import { z } from 'zod';
import { v4 as uuidv4 } from 'uuid';

import type { IRegisterBankAccountParams } from '../../hooks/useFantomStarterAdminApi';

import { cross } from '../../assets';

export const bankAccountSchema = z.object({
  customerId: z.string().min(1, 'Customer ID is required.'),
  bank_name: z.string().min(1, 'Bank name is required.'),
  account_number: z.string().min(1, 'Account number is required.'),
  routing_number: z.string().min(1, 'Routing number is required.'),
  account_owner_name: z.string().min(1, 'Account owner name is required.'),
  active: z.boolean(),
  address: z.object({
    street_line_1: z.string().min(1, 'Street line 1 is required.'),
    street_line_2: z.string().optional(),
    city: z.string().min(1, 'City is required.'),
    state: z
      .string()
      .regex(/^[A-Z0-9-]{1,3}$/, 'Invalid ISO 3166-2 subdivision code.'),
    postal_code: z.string().min(1, 'Postal code is required.'),
    country: z
      .string()
      .length(3, 'Country code must be ISO 3166-1 alpha-3.')
      .regex(/^[A-Z]{3}$/, 'Invalid country code format.'),
  }),
  idempotencyKey: z.string().min(1, 'Idempotency Key is required.'),
});

interface RegisterBankFormProps {
  customerId: string;
  onCancel: () => void;
  onSave: (formData: IRegisterBankAccountParams) => Promise<void>;
}

const RegisterBankForm: React.FC<RegisterBankFormProps> = ({
  customerId,
  onCancel,
  onSave,
}) => {
  const [formData, setFormData] = useState<IRegisterBankAccountParams>({
    customerId,
    bank_name: 'Chase',
    account_number: '12345678901',
    routing_number: '123456789',
    account_owner_name: 'John Doe',
    active: true,
    address: {
      street_line_1: '123 Washington St',
      street_line_2: 'Apt 2F',
      city: 'New York',
      state: 'NY',
      postal_code: '10001',
      country: 'USA',
    },
    idempotencyKey: uuidv4(),
  });
  const [isRegistering, setIsRegistering] = useState<boolean>(false);

  const [errors, setErrors] = useState<Record<string, string>>({});

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    const [field, subfield] = name.split('.');

    if (subfield) {
      setFormData((prev) => ({
        ...prev,
        address: { ...prev.address, [subfield]: value },
      }));
    } else {
      setFormData((prev) => ({ ...prev, [field]: value }));
    }
  };

  const handleSave = async () => {
    setIsRegistering(true);
    try {
      bankAccountSchema.parse(formData);
      await onSave(formData);
    } catch (err) {
      if (err instanceof z.ZodError) {
        const validationErrors: Record<string, string> = {};
        err.errors.forEach((error) => {
          const fieldName = error.path.join('.');
          validationErrors[fieldName] = error.message;
        });
        setErrors(validationErrors);
      } else {
        console.error(err);
      }
    } finally {
      setIsRegistering(false);
    }
  };

  return (
    <section className="flex flex-col px-6 pt-6 pb-4 bg-white rounded-md shadow-sm border border-slate-200 max-w-[600px] z-50">
      <header className="mb-4">
        <div className="flex justify-between items-center mb-2">
          <h1 className="text-2xl font-semibold text-slate-900">
            Register Bank Account
          </h1>
          <img
            loading="lazy"
            src={cross}
            className="w-6 h-6 cursor-pointer"
            alt="Icon"
            onClick={onCancel}
          />
        </div>
        <p className="text-base text-slate-600">
          Please enter the bank account details for registration.
        </p>
      </header>

      <form className="flex flex-col gap-4">
        {[
          { label: 'Bank Name', name: 'bank_name' },
          { label: 'Account Number', name: 'account_number' },
          { label: 'Routing Number', name: 'routing_number' },
          { label: 'Account Owner Name', name: 'account_owner_name' },
          { label: 'Street Line 1', name: 'address.street_line_1' },
          { label: 'Street Line 2', name: 'address.street_line_2' },
          { label: 'City', name: 'address.city' },
          { label: 'State', name: 'address.state' },
          { label: 'Postal Code', name: 'address.postal_code' },
          { label: 'Country', name: 'address.country' },
        ].map(({ label, name }) => (
          <div key={name} className="flex items-center">
            <label className="w-64 font-medium text-slate-700">{label}</label>
            <div className="flex-1">
              <input
                type="text"
                name={name}
                value={
                  name.includes('address')
                    ? formData.address[
                        name.split('.')[1] as keyof typeof formData.address
                      ] || ''
                    : (formData[name as keyof IRegisterBankAccountParams] as
                        | string
                        | '')
                }
                onChange={handleChange}
                placeholder={`Enter ${label}`}
                className="w-full py-2 px-3 bg-white border border-slate-300 rounded-md text-slate-700"
              />
              {errors[name] && (
                <p className="text-red-500 text-sm mt-1">{errors[name]}</p>
              )}
            </div>
          </div>
        ))}

        <footer className="flex justify-end space-x-4 mt-4">
          <button type="button" className="btn-text w-1/2" onClick={onCancel}>
            Cancel
          </button>
          <button
            type="button"
            className="btn-primary w-1/2"
            onClick={handleSave}
          >
            {isRegistering ? 'Registering...' : 'Register'}
          </button>
        </footer>
      </form>
    </section>
  );
};

export default RegisterBankForm;
