import { useEffect, useState, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { inAppWallet, preAuthenticate } from 'thirdweb/wallets/in-app';
import { useSetActiveWallet } from 'thirdweb/react';
import { polygonAmoy } from 'thirdweb/chains';
import { useActiveAccount } from 'thirdweb/react';
import { createWallet } from 'thirdweb/wallets';

import { client } from '../../config/thirdwebConfig';
import { AuthLogoSection } from '../../components/Hero';
import { AuthForm } from '../../components/Form';
import { AuthButtons } from '../../components/Buttons';
import { OtpInput } from '../../components/OtpInput';

import useFantomStarterAdminApi from '../../hooks/useFantomStarterAdminApi';
import useToken from '../../hooks/useToken';
import useModal from '../../hooks/useModal';
import { loader } from '../../assets';

const Login = () => {
  const navigate = useNavigate();
  const { openModal, closeModal } = useModal();
  const wallet = inAppWallet();
  const setActiveAccount = useSetActiveWallet();
  const activeAccount = useActiveAccount();
  const location = useLocation();
  const currentPath = location.pathname;

  const {
    getUser,
    registerUser,
    admin: { login },
  } = useFantomStarterAdminApi();
  const { setToken } = useToken();

  const now = Date.now();

  const [isUserFetched, setIsUserFetched] = useState<boolean>(false);
  const [isAlreadyUser, setIsAlreadyUser] = useState<boolean | null>(null);
  const [isSendingOtp, setIsSendingOtp] = useState<boolean>(false);
  const [address, setAddress] = useState<string | null>(null);
  const [isConnected, setIsConnected] = useState<boolean>(false);
  const [isSigningTx, setIsSigningTx] = useState<boolean>(false);
  const [isRegisteringUser, setIsRegisteringUser] = useState<boolean>(false);
  const [showLoadingSpinner, setShowLoadingSpinner] = useState<boolean>(false);

  const openOtpInputModal = useCallback((email: string) => {
    openModal(
      <OtpInput
        headerTitle="Log in"
        email={email}
        onClose={closeModal}
        onVerify={async (otpValue, setIsVerifyingOtp) => {
          try {
            setIsVerifyingOtp(true);
            await wallet.connect({
              client,
              chain: polygonAmoy,
              strategy: 'email',
              email,
              verificationCode: otpValue,
            });
            setShowLoadingSpinner(true);
            await setActiveAccount(wallet);
            closeModal();
          } catch (error) {
            console.error('Error while verifying OTP:', error);
            setShowLoadingSpinner(false);
          } finally {
            setIsVerifyingOtp(false);
          }
        }}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFormSubmit = useCallback(async (email: string) => {
    try {
      setIsSendingOtp(true);
      await preAuthenticate({
        client,
        strategy: 'email',
        email,
      });
      setIsSendingOtp(false);
      openOtpInputModal(email);
    } catch (error) {
      console.error('Error while Pre authenticating:', error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleContinueWithGoogle = useCallback(async () => {
    try {
      await wallet.connect({
        client,
        chain: polygonAmoy,
        strategy: 'google',
      });
      setShowLoadingSpinner(true);
      await setActiveAccount(wallet);
    } catch (error) {
      console.error('Error while connecting with Google:', error);
      setShowLoadingSpinner(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleContinueWithApple = useCallback(async () => {
    console.log('Continue with Apple');
    try {
      await wallet.connect({
        client,
        chain: polygonAmoy,
        strategy: 'google',
      });
      setShowLoadingSpinner(true);
      await setActiveAccount(wallet);
    } catch (error) {
      console.error('Error while continueing with Apple:', error);
      setShowLoadingSpinner(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleContinueWithMetamask = useCallback(async () => {
    console.log('Continue with MetaMask');
    try {
      const metamask = createWallet('io.metamask');
      await wallet.connect({
        strategy: 'wallet',
        chain: polygonAmoy,
        wallet: metamask,
        client,
      });
      setShowLoadingSpinner(true);
      await setActiveAccount(wallet);
    } catch (error) {
      console.error('Error while continuing with MetaMask:', error);
      setShowLoadingSpinner(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSignLoginTx = async () => {
    if (!address) {
      console.error('Wallet address not found!');
      return;
    }
    if (!activeAccount) {
      console.error('Active account not found!');
      return;
    }
    setIsSigningTx(true);
    try {
      const signature = await activeAccount.signMessage({
        message: `FantomStarter-Admin-Login-${address}-${now.toString()}`,
      });
      const result = await login(address, signature, now);
      setToken(result?.data?.token);
      navigate(currentPath === '/auth/login' ? '/' : currentPath);
    } catch (error) {
      console.error('Error while signing login transaction:', error);
    } finally {
      setIsSigningTx(false);
    }
  };

  const handleRegisterUser = async () => {
    if (!address) {
      console.error('Wallet address not found!');
      return;
    }
    setIsRegisteringUser(true);
    try {
      const result = await registerUser(address);
      if (result?.data?.status === 'created') {
        setIsAlreadyUser(true);
      }
    } catch (error) {
      console.error('Error while registering user:', error);
    } finally {
      setIsRegisteringUser(false);
    }
  };

  useEffect(() => {
    if (address) {
      let isMounted = true;
      const timeoutPromise = new Promise((_, reject) =>
        setTimeout(() => reject(new Error('Request timed out')), 10000)
      );

      const fetchUserPromise = getUser(address);

      Promise.race([fetchUserPromise, timeoutPromise])
        .then((result) => {
          if (isMounted) {
            setIsUserFetched(true);
            if (result?.data?.id) {
              setIsAlreadyUser(true);
            }
          }
        })
        .catch((error) => {
          if (isMounted) {
            if (error.message === 'Request timed out') {
              console.log('Request timed out');
            } else {
              console.log('Error fetching user:', error);
            }
            setIsUserFetched(true);
          }
        });

      return () => {
        isMounted = false;
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address]);

  useEffect(() => {
    if (activeAccount && activeAccount.address) {
      setAddress(activeAccount.address);
      setIsConnected(true);
    } else {
      setIsConnected(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeAccount]);

  useEffect(() => {
    if (isConnected && isAlreadyUser !== null) {
      isAlreadyUser ? handleSignLoginTx() : handleRegisterUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConnected, isAlreadyUser]);

  return (
    <main className="bg-white w-screen h-full sm:h-screen flex flex-col sm:flex-row items-center">
      <AuthLogoSection />
      {!isConnected && !showLoadingSpinner && (
        <section className="w-full sm:w-2/5 p-6 flex flex-col items-center">
          <div className="flex flex-col items-center">
            <h1 className="text-2xl sm:text-3xl font-semibold">Log In</h1>
            <p className="text-lg sm:text-xl font-normal mt-2 text-center">
              Enter your email below to log in
            </p>
          </div>
          <AuthForm
            onSubmit={handleFormSubmit}
            hideTnCBox
            isSubmitting={isSendingOtp}
          />
          <section className="flex gap-2 justify-center items-center w-full max-w-[360px] mx-auto mt-8 leading-none text-center text-[#334155]">
            <div className="flex-1 shrink self-stretch my-auto h-px border border-solid basis-0 border-[#E2E8F0]" />
            <div className="self-stretch my-auto text-sm sm:text-base">
              OR CONTINUE WITH
            </div>
            <div className="flex-1 shrink self-stretch my-auto h-px border border-solid basis-0 border-[#E2E8F0]" />
          </section>
          <AuthButtons
            onContinueWithGoogle={handleContinueWithGoogle}
            onContinueWithApple={handleContinueWithApple}
            onContinueWithMetamask={handleContinueWithMetamask}
          />
          <section className="flex flex-row items-center gap-2 justify-center mt-5">
            <p className="text-sm sm:text-base text-[#334155]">
              Don’t have an account?
            </p>
            <button
              className="btn-text px-0 font-medium text-sm sm:text-base"
              onClick={() => navigate('/auth/signup')}
            >
              Sign Up
            </button>
          </section>
        </section>
      )}
      {showLoadingSpinner && (
        <section className="w-full sm:w-2/5 p-6 flex flex-col items-center">
          <div className="flex flex-col items-center">
            <h1 className="text-2xl sm:text-3xl font-semibold">Log In</h1>
            <p className="text-lg sm:text-xl text-center font-normal mt-2">
              Please wait while we log you in
            </p>
          </div>
          <img
            src={loader}
            alt="loader"
            className="w-10 h-10 mt-8 animate-spin"
          />
        </section>
      )}
    </main>
  );
};

export default Login;
