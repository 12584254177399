import React from 'react';

interface LaunchDialogProps {
  onClose: () => void;
}

const LaunchDialog: React.FC<LaunchDialogProps> = ({ onClose }) => {
  return (
    <section className="flex flex-col px-6 pt-6 pb-12 max-w-lg bg-white rounded-md border border-solid border-slate-300 max-md:px-5 z-50">
      <header className="flex flex-col items-end w-full text-2xl font-semibold leading-none text-center text-slate-900">
        <button onClick={onClose} aria-label="Close dialog">
          <img
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/cc7109706f88dd38b2d0f4e67abb14df4acb1c277a8059b9a96afe464a39a7ae?placeholderIfAbsent=true&apiKey=466c088da2e142d481f76ae5c06a8ddd"
            alt=""
            className="object-contain w-6 aspect-square cursor-pointer"
            onClick={onClose}
          />
        </button>
        <h2 className="w-full max-md:max-w-full">Registering your contract on blockchain...</h2>
      </header>

      <div className="flex flex-col justify-center items-center pt-6 mt-4 w-full text-sm max-w-[464px] max-md:max-w-full">
        <div className="btn-primary flex gap-2 justify-center items-center px-4 py-2 font-medium leading-6 text-white rounded-md">
          <img
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/2bb5b72517045319a90da30aeeff94746de3400ffb46b2b062521f169a3af49f?placeholderIfAbsent=true&apiKey=466c088da2e142d481f76ae5c06a8ddd"
            alt=""
            className="object-contain shrink-0 self-stretch my-auto w-4 aspect-square animate-spin"
          />
          <span className="self-stretch my-auto">
            This may take 2-3 minutes.
          </span>
        </div>

        <p className="mt-6 w-full leading-5 text-center text-slate-500 max-md:max-w-full">
          Feel free to close this window. We'll notify you as soon as the
          process is complete.
        </p>
      </div>
    </section>
  );
};

export default LaunchDialog;
