import { useEffect, useState } from 'react';
import { DesktopLayout } from '../../components/Layouts';
import {
  Profile,
  // BillingDetails,
  BankAccount,
  // Preferences,
} from '../../components/Settings';

export default function Settings() {
  const [activeComponent, setActiveComponent] = useState('Profile');

  const sidebarItems = [
    { label: 'Profile', component: <Profile /> },
    // { label: 'Billing Details', component: <BillingDetails /> },
    { label: 'Bank Account', component: <BankAccount /> },
    // { label: 'Preferences', component: <Preferences /> },
  ];

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const agreementId = params.get('signed_agreement_id');
    if (agreementId) {
      setActiveComponent('Bank Account');
    }
    const tab = params.get('tab');
    if (tab) {
      setActiveComponent(tab);
    }
  }, []);

  return (
    <DesktopLayout>
      <section className="pt-24 sm:px-32 px-5 flex flex-col items-start gap-10 w-[1337px]">
        <header>
          <h5 className="text-2xl font-bold">Settings</h5>
          <p className="text-neutral-600 text-sm font-normal">
            Manage your account settings and set e-mail preferences.
          </p>
        </header>

        <main className="flex gap-6 p-6 h-full border border-slate-200 w-full">
          <nav className="flex flex-col text-sm font-medium text-slate-900 w-[200px]">
            {sidebarItems.map((item, index) => (
              <div
                key={index}
                className={`px-4 py-2 cursor-pointer ${
                  activeComponent === item.label ? 'bg-slate-100' : 'bg-white'
                } rounded-md`}
                onClick={() => setActiveComponent(item.label)}
              >
                {item.label}
              </div>
            ))}
          </nav>

          <div className="w-[1px] bg-slate-200 h-[1024px]" />

          {
            sidebarItems.find((item) => item.label === activeComponent)
              ?.component
          }
        </main>
      </section>
    </DesktopLayout>
  );
}
