import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { erc20ABI } from 'wagmi';
import { formatUnits, parseUnits, createPublicClient, http } from 'viem';
import { prepareContractCall, getContract, waitForReceipt } from 'thirdweb';
import { polygonAmoy } from 'thirdweb/chains';
import { useSendTransaction, useActiveAccount } from 'thirdweb/react';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';

import { polygonAmoy as viemPolygonAmoy } from '../../config/wagmiConfig';
import { client } from '../../config/thirdwebConfig';
import useModal from '../../hooks/useModal';
import SaleCardMenu from './SaleCardMenu';
import Tooltip from './Tooltip';
import EditModal from '../EditModal/Edit';
import DeployModal from '../DeployContracts/Deploy';
import { DocumentActions } from '../DocumentActions';
import {
  more,
  edit,
  editWhite,
  editBlack,
  code,
  codeWhite,
  codeBlack,
  truck,
  truckWhite,
  truckBlack,
  arrowDown,
  copy,
  info,
  share,
  doller,
  alert,
  calendar,
  calenderWhite,
  clipboardEdit,
  dollarDisabled,
  bankNote,
} from '../../assets';
import { extractNameAndTag } from '../../utils/conversion';
import fsSaleWithLiquidityProviderABI from '../../abis/fsSaleWithLiquidityProviderABI.json';
import useDocusignApi from '../../hooks/useDocusign';
import useFantomStarterAdminApi from '../../hooks/useFantomStarterAdminApi';
import NotificationCard from './NotificationCard';
import { daysUntilDate } from '../../utils/conversion';
import { DeleteConfirmationModal, ExtendDates } from '../Modal';

interface ISaleCardProps {
  project: any;
  templateHash: { [templateName: string]: any };
  refetchProjects: () => void;
}

const SaleCard: React.FC<ISaleCardProps> = ({
  project,
  templateHash,
  refetchProjects,
}) => {
  const circleDepositAddress = '0x5e28b28c1e57b88ef92a9b8ab90e622010a7ba4d';
  const { openModal, closeModal } = useModal();
  const { mutateAsync: sendTx } = useSendTransaction();
  const { mutateAsync: sendFundToContractTx } = useSendTransaction();
  const activeAccount = useActiveAccount();
  const { getTemplateEditViewUrl, createTemplate, getTemplateName } =
    useDocusignApi();
  const {
    admin: { deleteProject },
    circle: {
      createProjectFundDeposit,
      initiateWithdrawToBank,
      getDepositForProjectId,
    },
  } = useFantomStarterAdminApi();
  const navigate = useNavigate();

  const projectLogo = project.logo_image_url;
  const headerImage = project.header_image_url;
  const name = extractNameAndTag(project.name).name;
  const description = project.description;
  const capTotal = project.cap_total_dollars;

  const [address, setAddress] = useState<string | null>(null);
  const [itemsSold, setItemsSold] = useState<number>(0);
  const [toggleCardMenu, setToggleCardMenu] = useState<boolean>(false);
  const [toggleTooltip, setToggleTooltip] = useState<boolean>(false);
  const [refetchLocalStorageData, setRefetchLocalStorageData] =
    useState<string>('');
  const [editStepStatus, setEditStepStatus] = useState<
    'prevComplete' | 'actionReq' | 'done'
  >('actionReq');
  const [embedStepStatus, setEmbedStepStatus] = useState<
    'prevComplete' | 'actionReq' | 'done'
  >('prevComplete');
  const [deployStepStatus, setDeployStepStatus] = useState<
    'prevComplete' | 'actionReq' | 'done'
  >('prevComplete');
  const [allStepsDone, setAllStepsDone] = useState(
    project.sale_contract_address ? true : false
  );

  const [selectedAction, setSelectedAction] = useState<string | null>(null);
  const [isExecutingAction, setIsExecutingAction] = useState(false);
  const [isFundToContractStepDone, setIsFundToContractStepDone] =
    useState(false);
  const [isEnableClaimingStepDone, setIsClaimingStepDone] = useState(false);
  const [contractBalance, setContractBalance] = useState<number>(0);
  const [isRetrieveFundStepDone, setIsRetrieveFundStepDone] = useState(false);
  // const [isWithdrawnToBank, setIsWithdrawnToBank] = useState(false);
  const [refecthActionsLocalStorageData, setRefetchActionsLocalStorageData] =
    useState<string>('');
  const [toggleDocumentActions, setToggleDocumentActions] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [isTemplateCreated, setIsTemplateCreated] = useState(false);
  const [templateId, setTemplateId] = useState<string>('');
  const [templateName, setTemplateName] = useState<string | null>(null);
  const [docContractEdited, setDocContractEdited] = useState<boolean>(false);

  const investedAmount =
    itemsSold * Number(project.ratio_paytoken_per_idotoken);
  const investedPercentage = `${((investedAmount / capTotal) * 100).toFixed(1)}%`;
  const remainingAmount = capTotal - investedAmount;
  const totalTokensToFund: bigint = BigInt(
    Math.ceil(capTotal / Number(project.ratio_paytoken_per_idotoken))
  );
  const dateExtendRequired =
    daysUntilDate(project.end_date_funding) === 0 &&
    !project.sale_contract_address;
  const fundingPeriodOver =
    Date.now() > new Date(project.end_date_funding!).getTime();

  const openEditModal = () => {
    openModal(
      <EditModal
        onClose={() => {
          closeModal();
          setRefetchLocalStorageData(
            `refetchLocalStorageData - ${Date.now().toString()}`
          );
          refetchProjects();
        }}
        project={project}
      />
    );
  };

  const openDeployModal = () => {
    openModal(
      <DeployModal
        onClose={() => {
          closeModal();
          setRefetchLocalStorageData(
            `refetchLocalStorageData - ${Date.now().toString()}`
          );
          refetchProjects();
        }}
        project={project}
      />
    );
  };

  const openExtendDatesModal = () => {
    openModal(
      <ExtendDates
        onClose={() => {
          closeModal();
          setRefetchLocalStorageData(
            `refetchLocalStorageData - ${Date.now().toString()}`
          );
          refetchProjects();
        }}
        project={project}
      />
    );
  };

  const openDeleteConfirmationDialog = () => {
    openModal(
      <DeleteConfirmationModal
        onCancel={() => {
          closeModal();
        }}
        onDelete={async () => {
          const result = await deleteProject(project.id);
          refetchProjects();
          if (result.status === 200) {
            toast.success('Project Successfully Deleted!', {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
            });
          } else {
            toast.error('Failed to delete Project', {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
            });
          }
        }}
      />
    );
  };

  useEffect(() => {
    const editDocStepDone = localStorage.getItem(
      `editDocStepDone-${project.id}`
    );
    if (editDocStepDone) {
      setDocContractEdited(true);
    }

    const fundToContractStepDone = localStorage.getItem(
      `fundToContractStepDone-${project.id}`
    );
    if (fundToContractStepDone) {
      setIsFundToContractStepDone(true);
    }

    const enableClaimingStepDone = localStorage.getItem(
      `enableClaimingStepDone-${project.id}`
    );
    if (enableClaimingStepDone) {
      setIsClaimingStepDone(true);
    }

    const withdrawPayTokensStepDone = localStorage.getItem(
      `withdrawPayTokensStepDone-${project.id}`
    );
    if (withdrawPayTokensStepDone) {
      setIsRetrieveFundStepDone(true);
    }

    // const withdrawToBankStepDone = localStorage.getItem(
    //   `withdrawToBankStepDone-${project.id}`
    // );
    // if (withdrawToBankStepDone) {
    //   setIsWithdrawnToBank(true);
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refecthActionsLocalStorageData]);

  useEffect(() => {
    const editStepDone = localStorage.getItem(`editStepDone-${project.id}`);
    const embedStepDone = localStorage.getItem(`embedStepDone-${project.id}`);
    const deployStepDone = localStorage.getItem(`deployStepDone-${project.id}`);
    if (embedStepDone) {
      setEditStepStatus('done');
      setEmbedStepStatus('done');
      setDeployStepStatus('done');
      setAllStepsDone(true);
    } else if (deployStepDone) {
      setEditStepStatus('done');
      setDeployStepStatus('done');
      setEmbedStepStatus('actionReq');
    } else if (editStepDone) {
      setEditStepStatus('done');
      setDeployStepStatus('actionReq');
      setEmbedStepStatus('prevComplete');
    } else {
      setEditStepStatus('actionReq');
      setDeployStepStatus('prevComplete');
      setEmbedStepStatus('prevComplete');
    }
  }, [refetchLocalStorageData, project.id]);

  useEffect(() => {
    (async () => {
      if (
        project.sale_contract_address !== null &&
        project.sale_contract_address.includes('0x')
      ) {
        try {
          const publicClient = createPublicClient({
            chain: viemPolygonAmoy,
            transport: http(),
          });
          const soldAmount = (await publicClient.readContract({
            address: project.sale_contract_address as `0x${string}`,
            abi: fsSaleWithLiquidityProviderABI,
            functionName: 'itemsSold',
          })) as bigint;
          setItemsSold(Number(formatUnits(soldAmount, 0)));
        } catch (err) {
          console.warn('Something went wrong while fetching items sold', err);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const publicClient = createPublicClient({
          chain: viemPolygonAmoy,
          transport: http(),
        });
        const balance = await publicClient.readContract({
          address: project.paytoken_contract_address as `0x${string}`,
          abi: erc20ABI,
          functionName: 'balanceOf',
          args: [project.sale_contract_address as `0x${string}`],
        });
        const formattedBalance = Number(formatUnits(balance, 6));
        setContractBalance(formattedBalance);
      } catch (err) {
        console.log(
          'Something went wrong while retrieving contract balance: ',
          err
        );
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (activeAccount && activeAccount.address) {
      setAddress(activeAccount.address);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeAccount]);

  useEffect(() => {
    const templateName = getTemplateName(`${project.name} template`);
    const templateNameWithId = getTemplateName(`${project.id} template`);

    const template =
      templateHash[templateName] || templateHash[templateNameWithId];

    if (template) {
      setTemplateName(
        templateHash[templateNameWithId] ? templateNameWithId : templateName
      );
      setTemplateId(template.templateId);
      setIsTemplateCreated(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project.id, project.name, templateHash]);

  const handleFundContract = useCallback(async () => {
    setIsExecutingAction(true);
    try {
      toast.info('Approving tokens...', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000,
      });
      const tokenContract = getContract({
        address: project.token_contract_address,
        chain: polygonAmoy,
        client,
      });

      const approveTransaction = prepareContractCall({
        contract: tokenContract,
        method: 'function setApprovalForAll(address operator, bool approved)',
        params: [project.sale_contract_address, true],
      });
      const approveTx = await sendTx(approveTransaction);
      await waitForReceipt({
        client,
        chain: polygonAmoy,
        transactionHash: approveTx.transactionHash,
      });

      toast.info('Funding contract...', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000,
      });
      const saleContract = getContract({
        address: project.sale_contract_address,
        chain: polygonAmoy,
        client,
      });
      const fundToContractTransaction = prepareContractCall({
        contract: saleContract,
        method: 'function fundInoToContract(uint256 _amountInInoTokens)',
        params: [totalTokensToFund],
      });
      const fundToContractTx = await sendFundToContractTx(
        fundToContractTransaction
      );
      await waitForReceipt({
        client,
        chain: polygonAmoy,
        transactionHash: fundToContractTx.transactionHash,
      });

      toast.success('Contract funded successfully!', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
      localStorage.setItem(`fundToContractStepDone-${project.id}`, 'true');
      setRefetchActionsLocalStorageData(
        `refetchActionsLocalStorageData - ${Date.now().toString()}`
      );
      setSelectedAction(null);
    } catch (error) {
      console.error('Error during contract funding:', error);
      toast.error('Error funding the contract. Please try again.', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
    setIsExecutingAction(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client, totalTokensToFund, sendTx, sendFundToContractTx]);

  const handleEnableClaiming = useCallback(async () => {
    setIsExecutingAction(true);
    try {
      const claimingStartTime: bigint = BigInt(Math.floor(Date.now() / 1000));
      const saleContract = getContract({
        address: project.sale_contract_address,
        chain: polygonAmoy,
        client,
      });
      const enableClaimingTransaction = prepareContractCall({
        contract: saleContract,
        method:
          'function enableClaiming(bool _isClaimingOpen, uint256 _startDateOfClaimingTokens)',
        params: [true, claimingStartTime],
      });
      const enableClaimingTx = await sendTx(enableClaimingTransaction);
      await waitForReceipt({
        client,
        chain: polygonAmoy,
        transactionHash: enableClaimingTx.transactionHash,
      });

      const setEndDateOfClaimingTokensTransaction = prepareContractCall({
        contract: saleContract,
        method:
          'function setEndDateOfClaimingTokens(uint256 _endDateOfClaimingTokens)',
        params: [claimingStartTime + BigInt(1)],
      });
      const setEndDateOfClaimingTokensTx = await sendTx(
        setEndDateOfClaimingTokensTransaction
      );
      await waitForReceipt({
        client,
        chain: polygonAmoy,
        transactionHash: setEndDateOfClaimingTokensTx.transactionHash,
      });

      toast.success('Claiming enabled successfully!', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
      localStorage.setItem(`enableClaimingStepDone-${project.id}`, 'true');
      setRefetchActionsLocalStorageData(
        `refetchActionsLocalStorageData - ${Date.now().toString()}`
      );
      setSelectedAction(null);
    } catch (error) {
      console.error('Error enabling claiming:', error);
      toast.error('Error enabling claiming. Please try again.', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
    setIsExecutingAction(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client, sendTx]);

  const handleRetrieveContractBalance = useCallback(async () => {
    if (!address) {
      toast.error('Address not found!', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
      return;
    }
    setIsExecutingAction(true);
    try {
      const parsedRetrievableBalance = parseUnits(
        contractBalance.toString(),
        6
      );
      const saleContract = getContract({
        address: project.sale_contract_address,
        chain: polygonAmoy,
        client,
      });
      const withdrawPayTokensTransaction = prepareContractCall({
        contract: saleContract,
        method:
          'function withdrawPayTokens(address _owner, uint256 _payTokensToWithdraw)',
        params: [address, parsedRetrievableBalance],
      });
      const withdrawPayTokensTx = await sendTx(withdrawPayTokensTransaction);
      await waitForReceipt({
        client,
        chain: polygonAmoy,
        transactionHash: withdrawPayTokensTx.transactionHash,
      });

      toast.success('Tokens retrieved successfully!', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
      localStorage.setItem(`withdrawPayTokensStepDone-${project.id}`, 'true');
      setRefetchActionsLocalStorageData(
        `refetchActionsLocalStorageData - ${Date.now().toString()}`
      );
      setSelectedAction(null);
    } catch (error) {
      console.error('Error retrieving contract balance:', error);
      toast.error('Error retrieving contract balance. Please try again.', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
    setIsExecutingAction(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, contractBalance, client, sendTx]);

  const handleBundlePostDeploymentSteps = useCallback(async () => {
    if (!address) {
      toast.error('Address not found!', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
      return;
    }

    setIsExecutingAction(true);
    try {
      const fundToContractStepDone = localStorage.getItem(
        `fundToContractStepDone-${project.id}`
      );
      if (!fundToContractStepDone) {
        toast.info('Starting contract funding...', {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 5000,
        });

        const tokenContract = getContract({
          address: project.token_contract_address,
          chain: polygonAmoy,
          client,
        });

        const approveTransaction = prepareContractCall({
          contract: tokenContract,
          method: 'function setApprovalForAll(address operator, bool approved)',
          params: [project.sale_contract_address, true],
        });
        const approveTx = await sendTx(approveTransaction);
        await waitForReceipt({
          client,
          chain: polygonAmoy,
          transactionHash: approveTx.transactionHash,
        });

        const saleContract = getContract({
          address: project.sale_contract_address,
          chain: polygonAmoy,
          client,
        });

        const fundToContractTransaction = prepareContractCall({
          contract: saleContract,
          method: 'function fundInoToContract(uint256 _amountInInoTokens)',
          params: [totalTokensToFund],
        });

        const fundToContractTx = await sendFundToContractTx(
          fundToContractTransaction
        );
        await waitForReceipt({
          client,
          chain: polygonAmoy,
          transactionHash: fundToContractTx.transactionHash,
        });

        toast.success('Contract funded successfully!', {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });

        localStorage.setItem(`fundToContractStepDone-${project.id}`, 'true');
      } else {
        toast.info('Skipping contract funding step (already done)', {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }

      const enableClaimingStepDone = localStorage.getItem(
        `enableClaimingStepDone-${project.id}`
      );
      if (!enableClaimingStepDone) {
        toast.info('Enabling claiming...', {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 5000,
        });

        const claimingStartTime: bigint = BigInt(Math.floor(Date.now() / 1000));
        const saleContract = getContract({
          address: project.sale_contract_address,
          chain: polygonAmoy,
          client,
        });

        const enableClaimingTransaction = prepareContractCall({
          contract: saleContract,
          method:
            'function enableClaiming(bool _isClaimingOpen, uint256 _startDateOfClaimingTokens)',
          params: [true, claimingStartTime],
        });

        const enableClaimingTx = await sendTx(enableClaimingTransaction);
        await waitForReceipt({
          client,
          chain: polygonAmoy,
          transactionHash: enableClaimingTx.transactionHash,
        });

        const setEndDateOfClaimingTokensTransaction = prepareContractCall({
          contract: saleContract,
          method:
            'function setEndDateOfClaimingTokens(uint256 _endDateOfClaimingTokens)',
          params: [claimingStartTime + BigInt(1)],
        });

        const setEndDateOfClaimingTokensTx = await sendTx(
          setEndDateOfClaimingTokensTransaction
        );
        await waitForReceipt({
          client,
          chain: polygonAmoy,
          transactionHash: setEndDateOfClaimingTokensTx.transactionHash,
        });

        toast.success('Claiming enabled successfully!', {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });

        localStorage.setItem(`enableClaimingStepDone-${project.id}`, 'true');
      } else {
        toast.info('Skipping claiming step (already done)', {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }

      const withdrawPayTokensStepDone = localStorage.getItem(
        `withdrawPayTokensStepDone-${project.id}`
      );
      if (!withdrawPayTokensStepDone) {
        toast.info('Retrieving contract balance...', {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 5000,
        });

        const parsedRetrievableBalance = parseUnits(
          contractBalance.toString(),
          6
        );
        const saleContract = getContract({
          address: project.sale_contract_address,
          chain: polygonAmoy,
          client,
        });

        const withdrawPayTokensTransaction = prepareContractCall({
          contract: saleContract,
          method:
            'function withdrawPayTokens(address _owner, uint256 _payTokensToWithdraw)',
          params: [address, parsedRetrievableBalance],
        });

        const withdrawPayTokensTx = await sendTx(withdrawPayTokensTransaction);
        await waitForReceipt({
          client,
          chain: polygonAmoy,
          transactionHash: withdrawPayTokensTx.transactionHash,
        });

        toast.success('Tokens retrieved successfully!', {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });

        localStorage.setItem(`withdrawPayTokensStepDone-${project.id}`, 'true');
      } else {
        toast.info('Skipping contract balance retrieval step (already done)', {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }

      setRefetchActionsLocalStorageData(
        `refetchActionsLocalStorageData - ${Date.now().toString()}`
      );
      setSelectedAction(null);
    } catch (error) {
      console.error('Error during post-deployment steps:', error);
      toast.error('Error during post-deployment steps. Please try again.', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
    setIsExecutingAction(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    client,
    totalTokensToFund,
    sendTx,
    sendFundToContractTx,
    contractBalance,
    address,
  ]);

  const handleBankWithdrawal = useCallback(async () => {
    if (!address) {
      toast.error('Address not found!', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
      return;
    }
    if (!investedAmount) {
      toast.error('No invested amount found!', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
      return;
    }
    setIsExecutingAction(true);
    const idempotencyKey = uuidv4();
    try {
      const depositResult = await getDepositForProjectId(project.id);
      if (
        depositResult.status === 400 ||
        depositResult.status === 401 ||
        depositResult.status === 500
      ) {
        toast.error('Error fetching deposit!', {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
        setIsExecutingAction(false);
        return;
      }
      const hasDeposit =
        depositResult.data && depositResult.data.tx_hash ? true : false;
      if (hasDeposit && depositResult.data.withdrawn_to_bank === false) {
        toast.info('Initiating withdrawal to bank...', {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 5000,
        });
        const initiateResult = await initiateWithdrawToBank(
          project.id,
          idempotencyKey
        );
        if (
          initiateResult.status === 400 ||
          initiateResult.status === 401 ||
          initiateResult.status === 500
        ) {
          toast.error('Error initiating withdrawal to bank!', {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
          setIsExecutingAction(false);
          return;
        }
        toast.success('Withdrawal initiated successfully!', {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
        localStorage.setItem(`withdrawToBankStepDone-${project.id}`, 'true');
        setRefetchActionsLocalStorageData(
          `refetchActionsLocalStorageData - ${Date.now().toString()}`
        );
        setSelectedAction(null);
      } else {
        const localDepositHash = localStorage.getItem(
          `${address}-${project.id}-circle-deposit-hash`
        );
        if (localDepositHash) {
          const depositResult = await createProjectFundDeposit(
            project.id,
            localDepositHash
          );
          if (
            depositResult.status === 400 ||
            depositResult.status === 401 ||
            depositResult.status === 500
          ) {
            toast.error('Error creating project fund deposit!', {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
            });
            setIsExecutingAction(false);
            return;
          }
          toast.info('Initiating withdrawal to bank...', {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });
          const initiateResult = await initiateWithdrawToBank(
            project.id,
            idempotencyKey
          );
          if (
            initiateResult.status === 400 ||
            initiateResult.status === 401 ||
            initiateResult.status === 500
          ) {
            toast.error('Error initiating withdrawal to bank!', {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
            });
            setIsExecutingAction(false);
            return;
          }
          toast.success('Withdrawal initiated successfully!', {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
          localStorage.setItem(`withdrawToBankStepDone-${project.id}`, 'true');
          setRefetchActionsLocalStorageData(
            `refetchActionsLocalStorageData - ${Date.now().toString()}`
          );
          setSelectedAction(null);
        } else {
          toast.info('Depositing user balance to Circle deposit address...', {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });
          const payTokenContract = getContract({
            address: project.paytoken_contract_address,
            chain: polygonAmoy,
            client,
          });
          const depositTransaction = prepareContractCall({
            contract: payTokenContract,
            method: 'function transfer(address to, uint256 amount)',
            params: [
              circleDepositAddress,
              parseUnits(investedAmount.toString(), 6),
            ],
          });
          const depositTx = await sendTx(depositTransaction);
          await waitForReceipt({
            client,
            chain: polygonAmoy,
            transactionHash: depositTx.transactionHash,
          });
          localStorage.setItem(
            `${address}-${project.id}-circle-deposit-hash`,
            depositTx.transactionHash
          );

          const depositResult = await createProjectFundDeposit(
            project.id,
            depositTx.transactionHash
          );
          if (
            depositResult.status === 400 ||
            depositResult.status === 401 ||
            depositResult.status === 500
          ) {
            toast.error('Error creating project fund deposit!', {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
            });
            setIsExecutingAction(false);
            return;
          }
          toast.info('Initiating withdrawal to bank...', {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });
          const initateResult = await initiateWithdrawToBank(
            project.id,
            idempotencyKey
          );
          if (initateResult.status !== 201 || initateResult.status !== 200) {
            toast.error('Error initiating withdrawal to bank!', {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
            });
            setIsExecutingAction(false);
            return;
          }
          toast.success('Withdrawal initiated successfully!', {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
          localStorage.setItem(`withdrawToBankStepDone-${project.id}`, 'true');
          setRefetchActionsLocalStorageData(
            `refetchActionsLocalStorageData - ${Date.now().toString()}`
          );
          setSelectedAction(null);
        }
      }
    } catch (error) {
      console.error('Error withdrawing to bank:', error);
      toast.error('Error withdrawing to bank. Please try again.', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
    setIsExecutingAction(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, investedAmount, sendTx]);

  const handleGoClick = () => {
    if (!selectedAction) return;
    switch (selectedAction) {
      case 'Edit':
        openEditModal();
        break;
      case 'Embed':
        window.open(
          `https://share.g8wy.app/?path=/story/pages-sale-page-v2--default&args=id:${project.id};isTestnet:${true};isINO:${project.sale_version === 3}`,
          '_blank'
        );
        localStorage.setItem(`embedStepDone-${project.id}`, 'true');
        setRefetchLocalStorageData(
          `refetchLocalStorageData - ${Date.now().toString()}`
        );
        break;
      case 'Deploy':
        openDeployModal();
        break;
      case 'Fund Contract':
        handleFundContract();
        break;
      case 'Enable Claiming':
        handleEnableClaiming();
        break;
      case 'Retrieve Balance':
        handleRetrieveContractBalance();
        break;
      case 'Bank Withdraw':
        handleBankWithdrawal();
        break;
      default:
        break;
    }
  };

  const handleEditTemplate = useCallback(async (templateId: string) => {
    if (!templateId) {
      toast.error('No template found to edit', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
      return;
    }
    try {
      toast.info('Opening template editor...', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
      const redirectUrl = window.location.href;
      const result = await getTemplateEditViewUrl(templateId, redirectUrl);
      localStorage.setItem(`editDocStepDone-${project.id}`, 'true');
      setRefetchActionsLocalStorageData(
        `refetchActionsLocalStorageData - ${Date.now().toString()}`
      );
      window.location.href = result.url;
    } catch (error) {
      console.error('Error editing template:', error);
      toast.error('Error editing template. Please try again.', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTooltipAction = () => {
    handleEditTemplate(templateId);
  };

  const items = [
    {
      text: 'Edit',
      color: 'slate-700',
      onClick: () => openEditModal(),
    },
    {
      text: 'Share',
      color: 'slate-700',
      onClick: () => {
        window.open(
          `https://share.g8wy.app/?path=/story/pages-sale-page-v2--default&args=id:${project.id};isTestnet:${true};isINO:${project.sale_version === 3}`,
          '_blank'
        );
        localStorage.setItem(`embedStepDone-${project.id}`, 'true');
        setRefetchLocalStorageData(
          `refetchLocalStorageData - ${Date.now().toString()}`
        );
      },
    },
    {
      text: 'View Contract',
      color: 'slate-700',
      onClick: () => {
        handleEditTemplate(templateId);
      },
    },
    {
      text: 'Delete',
      color: 'red-500',
      onClick: () => openDeleteConfirmationDialog(),
    },
  ];

  return (
    <article className="flex flex-col p-6 bg-white rounded-md shadow-sm border border-slate-200 min-w-[404px]">
      <header className="flex flex-col max-w-[490px] relative">
        <section className="absolute right-4 top-4">
          <button
            aria-label="More options"
            className="w-8 h-8 flex justify-center items-center bg-white rounded-md border border-slate-100"
            onClick={() => setToggleCardMenu(!toggleCardMenu)}
          >
            <img
              loading="lazy"
              src={more}
              alt="More options"
              className="w-4 aspect-square"
            />
          </button>
          {toggleCardMenu && (
            <section className="absolute right-0 top-10">
              <SaleCardMenu items={items} />
            </section>
          )}
        </section>
        {daysUntilDate(project.end_date_funding) !== 0 && (
          <div className="bg-slate-900 text-white absolute top-4 left-4 text-sm p-2 rounded-md">{`${daysUntilDate(
            project.end_date_funding
          )} days left`}</div>
        )}
        {dateExtendRequired && (
          <div className="bg-slate-900 text-white absolute top-4 left-4 text-sm p-2 rounded-md">{`Expired`}</div>
        )}
        <section className="flex flex-col w-full max-md:max-w-full">
          <img
            loading="lazy"
            src={headerImage}
            alt="hero"
            className="object-cover h-[232px] w-full rounded-md"
          />

          <div className="flex items-center flex-row gap-2 mt-4 w-full max-md:max-w-full">
            <img
              loading="lazy"
              src={projectLogo}
              alt="Sale card logo"
              className="w-14 h-14 rounded-xl aspect-square"
            />
            <div className="flex flex-col justify-between min-w-[240px] max-w-full">
              <h1 className="text-lg font-bold truncate text-slate-900">
                {name}
              </h1>
              <div className="flex w-full gap-2 mt-2 text-xs">
                <span className="text-slate-500 flex-shrink-0">Sale ID:</span>
                <div className="flex items-center gap-1 font-medium text-black min-w-0">
                  <span className="truncate">{project.id}</span>
                  <img
                    loading="lazy"
                    src={copy}
                    alt="Copy icon"
                    className="flex-shrink-0 ml-1 w-4 h-4 cursor-pointer"
                    onClick={() => {
                      navigator.clipboard.writeText(project.id);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="flex flex-col mt-6 w-full text-xs max-md:max-w-full">
          <h2 className="leading-none text-slate-500">Sale description</h2>
          <p className="mt-2 font-medium leading-4 text-black">{description}</p>
        </section>
      </header>

      {investedAmount === 0 ? (
        <section className="mt-6">
          <NotificationCard
            iconSrc={info}
            title={
              deployStepStatus === 'actionReq'
                ? 'Deploy your sale to start receiving investments.'
                : 'You have not started receiving investments yet.'
            }
            classname="w-full p-5 mb-[10px]"
            titleTextClassName="text-sm whitespace-nowrap truncate"
          />
        </section>
      ) : (
        <section className="mt-6">
          <div className="flex items-center justify-between">
            <div className="flex gap-2 items-center text-base font-bold text-slate-900">
              {investedAmount} UDSC
              {!docContractEdited && (
                <span className="relative">
                  <img
                    src={alert}
                    alt="alert"
                    className="w-4 aspect-square cursor-pointer"
                    onClick={() => setToggleTooltip(!toggleTooltip)}
                  />
                  {toggleTooltip && (
                    <section className="absolute bottom-0 left-6 w-fit">
                      <Tooltip
                        message="An action is required."
                        actionText="Go to Contracts"
                        onAction={handleTooltipAction}
                      />
                    </section>
                  )}
                </span>
              )}
            </div>
            <div className="text-sm font-bold text-slate-900">
              {investedPercentage} invested
            </div>
          </div>
          <div className="mt-2 bg-slate-100 h-4 rounded-full overflow-hidden">
            <div
              className="bg-slate-900 h-full"
              style={{ width: investedPercentage }}
            ></div>
          </div>
          <div className="mt-1 text-sm text-slate-500">
            {remainingAmount} UDSC left
          </div>
        </section>
      )}

      <hr className="w-full border-slate-200 my-6" />

      {dateExtendRequired ? (
        <button
          className={'btn-action-required flex-1 justify-center'}
          onClick={() => {
            openExtendDatesModal();
          }}
        >
          <span>
            <img
              loading="lazy"
              src={calenderWhite}
              alt="calender icon"
              className="w-4 aspect-square"
            />
          </span>
          {'Extend dates'}
        </button>
      ) : !docContractEdited ? (
        <button
          className={'btn-action-required flex-1 justify-center'}
          onClick={() => handleEditTemplate(templateId)}
        >
          <span>
            <img
              loading="lazy"
              src={clipboardEdit}
              alt="edit doc icon"
              className="w-4 aspect-square"
            />
          </span>
          {'Edit Contract Template'}
        </button>
      ) : !fundingPeriodOver ? (
        <section className="flex gap-2 w-full relative">
          {deployStepStatus === 'actionReq' ? (
            <button
              className={'btn-action-required flex-1 justify-center'}
              onClick={() => openDeployModal()}
            >
              <span>
                <img
                  loading="lazy"
                  src={truckWhite}
                  alt="Deploy icon"
                  className="w-4 aspect-square"
                />
              </span>
              {`Launch Smart Contract`}
            </button>
          ) : (
            <section className="w-full flex gap-2">
              <button
                className={'btn-action-required flex-1 justify-center'}
                onClick={() => {
                  const chainId = Number(project.chain);
                  const isTestnet =
                    chainId === 4002 ||
                    chainId === 80002 ||
                    chainId === 97 ||
                    chainId === 5 ||
                    chainId === 421611 ||
                    chainId === 420 ||
                    chainId === 43113;
                  const isINO = project.sale_version === 3;
                  window.open(
                    `https://share.g8wy.app/?path=/story/pages-sale-page-v2--default&args=id:${project.id};isTestnet:${isTestnet};isINO:${isINO}`,
                    '_blank'
                  );
                  localStorage.setItem(`embedStepDone-${project.id}`, 'true');
                  setRefetchLocalStorageData(
                    `refetchLocalStorageData - ${Date.now().toString()}`
                  );
                }}
              >
                <span>
                  <img
                    loading="lazy"
                    src={share}
                    alt="share icon"
                    className="w-4 aspect-square"
                  />
                </span>
                {`Share`}
              </button>
            </section>
          )}
        </section>
      ) : !isRetrieveFundStepDone ? (
        <button
          className={
            fundingPeriodOver
              ? 'btn-action-required flex-1 justify-center'
              : 'btn-not-allowed flex-1 justify-center'
          }
          onClick={() => {
            handleBundlePostDeploymentSteps();
          }}
          disabled={isExecutingAction || !fundingPeriodOver}
        >
          <span>
            <img
              loading="lazy"
              src={fundingPeriodOver ? doller : dollarDisabled}
              alt="Deploy icon"
              className="w-4 aspect-square"
            />
          </span>
          {isExecutingAction ? `Transfering funds...` : `Transfer funds`}
        </button>
      ) : (
        <button
          className={'btn-action-required flex-1 justify-center'}
          onClick={() => {
            navigate('/financials');
          }}
        >
          <span>
            <img
              loading="lazy"
              src={bankNote}
              alt="Withdraw icon"
              className="w-4 aspect-square"
            />
          </span>
          {`Withdraw`}
        </button>
      )}
    </article>
  );
};

export default SaleCard;
