import React, { useCallback, useEffect, useState } from 'react';
import { useActiveAccount } from 'thirdweb/react';

import useModal from '../../hooks/useModal';
import useFantomStarterAdminApi from '../../hooks/useFantomStarterAdminApi';
import {
  AccountLinkingForm,
  CreateCustomerForm,
  RegisterBankForm,
} from '../../components/Form';

interface BankAccount {
  id: string;
  accountNumber: string;
  bankName: string;
}

export interface RegisteredBankAccount {
  id: string;
  accountNumber: string;
  name: string;
}

const BankAccounts: React.FC = () => {
  const activeAccount = useActiveAccount();
  const {
    circle: { getWireBanks, getWireBankDescription },
    bridge: { requestTosLink, createCustomer, registerBankAccount },
  } = useFantomStarterAdminApi();
  const { openModal, closeModal } = useModal();

  const [tosAccepted, setTosAccepted] = useState<boolean>(false);
  const [isAlreadyCustomer, setIsAlreadyCustomer] = useState<boolean>(false);
  const [bankDescriptions, setBankDescriptions] = useState<string[]>([]);
  const [bankAccounts, setBankAccounts] = useState<BankAccount[]>([]);
  const [isGettingLink, setIsGettingLink] = useState<boolean>(false);
  const [signedAggrementId, setSignedAggrementId] = useState<string | null>();
  const [customerId, setCustomerId] = useState<string | null>(
    localStorage.getItem(`${activeAccount?.address}-customerId`)
  );
  const [registeredBanks, setRegisteredBanks] = useState<
    RegisteredBankAccount[]
  >(
    JSON.parse(localStorage.getItem(`${activeAccount?.address}-banks`) || '[]')
  );

  const handleRequestTosLink = useCallback(async () => {
    setIsGettingLink(true);
    try {
      const idempotencyKey = crypto.randomUUID();
      const redirectUri = window.location.href;

      const response = await requestTosLink({ idempotencyKey });

      if (response.data?.url) {
        const urlWithRedirect = `${response.data.url}&redirect_uri=${redirectUri}`;
        window.location.href = urlWithRedirect;
      } else {
        console.error('Failed to retrieve TOS link URL');
      }
    } catch (error) {
      console.error('Error requesting TOS link:', error);
    } finally {
      setIsGettingLink(false);
    }
  }, [requestTosLink]);

  const openAccountLinkFormModal = () => {
    openModal(<AccountLinkingForm onCancel={closeModal} />);
  };

  const openCreateCustomerFormModal = () => {
    openModal(
      <CreateCustomerForm
        onCancel={closeModal}
        onSave={async (customerData: any) => {
          try {
            const response = await createCustomer(customerData);
            if (response.data?.id) {
              const newCustomerId = response.data.id;
              setCustomerId(newCustomerId);
              localStorage.setItem(
                `${activeAccount?.address}-customerId`,
                newCustomerId
              );
              closeModal();
              openRegisterBankFormModal();
            } else {
              console.error('Failed to create customer');
            }
          } catch (error) {
            console.error('Error creating customer:', error);
          }
        }}
        signedAgreementId={signedAggrementId || ''}
      />
    );
  };

  const openRegisterBankFormModal = () => {
    openModal(
      <RegisterBankForm
        onCancel={closeModal}
        customerId={customerId || ''}
        onSave={async (formData) => {
          try {
            if (!customerId) throw new Error('Customer ID is missing');
            const response = await registerBankAccount(formData, customerId);
            console.log(response);

            if (response.data?.id) {
              const bankId = response.data.id;
              const bankName = response.data.bank_name;
              const accountNumber = response.last_4;

              const existingBanks = JSON.parse(
                localStorage.getItem(`${activeAccount?.address}-banks`) || '[]'
              );
              const updatedBanks = [
                ...existingBanks,
                { id: bankId, name: bankName, accountNumber },
              ];
              setRegisteredBanks(updatedBanks);
              localStorage.setItem(
                `${activeAccount?.address}-banks`,
                JSON.stringify(updatedBanks)
              );
              closeModal();
            } else {
              console.error('Failed to create customer');
            }
          } catch (error) {
            console.error('Error creating customer:', error);
          }
        }}
      />
    );
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const agreementId = params.get('signed_agreement_id');

    if (agreementId) {
      setSignedAggrementId(agreementId);
      setTosAccepted(true);
      openCreateCustomerFormModal();
    }
  }, []);

  useEffect(() => {
    if (customerId) {
      setTosAccepted(true);
      setIsAlreadyCustomer(true);
    }
  }, [customerId]);

  useEffect(() => {
    (async () => {
      const result = await getWireBanks();
      if (result.data) {
        const wireIds = result.data.map((item: any) => item.wire_id);
        const bankDescriptions: string[] = [];
        for (const wireId of wireIds) {
          const result = await getWireBankDescription(wireId);
          if (result.data) {
            bankDescriptions.push(result.data.description);
          }
        }
        setBankDescriptions(bankDescriptions);
      }
    })();
  }, []);

  useEffect(() => {
    if (bankDescriptions.length) {
      const accounts: BankAccount[] = bankDescriptions.map(
        (description, index) => {
          const accountNumberMatch = description.match(/\*{4}\d{4}$/);
          const accountNumber = accountNumberMatch ? accountNumberMatch[0] : '';

          const bankNameMatch = description.match(/^[A-Z\s]+/);
          const bankName = bankNameMatch ? bankNameMatch[0].trim() : '';

          return {
            id: (index + 1).toString(),
            accountNumber: accountNumber,
            bankName: bankName,
          };
        }
      );
      setBankAccounts(accounts);
    }
  }, [bankDescriptions]);

  return (
    <section className="flex flex-col py-2 w-full">
      <header className="flex justify-between items-center w-full">
        <h1 className="text-base font-semibold text-black">Bank Accounts</h1>
        <button
          className="px-4 py-2 text-sm font-medium text-white bg-slate-900 rounded-md"
          onClick={
            tosAccepted
              ? isAlreadyCustomer
                ? openRegisterBankFormModal
                : openCreateCustomerFormModal
              : handleRequestTosLink
          }
          disabled={isGettingLink}
        >
          {isGettingLink ? 'Please wait...' : 'Add New'}
        </button>
      </header>
      <div className="flex flex-wrap gap-4 mt-6 w-full">
        {/* {bankAccounts.map((account) => (
          <article
            key={account.id}
            className="flex justify-between gap-4 p-4 bg-white rounded-md border border-solid w-[250px]"
          >
            <div className="text-sm text-slate-900">
              <h2 className="font-semibold">Account {account.id}</h2>
              <p className="mt-1">{account.accountNumber}</p>
              <p className="mt-1 text-xs text-slate-500">{account.bankName}</p>
            </div>
          </article>
        ))} */}
        {registeredBanks.map((account) => (
          <article
            key={account.id}
            className="flex justify-between gap-4 p-4 bg-white rounded-md border border-solid w-[250px]"
          >
            <div className="text-sm text-slate-900">
              <h2 className="font-semibold">
                Account {`****${account.id.slice(-4)}`}
              </h2>
              <p className="mt-1">{`****${account.accountNumber.slice(-4)}`}</p>
              <p className="mt-1 text-xs text-slate-500">
                {account.name.toUpperCase()}
              </p>
            </div>
          </article>
        ))}
      </div>
    </section>
  );
};

export default BankAccounts;
