import { linkedInLogo } from '../../assets';

export const footerColumns = [
  {
    title: 'Products',
    items: [
      'Luxury Real Estate',
      'Vacation Homes',
      'Single-Family Homes',
      'Retail Real Estate',
    ],
    links: ['/sales/create', '/sales/create', '/sales/create', '/sales/create'],
  },
  {
    title: 'Company',
    items: ['FAQ', 'Contact', 'Careers'],
    links: [
      'https://docs.g8wy.app',
      '/',
      'https://angel.co/company/fsmultichain/',
    ],
  },
  {
    title: 'Legal',
    items: ['Terms & Conditions', 'Privacy Policy'],
    links: [
      'https://www.g8wy.app/terms-and-conditions',
      'https://www.g8wy.app/privacy-policy',
    ],
  },
];

export const socialIcons = [
  {
    src: linkedInLogo,
    alt: 'LinkedIn',
    link: 'https://www.linkedin.com/company/g8wy/',
  },
];