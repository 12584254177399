import * as React from 'react';

interface InfoSectionProps {
  title: string;
  description: string;
}

export const CryptoGuide: React.FC = () => {
  const cryptoSections: InfoSectionProps[] = [
    {
      title: 'What are Stablecoins?',
      description:
        'Stablecoins are digital assets specifically designed for business transactions, maintaining a fixed value tied to established currencies like USD. Unlike volatile cryptocurrencies, stablecoins provide the reliability of traditional currency with the efficiency of blockchain technology.',
    },
    {
      title: 'Secure Infrastructure',
      description: 'G8WY processes all transactions through audited smart contracts, ensuring complete security and transparency. Our platform automatically verifies and records every transaction on the blockchain, providing an immutable record of all property sales and payments.',
    },
    {
      title: 'Enterprise-Grade Transactions',
      description: 'Our platform leverages enterprise-level stablecoin infrastructure through our partnership with Circle for USDC transactions. Every payment is automatically monitored for compliance and settles in real-time, providing instant verification for your transactions.',
    },
    {
      title: 'Currency Conversion',
      description: 'Through our integration with Bridge.xyz and Stripe, we offer seamless conversion of stablecoins to local currencies at competitive rates below traditional banking fees. Enjoy same-day settlement regardless of transaction size or destination.',
    },
    {
      title: 'Cost Reduction',
      description: 'Save up to 80% on international transfer fees compared to traditional banking.'
    },
    {
      title: 'Faster Settlement',
      description: 'Usually same-day settlement for international transactions, regardless of amount.'
    },
  ];

  const InfoSection: React.FC<InfoSectionProps> = ({ title, description }) => {
    return (
      <article className="flex flex-col justify-center mt-10 w-full max-md:max-w-full">
        <h2 className="text-2xl font-semibold tracking-normal leading-none">
          {title}
        </h2>
        <p className="mt-2 text-xl leading-6 max-md:max-w-full">
          {description}
        </p>
      </article>
    );
  };

  return (
    <main className="flex gap-10 text-slate-900">
      <section className="flex flex-col flex-1 shrink w-full basis-0 min-w-[240px] max-md:max-w-full">
        <h1 className="text-5xl font-extrabold tracking-tight leading-none max-md:max-w-full max-md:text-4xl">
          Understanding Stablecoin Payments
        </h1>
        <div className="flex flex-col mt-10 w-full max-md:max-w-full">
          {cryptoSections.map((section, index) => (
            <InfoSection
              key={index}
              title={section.title}
              description={section.description}
            />
          ))}
        </div>
      </section>
    </main>
  );
};
