import React from 'react';

interface NotificationCardProps {
  iconSrc: string;
  title: string;
  buttonText?: string;
  classname?: string;
  titleTextClassName?: string;
  onButtonClick?: () => void;
}

const NotificationCard: React.FC<NotificationCardProps> = ({
  iconSrc,
  title,
  buttonText,
  classname,
  titleTextClassName,
  onButtonClick,
}) => {
  return (
    <section
      className={`flex items-center justify-between bg-white rounded-md shadow-sm border border-slate-100 gap-4 ${classname}`}
    >
      <div className="flex items-center gap-3">
        <img
          loading="lazy"
          src={iconSrc}
          alt=""
          className="w-5 h-5 object-contain"
        />
        <h2 className={`font-semibold text-stone-900 ${titleTextClassName}`}>
          {title}
        </h2>
      </div>

      {buttonText && (
        <div className="flex items-center">
          <button
            className="px-4 py-2 text-sm font-medium bg-white rounded-md border border-slate-200 text-slate-900 cursor-pointer"
            onClick={onButtonClick}
          >
            {buttonText}
          </button>
        </div>
      )}
    </section>
  );
};

export default NotificationCard;
