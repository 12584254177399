import React, { useEffect, useState } from 'react';
import { formatUnits, createPublicClient, http } from 'viem';
import { erc20ABI } from 'wagmi';
import { useActiveAccount } from 'thirdweb/react';

import { polygonAmoy } from '../../config/wagmiConfig';
import useAllProjects from '../../hooks/useAllProjects';
import useModal from '../../hooks/useModal';
import { BankWithdrawModal } from '../Modal';
import { WalletWithdrawModal } from '../Modal';

const FundsOverview: React.FC = () => {
  const { projects } = useAllProjects();
  const activeAccount = useActiveAccount();
  const { openModal, closeModal } = useModal();

  const [contractBalances, setContractBalances] = useState<number[]>([]);
  const [smartContractBalance, setSmartContractBalance] = useState<number>(0);
  const [mainAccountBalance, setMainAccountBalance] = useState<number>(0);
  const [totalBalance, setTotalBalance] = useState<number>(0);

  const fetchContractBalance = async (project: any) => {
    try {
      const publicClient = createPublicClient({
        chain: polygonAmoy,
        transport: http(),
      });

      const balance = (await publicClient.readContract({
        address: project.paytoken_contract_address as `0x${string}`,
        abi: erc20ABI,
        functionName: 'balanceOf',
        args: [project.sale_contract_address as `0x${string}`],
      })) as bigint;
      const formattedBalance = Number(formatUnits(balance, 6));
      return formattedBalance;
    } catch (err) {
      console.warn(
        'Something went wrong while fetching smart contract balance',
        err
      );
      return 0;
    }
  };

  const openBankWithdrawModal = () => {
    openModal(<BankWithdrawModal projects={projects} onCancel={closeModal} />);
  };

  const openWalletWithdrawModal = () => {
    openModal(
      <WalletWithdrawModal projects={projects} onCancel={closeModal} />
    );
  };

  useEffect(() => {
    const fetchAllContractBalances = async () => {
      if (projects.length > 0) {
        const results = await Promise.all(
          projects.map(async (project) => {
            return await fetchContractBalance(project);
          })
        );

        setContractBalances(results);
      }
    };

    fetchAllContractBalances();
  }, [projects]);

  useEffect(() => {
    (async () => {
      if (activeAccount) {
        try {
          const publicClient = createPublicClient({
            chain: polygonAmoy,
            transport: http(),
          });

          const balance = (await publicClient.readContract({
            address:
              '0x41E94Eb019C0762f9Bfcf9Fb1E58725BfB0e7582' as `0x${string}`,
            abi: erc20ABI,
            functionName: 'balanceOf',
            args: [activeAccount.address as `0x${string}`],
          })) as bigint;
          const formattedBalance = Number(formatUnits(balance, 6));
          setMainAccountBalance(formattedBalance);
        } catch (err) {
          console.warn(
            'Something went wrong while fetching smart contract balance',
            err
          );
          return 0;
        }
      }
    })();
  }, [activeAccount]);

  useEffect(() => {
    if (contractBalances.length > 0) {
      const smartContractBalance = contractBalances.reduce(
        (acc, balance) => (balance ? acc + balance : acc),
        0
      );
      setSmartContractBalance(smartContractBalance ? smartContractBalance : 0);
    }
  }, [contractBalances]);

  useEffect(() => {
    setTotalBalance(smartContractBalance + mainAccountBalance);
  }, [smartContractBalance, mainAccountBalance]);

  const getPercentage = (balance: number, total: number) => {
    return total > 0 ? (balance / total) * 100 : 0;
  };

  const smartContractPercentage = getPercentage(
    smartContractBalance,
    totalBalance
  );
  const mainAccountPercentage = getPercentage(mainAccountBalance, totalBalance);

  return (
    <section className="p-6 bg-white rounded-md border border-slate-300 w-full">
      <header className="flex justify-between items-center w-full">
        <h1 className="text-lg font-semibold text-slate-900">Funds Overview</h1>
        <nav className="flex gap-2">
          <button
            className="px-4 py-2 bg-slate-100 rounded-md text-slate-900"
            onClick={openWalletWithdrawModal}
          >
            Withdraw from Smart Contracts
          </button>
          <button
            className="px-4 py-2 bg-slate-900 text-white rounded-md"
            onClick={openBankWithdrawModal}
          >
            Withdraw to Bank Account
          </button>
        </nav>
      </header>

      <div className="mt-6">
        <p className="text-3xl font-bold text-slate-900">
          {totalBalance.toFixed(2)} USDC
        </p>
      </div>

      <div className="mt-4 w-full">
        <div className="flex w-[500px] h-3 rounded-md overflow-hidden">
          <div
            className="bg-sky-500 h-full"
            style={{ width: `${smartContractPercentage}%` }}
          />
          <div
            className="bg-fuchsia-500 h-full"
            style={{ width: `${mainAccountPercentage}%` }}
          />
        </div>

        <div className="flex gap-4 items-center mt-2">
          <div className="flex items-center">
            <div className="w-3 h-3 bg-sky-500 rounded-full" />
            <p className="ml-2 text-xs text-slate-500">
              Smart Contracts: {smartContractBalance.toFixed(2)} USDC
            </p>
          </div>

          <div className="flex items-center">
            <div className="w-3 h-3 bg-fuchsia-500 rounded-full" />
            <p className="ml-2 text-xs text-slate-500">
              Main Account: {mainAccountBalance.toFixed(2)} USDC
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FundsOverview;
