import { useRef, useState, useCallback } from 'react';
import { cross } from '../../assets';
import { SaleCalendarCard } from '../Cards';
import { formatDateToYYYYMMDD } from '../../utils/conversion';
import { z } from 'zod';

import useFantomStarterAdminApi from '../../hooks/useFantomStarterAdminApi';
import { toast } from 'react-toastify';

interface ExtendDatesProps {
  onClose: () => void;
  project: any;
}

const maxDuration = 365;

const dateSchema = z
  .object({
    startDate: z.string().refine(
      (date) => {
        const start = new Date(date);
        const now = new Date();
        return start > now;
      },
      {
        message: 'Start date must be in the future',
      }
    ),
    endDate: z.string().refine((date) => !isNaN(new Date(date).getTime()), {
      message: 'Invalid end date',
    }),
  })
  .superRefine((data, ctx) => {
    const start = new Date(data.startDate);
    const end = new Date(data.endDate);

    if (end <= start) {
      ctx.addIssue({
        code: 'custom',
        path: ['endDate'],
        message: 'End date must be after start date',
      });
    }

    const maxEndDate = new Date(start);
    maxEndDate.setDate(maxEndDate.getDate() + maxDuration);
    if (end > maxEndDate) {
      ctx.addIssue({
        code: 'custom',
        path: ['endDate'],
        message: `End date cannot be more than ${maxDuration} days after the start date`,
      });
    }
  });

const ExtendDates: React.FC<ExtendDatesProps> = ({ onClose, project }) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const {
    admin: { updateFundingDates },
  } = useFantomStarterAdminApi();

  const [startDate, setStartDate] = useState<string | null>(
    formatDateToYYYYMMDD(project.start_date_funding) || null
  );
  const [endDate, setEndDate] = useState<string | null>(
    formatDateToYYYYMMDD(project.end_date_funding) || null
  );
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [isUpdating, setIsUpdating] = useState(false);

  const handleDateChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    setState: React.Dispatch<React.SetStateAction<string | null>>
  ) => {
    setState(event.target.value);
  };

  const handleUpdate = useCallback(async () => {
    setIsUpdating(true);
    const result = dateSchema.safeParse({ startDate, endDate });
    if (!result.success) {
      const newErrors = result.error.errors.reduce(
        (acc, error) => {
          acc[error.path[0]] = error.message;
          return acc;
        },
        {} as Record<string, string>
      );
      setErrors(newErrors);
    } else {
      setErrors({});
      console.log('Update dates', startDate, endDate);

      try {
        const response = await updateFundingDates({
          id: project.id,
          project_id: project.id,
          start_date_funding: startDate,
          end_date_funding: endDate,
        });

        if (response.status === 200) {
          toast.success('Updated funding dates!', {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
          onClose();
        } else {
          toast.error('Failed to update funding dates!', {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
        }
      } catch (error) {
        console.error('Error updating funding dates:', error);
        toast.error('Failed to update funding dates!', {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    }
    setIsUpdating(false);
  }, [startDate, endDate, project.id, updateFundingDates, onClose]);

  return (
    <>
      <div>
        <div className="flex justify-center items-center fixed inset-0 z-50 outline-none focus:outline-none overflow-x-hidden overflow-y-auto">
          <div className="relative w-[400px] my-6 mx-auto">
            <section className="flex flex-col p-6 bg-white rounded-md shadow-sm max-w-[400px]">
              <header className="w-full">
                <div className="flex justify-center items-center text-2xl font-semibold text-slate-900">
                  <h1 className="flex-1 font-semibold leading-8">
                    Extend Sale Period
                  </h1>
                  <img
                    src={cross}
                    className="w-6 cursor-pointer"
                    alt="cross"
                    onClick={() => onClose()}
                  />
                </div>
              </header>

              <SaleCalendarCard
                startDate={startDate}
                endDate={endDate}
                errors={errors}
                handleDateChange={handleDateChange}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                startDateInputRef={useRef(null)}
                endDateInputRef={useRef(null)}
                withHeaderTitle={false}
              />

              <button
                className="btn-action-required justify-center mt-5"
                onClick={handleUpdate}
                disabled={isUpdating}
              >
                {isUpdating ? 'Updating...' : 'Update'}
              </button>
            </section>
          </div>
        </div>
        <div
          ref={modalRef}
          className="fixed inset-0 z-40 bg-black bg-opacity-70 backdrop-blur-[2px]"
        />
      </div>
    </>
  );
};

export default ExtendDates;
