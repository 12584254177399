import * as React from 'react';
import { Link } from 'react-router-dom';

interface HeroSectionProps {
  title: string;
  backgroundImage: string;
}

export function HeroSection({ title, backgroundImage }: HeroSectionProps) {
  // Split the title where we want the line break
  const titleParts = title.split('business');

  return (
    <section className="flex flex-col font-bold text-white">
      <div className="relative flex w-full h-[673px] max-md:max-w-full">
        <img
          loading="lazy"
          src={backgroundImage}
          alt=""
          className="object-cover absolute inset-0 w-full h-full z-0"
        />
        <div className="relative flex flex-col justify-center items-center w-full h-full bg-black bg-opacity-20 text-center">
          <h1 className="text-7xl tracking-tighter leading-[86px] max-md:text-4xl max-md:leading-[53px]">
            {titleParts[0]}business<br />
            {titleParts[1]}
          </h1>
          <Link to="/sales/create">
            <button
              className="flex gap-2 justify-center items-center p-4 mt-10 text-base rounded-md min-h-[56px] btn-primary"
              aria-label="Start a token sale"
            >
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/f3e04e1b75db3c46db510eaee1b0db20e6d7f3d25f566f4268e5eb402d098ed8?placeholderIfAbsent=true&apiKey=466c088da2e142d481f76ae5c06a8ddd"
                alt=""
                className="object-contain w-4 aspect-square"
              />
              <span>Start a Tokenized Sale</span>
            </button>
          </Link>
        </div>
      </div>
    </section>
  );
}