import { useEffect, useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import debounce from 'lodash.debounce';
import { useNavigate } from 'react-router-dom';

import useAllProjects from '../../hooks/useAllProjects';
import useFantomStarterAdminApi from '../../hooks/useFantomStarterAdminApi';
import { DesktopLayout } from '../../components/Layouts';
import { NoContentFound } from '../../components/NoContentFound';
import { SaleCard } from '../../components/Cards';
import { search } from '../../assets';
import { extractNameAndTag } from '../../utils/conversion';
import useDocusignApi from '../../hooks/useDocusign';
import { NotificationCard } from '../../components/Cards';
import { useActiveAccount } from 'thirdweb/react';
import { RegisteredBankAccount } from '../../components/Settings/BankAccount';

const Sales = () => {
  const tabs = ['All', 'Villas', 'Apartments', 'Land'];
  const { projects, refetch: refetchProjects } = useAllProjects();
  const {
    circle: { getWireBanks },
  } = useFantomStarterAdminApi();
  const { getTemplates } = useDocusignApi();
  const navigate = useNavigate();
  const activeAccount = useActiveAccount();

  const registeredBanks: RegisteredBankAccount[] = JSON.parse(
    localStorage.getItem(`${activeAccount?.address}-banks`) || '[]'
  );

  const [activeTab, setActiveTab] = useState('All');
  const [searchQuery, setSearchQuery] = useState('');
  const [userWireIds, setUserWireIds] = useState<string[]>([]);
  const [templateHash, setTemplateHash] = useState<{
    [templateName: string]: any;
  }>({});

  const isAccountLinked = userWireIds.length > 0 || registeredBanks.length > 0;

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    debouncedSearch(e.target.value);
  };

  const debouncedSearch = useMemo(
    () => debounce((query: string) => setSearchQuery(query), 300),
    []
  );

  const filteredProjects = useMemo(() => {
    let filtered = projects;

    if (activeTab !== 'All') {
      filtered = projects.filter((project) => {
        const projectName = extractNameAndTag(project.name).name.toLowerCase();
        if (activeTab === 'Villas') {
          return projectName.includes('villa');
        } else if (activeTab === 'Apartments') {
          return projectName.includes('apartment');
        } else if (activeTab === 'Land') {
          return projectName.includes('land');
        }
        return true;
      });
    }

    if (searchQuery) {
      const lowercasedQuery = searchQuery.toLowerCase();
      filtered = filtered.filter(
        (project) =>
          project.name.toLowerCase().includes(lowercasedQuery) ||
          project.id.toLowerCase().includes(lowercasedQuery)
      );
    }

    return filtered;
  }, [activeTab, projects, searchQuery]);

  useEffect(() => {
    (async () => {
      const result = await getWireBanks();
      if (result.status === 200) {
        setUserWireIds(result.data || []);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  useEffect(() => {
    getTemplates().then((result: any) => {
      if (!result || !result.envelopeTemplates) return;
      const templates: any[] = result.envelopeTemplates;
      const hash: { [templateName: string]: any } = {};
      templates.forEach((template) => (hash[template.name] = template));
      setTemplateHash(hash);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DesktopLayout>
      <main className="pt-[92px] sm:px-32 px-5 flex flex-col sm:flex-row gap-5 sm:gap-20 items-start sm:items-center">
        <section className="mt-16 flex flex-col px-32 md:px-5 w-full">
          {!isAccountLinked && (
            <div className="w-full relative">
              <NotificationCard
                iconSrc="https://cdn.builder.io/api/v1/image/assets/TEMP/8920958a31835bb01a6af01f9bb07f6adcfa9ed6bdd54aab31f47266d6627f5d?placeholderIfAbsent=true&apiKey=466c088da2e142d481f76ae5c06a8ddd"
                title="Complete your seller profile to accept payments"
                buttonText="Link bank account"
                classname="-top-20 box-border w-full absolute p-4"
                onButtonClick={() => navigate('/settings?tab=Bank%20Account')}
              />
            </div>
          )}
          <header className="flex gap-4 items-center w-full text-sm font-medium justify-between">
            <nav className="flex min-w-[240px] text-slate-700 w-[280px]">
              <div className="flex items-start p-1.5 w-full rounded-md bg-slate-100">
                {tabs.map((label) => (
                  <div
                    key={label}
                    className={`flex gap-2.5 px-3 py-1.5 rounded cursor-pointer ${
                      activeTab === label ? 'bg-white text-slate-900' : ''
                    }`}
                    onClick={() => setActiveTab(label)}
                  >
                    <span>{label}</span>
                  </div>
                ))}
              </div>
            </nav>

            <div className="flex gap-4 justify-between items-center">
              <div className="flex min-w-[280px] items-center rounded-md border shadow-sm border-slate-200 bg-white px-2 py-2.5 gap-2">
                <img
                  loading="lazy"
                  src={search}
                  className="w-4"
                  alt="Search icon"
                />
                <input
                  type="search"
                  placeholder="Search for a sale"
                  className="flex-1 bg-transparent border-none outline-none"
                  aria-label="Search for a sale"
                  onChange={handleSearchChange}
                />
              </div>
              <Link to="/sales/create">
                <button className="btn-primary">Start a sale</button>
              </Link>
            </div>
          </header>
          {filteredProjects.length > 0 ? (
            <section className="mt-6 pb-4 grid gap-6 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
              {filteredProjects.map((project) => (
                <SaleCard
                  key={project.id}
                  project={project}
                  templateHash={templateHash}
                  refetchProjects={refetchProjects}
                />
              ))}
            </section>
          ) : (
            <NoContentFound />
          )}
        </section>
      </main>
    </DesktopLayout>
  );
};

export default Sales;
