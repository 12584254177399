import React, { useState, useEffect } from 'react';
import { z } from 'zod';
import { v4 as uuidv4 } from 'uuid';

import { ICreateCustomerParams } from '../../hooks/useFantomStarterAdminApi';

interface CreateCustomerFormProps {
  onCancel: () => void;
  onSave: (customerData: ICreateCustomerParams) => Promise<void>;
  signedAgreementId: string;
}

const customerSchema = z.object({
  type: z.enum(['individual', 'business']),
  first_name: z.string().min(1, 'First name is required.'),
  last_name: z.string().min(1, 'Last name is required.'),
  email: z.string().email('Invalid email address.'),
  phone: z.string().min(10, 'Phone number is required.'),
  birth_date: z.string().min(1, 'Birth date is required.'),
  tax_identification_number: z.string().min(1, 'Tax ID is required.'),
  address: z.object({
    street_line_1: z.string().min(1, 'Address line 1 is required.'),
    street_line_2: z.string().optional(),
    city: z.string().min(1, 'City is required.'),
    state: z
      .string()
      .regex(/^[A-Z0-9-]{1,3}$/, 'Invalid ISO 3166-2 subdivision code.'),
    postal_code: z.string().min(1, 'Postal code is required.'),
    country: z
      .string()
      .length(3, 'Country code must be ISO 3166-1 alpha-3.')
      .regex(/^[A-Z]{3}$/, 'Invalid country code format.'),
  }),
});

const CreateCustomerForm: React.FC<CreateCustomerFormProps> = ({
  onCancel,
  onSave,
  signedAgreementId,
}) => {
  const [formData, setFormData] = useState<ICreateCustomerParams>({
    type: 'individual',
    first_name: 'John',
    last_name: 'Doe',
    email: 'johndoe@johndoe.com',
    phone: '+15555555555',
    signed_agreement_id: signedAgreementId,
    birth_date: '1989-09-09',
    tax_identification_number: '111-11-1111',
    idempotencyKey: '',
    address: {
      street_line_1: '123 Washington St',
      street_line_2: 'Apt 2F',
      city: 'San Francisco',
      state: 'CA',
      postal_code: '10001',
      country: 'USA',
    },
  });

  const [errors, setErrors] = useState<Record<string, string>>({});
  const [currentStep, setCurrentStep] = useState(1);

  const [isCreating, setIsCreating] = useState(false);

  useEffect(() => {
    setFormData((prev) => ({ ...prev, idempotencyKey: uuidv4() }));
  }, []);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;

    if (name in formData.address) {
      setFormData((prev) => ({
        ...prev,
        address: { ...prev.address, [name]: value },
      }));
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleNext = () => {
    if (currentStep === 1) {
      try {
        customerSchema
          .pick({
            type: true,
            first_name: true,
            last_name: true,
            email: true,
            phone: true,
            birth_date: true,
            tax_identification_number: true,
          })
          .parse(formData);
        setCurrentStep(2);
      } catch (err: any) {
        if (err instanceof z.ZodError) {
          const validationErrors: Record<string, string> = {};
          err.errors.forEach((error) => {
            const path = error.path.join('.');
            validationErrors[path] = error.message;
          });
          setErrors(validationErrors);
        }
      }
    }
  };

  const handleSave = async () => {
    setIsCreating(true);
    try {
      customerSchema.parse(formData);
      await onSave(formData);
    } catch (err: any) {
      if (err instanceof z.ZodError) {
        const validationErrors: Record<string, string> = {};
        err.errors.forEach((error) => {
          const path = error.path.join('.');
          validationErrors[path] = error.message;
        });
        setErrors(validationErrors);
      }
    } finally {
      setIsCreating(false);
    }
  };

  const renderStep1 = () => (
    <>
      <div className="flex items-center">
        <label className="w-64 font-medium text-slate-700">Type</label>
        <div className="flex-1">
          <select
            name="type"
            value={formData.type}
            onChange={handleChange}
            className="w-full py-2 px-3 bg-white border border-slate-300 rounded-md text-slate-700"
          >
            <option value="individual">Individual</option>
            <option value="business">Business</option>
          </select>
        </div>
      </div>

      {[
        { label: 'First Name', name: 'first_name', type: 'text' },
        { label: 'Last Name', name: 'last_name', type: 'text' },
        { label: 'Email', name: 'email', type: 'email' },
        { label: 'Phone', name: 'phone', type: 'tel' },
        { label: 'Birth Date', name: 'birth_date', type: 'date' },
        { label: 'Tax ID', name: 'tax_identification_number', type: 'text' },
      ].map(({ label, name, type }) => (
        <div key={name} className="flex items-center">
          <label className="w-64 font-medium text-slate-700">{label}</label>
          <div className="flex-1">
            <input
              type={type}
              name={name}
              value={(formData as any)[name]}
              onChange={handleChange}
              className="w-full py-2 px-3 bg-white border border-slate-300 rounded-md text-slate-700"
            />
            {errors[name] && (
              <p className="text-red-500 text-sm mt-1">{errors[name]}</p>
            )}
          </div>
        </div>
      ))}
    </>
  );

  const renderStep2 = () => (
    <>
      <h2 className="text-xl font-semibold">Address</h2>
      {[
        { label: 'Street Line 1', name: 'street_line_1' },
        { label: 'Street Line 2', name: 'street_line_2' },
        { label: 'City', name: 'city' },
        { label: 'State', name: 'state' },
        { label: 'Postal Code', name: 'postal_code' },
        { label: 'Country', name: 'country' },
      ].map(({ label, name }) => (
        <div key={name} className="flex items-center">
          <label className="w-64 font-medium text-slate-700">{label}</label>
          <div className="flex-1">
            <input
              type="text"
              name={name}
              value={(formData.address as any)[name]}
              onChange={handleChange}
              className="w-full py-2 px-3 bg-white border border-slate-300 rounded-md text-slate-700"
            />
            {errors[`address.${name}`] && (
              <p className="text-red-500 text-sm mt-1">
                {errors[`address.${name}`]}
              </p>
            )}
          </div>
        </div>
      ))}
    </>
  );

  return (
    <section className="flex flex-col px-6 pt-6 pb-4 bg-white rounded-md shadow-sm border border-slate-200 max-w-[600px] z-50">
      <header className="mb-4">
        <h1 className="text-2xl font-semibold text-slate-900">
          Create Customer
        </h1>
      </header>

      <form className="flex flex-col gap-4">
        {currentStep === 1 ? renderStep1() : renderStep2()}

        <footer className="flex justify-end space-x-4">
          {currentStep > 1 && (
            <button
              type="button"
              className="btn-text w-1/2"
              onClick={() => setCurrentStep(1)}
            >
              Back
            </button>
          )}
          {currentStep === 1 ? (
            <button
              type="button"
              className="btn-primary w-1/2"
              onClick={handleNext}
            >
              Next
            </button>
          ) : (
            <button
              type="button"
              className="btn-primary w-1/2"
              onClick={handleSave}
            >
              {isCreating ? 'Creating...' : 'Save'}
            </button>
          )}
        </footer>
      </form>
    </section>
  );
};

export default CreateCustomerForm;
