interface SaleContractCardProps {
  title: string;
  buttonText: string;
  placeholderText: string;
  handleFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  errors: Record<string, string>;
}

const SaleContractCard: React.FC<SaleContractCardProps> = ({
  title,
  buttonText,
  placeholderText,
  handleFileChange,
  errors,
}) => {
  return (
    <section className="flex flex-col mt-6 px-6 py-4 text-sm bg-white rounded-md border border-solid border-slate-300 max-w-[552px] max-md:px-5">
      <header className="flex flex-col w-full font-semibold leading-none text-black whitespace-nowrap max-md:max-w-full">
        <h2 className="flex gap-2 items-center w-full max-md:max-w-full">
          {title}
        </h2>
        <hr className="mt-4 w-full border border-solid border-slate-200 min-h-[1px] max-md:max-w-full" />
      </header>

      <form className="flex flex-col mt-8 w-full font-medium max-md:max-w-full">
        <div className="flex flex-wrap gap-2 items-center w-full max-md:max-w-full">
          <label htmlFor="fileInput" className="sr-only">
            Upload file
          </label>
          <button
            type="button"
            onClick={() => document.getElementById('fileInput')?.click()}
            className="gap-2.5 self-stretch px-4 py-2 my-auto leading-6 whitespace-nowrap rounded-md bg-slate-100 text-slate-900 w-[94px]"
            tabIndex={0}
          >
            {buttonText}
          </button>
          <input
            type="file"
            id="fileInput"
            className="hidden"
            onChange={handleFileChange}
            aria-label="File upload input"
          />
          <p className="flex-1 shrink self-stretch my-auto leading-none basis-8 text-slate-500">
            {placeholderText}
          </p>
        </div>
        {errors.contractFile && (
          <span className="text-red-500 mt-2">{errors.contractFile}</span>
        )}
      </form>
    </section>
  );
};

export default SaleContractCard;
