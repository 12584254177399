interface SalePriceProps {
  raising: string;
  pricePerUnit: string;
  maxInvestment: string;
  totalRaiseGoal: string;
  setRaising: React.Dispatch<React.SetStateAction<string>>;
  setPricePerUnit: React.Dispatch<React.SetStateAction<string>>;
  setMaxInvestment: React.Dispatch<React.SetStateAction<string>>;
  setTotalRaiseGoal: React.Dispatch<React.SetStateAction<string>>;
  errors: Record<string, string>;
}

export default function SalePriceCard({
  raising,
  pricePerUnit,
  maxInvestment,
  totalRaiseGoal,
  setRaising,
  setPricePerUnit,
  setMaxInvestment,
  setTotalRaiseGoal,
  errors,
}: SalePriceProps) {
  const inputFields = [
    {
      label: 'Raising in (axlUSDC)',
      value: raising,
      id: 'raising',
      setter: setRaising,
    },
    {
      label: 'Price per unit',
      value: pricePerUnit,
      id: 'pricePerUnit',
      setter: setPricePerUnit,
    },
    {
      label: 'Maximum investment',
      value: maxInvestment,
      id: 'maxInvestment',
      setter: setMaxInvestment,
    },
    {
      label: 'Total raise goal',
      value: totalRaiseGoal,
      id: 'totalRaiseGoal',
      setter: setTotalRaiseGoal,
    },
  ];

  return (
    <form className="flex mt-6 flex-col px-6 py-4 text-sm leading-none bg-white rounded-md border border-solid border-slate-300 max-w-[552px] max-md:px-5">
      <section className="flex flex-col w-full font-semibold text-black whitespace-nowrap max-md:max-w-full">
        <header className="flex flex-col w-full max-md:max-w-full">
          <h2 className="flex gap-2 items-center w-full max-md:max-w-full">
            <span className="flex-1 shrink gap-1 self-stretch my-auto w-full min-w-[240px] max-md:max-w-full">
              Price
            </span>
          </h2>
        </header>
        <div className="mt-4 w-full border border-solid border-slate-200 min-h-[1px] max-md:max-w-full" />
      </section>

      <section className="flex flex-col mt-8 w-full max-md:max-w-full">
        {inputFields.map((field) => (
          <div
            key={field.id}
            className="flex flex-col justify-center mt-4 first:mt-0 w-full max-md:max-w-full"
          >
            <div className="flex flex-wrap gap-4 items-center w-full max-md:max-w-full">
              <label
                htmlFor={field.id}
                className="flex-1 shrink self-stretch my-auto font-medium text-black basis-0"
              >
                {field.label}
              </label>
              <div className="flex flex-col self-stretch my-auto whitespace-nowrap min-w-[240px] text-slate-400 w-[244px]">
                <input
                  type={field.id === 'raising' ? 'text' : 'number'}
                  id={field.id}
                  value={field.value}
                  onChange={(e) => field.setter(e.target.value)}
                  className="self-stretch py-2 pr-14 pl-3 w-full bg-white rounded-md border border-solid border-slate-300 max-md:pr-5"
                  aria-label={field.label}
                />
                {errors[field.id] && (
                  <span className="text-red-500 text-xs mt-1">
                    {errors[field.id]}
                  </span>
                )}
              </div>
            </div>
          </div>
        ))}
      </section>
    </form>
  );
}
