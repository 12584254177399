import React from 'react';
import { Link } from 'react-router-dom';
import { search, filter } from '../../assets';

interface SearchActionsProps {
  onSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onFilterClick: () => void;
  onExportClick: () => void;
  onStartSaleClick: () => void;
}

const SearchActions: React.FC<SearchActionsProps> = ({
  onSearchChange,
  onFilterClick,
  onExportClick,
  onStartSaleClick,
}) => {
  return (
    <nav className="flex items-center justify-end gap-4 mt-4 py-2 bg-white w-full">
      <div className="flex items-center gap-2 px-2 py-2.5 w-[280px] text-sm leading-none border rounded-md shadow-sm">
        <img
          loading="lazy"
          src={search}
          alt=""
          className="w-4 h-4 object-contain"
        />
        <input
          type="text"
          placeholder="Search for a sale"
          className="flex-1 text-slate-400 bg-transparent border-none outline-none placeholder-opacity-50"
          onChange={onSearchChange}
          aria-label="Search for a sale"
        />
      </div>

      <button
        className="flex justify-center items-center p-2 bg-white border rounded-md"
        onClick={onFilterClick}
      >
        <img
          loading="lazy"
          src={filter}
          alt={filter}
          className="w-4 h-4 object-contain mr-2"
        />
        Filter
      </button>

      <button className="btn-action p-3" onClick={onExportClick}>
        Export.CVS
      </button>

      <Link to="/sales/create">
        <button className="btn-primary" onClick={onStartSaleClick}>
          Start a sale
        </button>
      </Link>
    </nav>
  );
};

export default SearchActions;
