import React, { PropsWithChildren, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useActiveWallet, useActiveAccount } from 'thirdweb/react';
import useToken from '../hooks/useToken';

export const ThirdwebContext = React.createContext<any>(null);

export const ThirdwebAccountProvider: React.FC<PropsWithChildren<any>> = ({
  children,
}) => {
  const wallet = useActiveWallet();
  const activeAccount = useActiveAccount();
  const { logout } = useToken();
  const navigate = useNavigate();

  const [isConnected, setIsConnected] = useState(false);
  const [address, setAddress] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (wallet) {
      wallet.subscribe('disconnect', () => {
        setIsConnected(false);
        setAddress(undefined);

        logout();
        navigate('/auth/login');
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wallet]);

  useEffect(() => {
    if (activeAccount && activeAccount.address) {
      setIsConnected(true);
      setAddress(activeAccount.address);
    }
  }, [activeAccount]);

  return (
    <ThirdwebContext.Provider value={[isConnected, address]}>
      {children}
    </ThirdwebContext.Provider>
  );
};
