import React from 'react';

type Tab = {
  label: string;
  isActive: boolean;
};

interface TabProps {
  tabs: Tab[];
  onTabClick: (index: number) => void;
}

const Tabs: React.FC<TabProps> = ({ tabs, onTabClick }) => {
  return (
    <nav className="flex py-1.5 px-1.5 mt-10 rounded-md bg-slate-100 text-slate-700">
      {tabs.map((tab, index) => (
        <button
          key={index}
          onClick={() => onTabClick(index)}
          className={`px-3 py-1.5 rounded ${
            tab.isActive ? 'bg-white text-slate-900' : 'text-slate-700'
          }`}
        >
          {tab.label}
        </button>
      ))}
    </nav>
  );
};

export default Tabs;
