import { FC } from 'react';

interface IMenuItem {
  text: string;
  color: string;
  onClick: () => void;
}

interface ISaleCardMenuProps {
  items: IMenuItem[];
}

const SaleCardMenu: FC<ISaleCardMenuProps> = ({ items }) => {
  return (
    <nav
      aria-label="Main Menu"
      className="max-w-xs min-w-[216px] rounded-md shadow-sm border border-slate-100"
    >
      <ul className="flex flex-col p-2 bg-white rounded-sm">
        {items.map((item, index) => (
          <li
            key={index}
            className={
              index === items.length - 1 ? 'border-t border-slate-200' : ''
            }
          >
            <button
              className={`w-full text-left py-1 px-2 text-sm text-${item.color} hover:bg-slate-50 focus:outline-none`}
              onClick={() => item.onClick()}
            >
              {item.text}
            </button>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default SaleCardMenu;
