import { useEffect, useRef } from 'react';
import { useDisconnect } from 'thirdweb/react';

type TimeoutId = ReturnType<typeof setTimeout> | null;

const useSessionTimeout = (
  activeWallet: any,
  timeoutDuration: number = 300000
): { resetTimer: () => void } => {
  const timerId = useRef<TimeoutId>(null);

  const { disconnect } = useDisconnect();

  const resetTimer = () => {
    if (timerId.current) clearTimeout(timerId.current);

    timerId.current = setTimeout(() => {
      if (activeWallet) disconnect(activeWallet);
    }, timeoutDuration);
  };

  useEffect(() => {
    const events: Array<keyof WindowEventMap> = [
      'mousemove',
      'mousedown',
      'keydown',
      'scroll',
      'touchstart',
    ];

    events.forEach((event) => window.addEventListener(event, resetTimer));

    resetTimer();

    return () => {
      if (timerId.current) clearTimeout(timerId.current);
      events.forEach((event) => window.removeEventListener(event, resetTimer));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeoutDuration]);

  return { resetTimer };
};

export default useSessionTimeout;
