import React from 'react';

interface TooltipProps {
  message: string;
  actionText: string;
  onAction: () => void;
}

const Tooltip: React.FC<TooltipProps> = ({ message, actionText, onAction }) => {
  return (
    <div className="flex gap-2 items-center px-3.5 py-2 text-sm font-medium bg-white rounded-md shadow-[0px_2px_4px_rgba(30,41,59,0.25)]">
      <p className="self-stretch whitespace-nowrap my-auto leading-none text-slate-700">
        {message}
      </p>
      <button
        onClick={onAction}
        className="gap-2.5 self-stretch whitespace-nowrap py-2 my-auto leading-6 rounded-md bg-white bg-opacity-0 text-slate-900"
      >
        {actionText}
      </button>
    </div>
  );
};

export default Tooltip;
