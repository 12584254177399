import React, { useState, useRef, ChangeEvent, useCallback } from 'react';
import { useActiveAccount } from 'thirdweb/react';
import { z } from 'zod';
import {
  SaleDescriptionCard,
  SaleCalendarCard,
  SaleStrategyCard,
  SalePriceCard,
  SaleContractCard,
  VisualsUploaderCard,
} from '../Cards';
import useFantomStarterAdminApi from '../../hooks/useFantomStarterAdminApi';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import useDocusignApi from '../../hooks/useDocusign';

interface SaleOption {
  title: string;
  maxWinners: number;
}

const CreateSaleForm: React.FC = () => {
  const activeAccount = useActiveAccount();
  const companyName =
    localStorage.getItem(`${activeAccount?.address}-companyName`) || null;

  const [propertyLogo, setPropertyLogo] = useState<File | null>(null);
  const [mainPropertyImage, setMainPropertyImage] = useState<File | null>(null);
  const [propertyLogoUrl, setPropertyLogoUrl] = useState<string | null>(null);
  const [mainPropertyImageUrl, setMainPropertyImageUrl] = useState<
    string | null
  >(null);
  const [saleName, setSaleName] = useState<string>('');
  const [saleTagline, setSaleTagline] = useState<string>('');
  const [startDate, setStartDate] = useState<string | null>(null);
  const [endDate, setEndDate] = useState<string | null>(null);
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const [isRegisteredMembership, setIsRegisteredMembership] =
    useState<boolean>(false);
  const [saleSummary, setSaleSummary] = useState({
    terms: [
      { title: 'Investment type', value: '' },
      { title: 'Accepted tiers', value: '' },
      { title: 'Funding cap', value: '' },
      { title: 'Individual cap', value: '' },
      { title: 'Token', value: '' },
      { title: 'Token price', value: '' },
      { title: 'Tokens for sale', value: '' },
      { title: 'Payment accepted', value: '' },
    ],
    paragraphs: [],
  });
  const [raising, setRaising] = useState<string>('USDC');
  const [pricePerUnit, setPricePerUnit] = useState<string>('0.5');
  const [maxInvestment, setMaxInvestment] = useState<string>('1');
  const [totalRaiseGoal, setTotalRaiseGoal] = useState<string>('2');
  const [contractFile, setContractFile] = useState<File | null>(null);

  const [errors, setErrors] = useState<Record<string, string>>({});
  const [isUploading, setIsUploading] = useState<boolean>(false);

  const navigate = useNavigate();
  const fantomStarterAdminApi = useFantomStarterAdminApi();
  const { createProject, saveProjectSummary, updateProjectDetails } =
    fantomStarterAdminApi.admin;
  const { createTemplate } = useDocusignApi();

  const saleOptions: SaleOption[] = [
    { title: 'Open', maxWinners: 25 },
    { title: 'Private', maxWinners: 50 },
    { title: 'Auction', maxWinners: 100 },
  ];

  const schema = z.object({
    saleName: z.string().min(1, 'Enter sale name'),
    saleTagline: z.string().min(1, 'Enter sale tagline'),
    selectedOption: z.string().nonempty('Select a sale strategy'),
    propertyLogoUrl: z.string().min(1, 'Enter property logo'),
    mainPropertyImageUrl: z.string().min(1, 'Enter main property image'),
    startDate: z.string().min(1, 'Enter start date'),
    endDate: z.string().min(1, 'Enter end date'),
    saleSummary: z.object({
      paragraphs: z
        .array(
          z.object({
            title: z.string().optional(),
            tag: z.string().optional(),
            text: z.string().optional(),
            image: z.string().optional(),
          })
        )
        .min(1, 'Sale summary must contain at least one paragraph'),
    }),
    raising: z.string().min(1, 'Raising amount is required'),
    pricePerUnit: z.string().min(1, 'Price per unit is required'),
    maxInvestment: z.string().min(1, 'Maximum investment is required'),
    totalRaiseGoal: z.string().min(1, 'Total raise goal is required'),
    contractFile: z.custom<File | null>((file) => file instanceof File, {
      message: 'Upload a contract file',
    }),
  });

  console.log(saleSummary);

  const handleDrop = (
    event: React.DragEvent<HTMLDivElement>,
    setImage: React.Dispatch<React.SetStateAction<File | null>>
  ) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file) {
      setImage(file);
    }
  };

  const handleFileChange = (
    event: ChangeEvent<HTMLInputElement>,
    setImage: React.Dispatch<React.SetStateAction<File | null>>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      setImage(file);
    }
  };

  const handleContractChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setContractFile(file);
    }
  };

  const uploadImageToCloudinary = async (file: File) => {
    const uploadPreset =
      process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET ?? 'default_upload_preset';
    const cloudName =
      process.env.REACT_APP_CLOUDINARY_CLOUD_NAME ?? 'default_cloud_name';

    const data = new FormData();
    data.append('file', file);
    data.append('upload_preset', uploadPreset);
    data.append('cloud_name', cloudName);

    const response = await fetch(
      `https://api.cloudinary.com/v1_1/${cloudName}/image/upload`,
      {
        method: 'POST',
        body: data,
      }
    );
    const result = await response.json();
    return result.secure_url;
  };

  const handleCreateTemplate = useCallback(
    async (projectId: string) => {
      if (!contractFile) return;
      try {
        toast.info('Creating template...', {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
        const result = await createTemplate(
          `${projectId} template`,
          contractFile
        );
        if (result.templateId) {
          toast.success('Template created successfully', {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
        } else {
          toast.error('Failed to create template', {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
        }
      } catch (error) {
        console.error('Error creating template:', error);
        toast.error('Error creating template. Please try again.', {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [contractFile, createTemplate]
  );

  const handleSave = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setIsUploading(true);

    try {
      let uploadedPropertyLogoUrl = propertyLogoUrl;
      let uploadedMainPropertyImageUrl = mainPropertyImageUrl;

      if (propertyLogo) {
        uploadedPropertyLogoUrl = await uploadImageToCloudinary(propertyLogo);
        setPropertyLogoUrl(uploadedPropertyLogoUrl);
      }
      if (mainPropertyImage) {
        uploadedMainPropertyImageUrl =
          await uploadImageToCloudinary(mainPropertyImage);
        setMainPropertyImageUrl(uploadedMainPropertyImageUrl);
      }

      const result = schema.safeParse({
        saleName,
        saleTagline,
        selectedOption: selectedOption || '',
        propertyLogoUrl: uploadedPropertyLogoUrl || '',
        mainPropertyImageUrl: uploadedMainPropertyImageUrl || '',
        startDate: startDate || '',
        endDate: endDate || '',
        saleSummary,
        raising,
        pricePerUnit,
        maxInvestment,
        totalRaiseGoal,
        contractFile,
      });

      if (result.success) {
        setErrors({});

        const apiResult = await createProject({
          name: `${saleName} --rwa`,
          description: saleTagline,
          tags: [],
          chain: '80002', // todo: mainnet testnet chain id depending on the deployment
          logo_image_url: uploadedPropertyLogoUrl || '',
          main_image_url: uploadedMainPropertyImageUrl || '',
          start_date_whitelist: null,
          end_date_whitelist: null,
          start_date_funding: startDate,
          end_date_funding: endDate,
          ido_token_symbol: 'NFT',
          pay_token_symbol: raising,
          cap_total_dollars: totalRaiseGoal,
          cap_individual_dollars: maxInvestment,
          ratio_idotoken_per_paytoken: 1 / Number(pricePerUnit),
          ratio_paytoken_per_idotoken: pricePerUnit,
          sale_version: 3,
          with_whitelist: selectedOption === 'Private',
          with_lottery: selectedOption === 'Auction',
          only_staked: false,
          with_kyc: false,
          header_image_url: uploadedMainPropertyImageUrl,
          company: companyName,
          location: null,
        });

        if (apiResult.status === 200) {
          toast.success('Project Successfully Created!', {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });

          const createdProjectId = apiResult.data.result.data.projectId;

          try {
            const response = await updateProjectDetails({
              id: createdProjectId,
              project_id: createdProjectId,
              name: `${saleName} --rwa`,
              tags: [],
              logo_image_url: uploadedPropertyLogoUrl || '',
              chain: 80002,
              pay_token_symbol: raising,
              cap_total_dollars: totalRaiseGoal,
              cap_individual_dollars: maxInvestment,
              ratio_paytoken_per_idotoken: pricePerUnit,
            });

            if (response.status === 200) {
              toast.success('Updated Project Details!', {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
              });
              localStorage.setItem(`editStepDone-${createdProjectId}`, 'true');
            } else {
              toast.error('Failed to update details!', {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
              });
            }
          } catch (error) {
            console.error('Error updating project details:', error);
            toast.error(
              'Failed to update details, please do it from sales page!',
              {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
              }
            );
          }

          try {
            await saveProjectSummary(createdProjectId, saleSummary);
          } catch (error) {
            console.error('Error saving project summary:', error);
            toast.info(
              'Could not save summary, please do it from sales page!',
              {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
              }
            );
          }

          try {
            await handleCreateTemplate(createdProjectId);
          } catch (error) {
            console.error('Error creating template:', error);
            toast.info(
              'Could not save summary, please do it from sales page!',
              {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
              }
            );
          }

          navigate('/sales');
        } else {
          toast.error('Failed to create Project', {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
        }
      } else {
        const validationErrors: Record<string, string> = {};
        result.error.errors.forEach((err) => {
          if (err.path[0]) {
            validationErrors[err.path[0]] = err.message;
          }
        });
        setErrors(validationErrors);
      }
    } catch (error) {
      console.error('Error uploading images or creating project:', error);
    } finally {
      setIsUploading(false);
    }
  };

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    setState: React.Dispatch<React.SetStateAction<string>>
  ) => {
    setState(event.target.value);
  };

  const handleDateChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    setState: React.Dispatch<React.SetStateAction<string | null>>
  ) => {
    setState(event.target.value);
  };

  const handleCancel = () => {
    console.log('Cancel clicked');
  };

  return (
    <form
      onSubmit={handleSave}
      className="max-w-lg mx-auto bg-[#F8FAFC] p-6 rounded-md"
    >
      <div className="mt-24 flex flex-col items-center">
        <h2 className="text-3xl font-semibold">Launch Your Deal</h2>
        <p className="m-0 font-medium">
          Fill all fields to create your sale in under 60 seconds
        </p>
      </div>

      <SaleDescriptionCard
        saleName={saleName}
        saleTagline={saleTagline}
        saleSummary={saleSummary}
        errors={errors}
        handleInputChange={handleInputChange}
        setSaleName={setSaleName}
        setSaleTagline={setSaleTagline}
        setSaleSummary={setSaleSummary}
      />

      <SaleStrategyCard
        saleOptions={saleOptions}
        selectedOption={selectedOption}
        isRegisteredMembership={isRegisteredMembership}
        errors={errors}
        handleOptionChange={setSelectedOption}
        handleToggle={() => setIsRegisteredMembership(!isRegisteredMembership)}
      />

      <SalePriceCard
        raising={raising}
        pricePerUnit={pricePerUnit}
        maxInvestment={maxInvestment}
        totalRaiseGoal={totalRaiseGoal}
        setRaising={setRaising}
        setPricePerUnit={setPricePerUnit}
        setMaxInvestment={setMaxInvestment}
        setTotalRaiseGoal={setTotalRaiseGoal}
        errors={errors}
      />

      <VisualsUploaderCard
        propertyLogo={propertyLogo}
        mainPropertyImage={mainPropertyImage}
        errors={errors}
        handleDrop={handleDrop}
        handleFileChange={handleFileChange}
        setPropertyLogo={setPropertyLogo}
        setMainPropertyImage={setMainPropertyImage}
      />

      <SaleContractCard
        title="Contract"
        buttonText="Browse..."
        placeholderText={contractFile ? contractFile.name : 'No file uploaded.'}
        handleFileChange={handleContractChange}
        errors={errors}
      />

      <SaleCalendarCard
        startDate={startDate}
        endDate={endDate}
        errors={errors}
        handleDateChange={handleDateChange}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        startDateInputRef={useRef(null)}
        endDateInputRef={useRef(null)}
      />

      <section className="flex mt-6 w-full gap-4">
        <button
          type="button"
          onClick={handleCancel}
          className="btn-text bg-[#F8FAFC] flex-1 text-base font-medium py-4"
        >
          Cancel
        </button>
        <button
          type="submit"
          className={`btn-primary flex-1 text-base font-medium py-4 ${
            isUploading ? 'opacity-50' : ''
          }`}
          disabled={isUploading}
        >
          {isUploading ? 'Saving...' : 'Save'}
        </button>
      </section>
      <ToastContainer />
    </form>
  );
};

export default CreateSaleForm;
