import React, {
  ChangeEvent,
  RefObject,
  useCallback,
  useRef,
  useState,
} from 'react';
import { useActiveAccount } from 'thirdweb/react';
import { z } from 'zod';
import useFantomStarterAdminApi from '../../../hooks/useFantomStarterAdminApi';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import useDocusignApi from '../../../hooks/useDocusign';

import { DesktopLayout } from '../../../components/Layouts';
import { arrowLeft, calendar, sparkles, upload } from '../../../assets';
import { editorStateToFsParagraphs } from '../../../utils/editor';
import TextEditor from '../../../components/TextEditor/TextEditor';

interface SaleOption {
  title: string;
  maxWinners: number;
}

const CreateV2: React.FC = () => {
  const propertyLogoInputRef = useRef<HTMLInputElement>(null);
  const mainPropertyImageInputRef = useRef<HTMLInputElement>(null);
  const startDateInputRef = useRef(null);
  const endDateInputRef = useRef(null);

  const activeAccount = useActiveAccount();
  const companyName =
    localStorage.getItem(`${activeAccount?.address}-companyName`) || null;
  const navigate = useNavigate();
  const fantomStarterAdminApi = useFantomStarterAdminApi();
  const { createProject, saveProjectSummary, updateProjectDetails } =
    fantomStarterAdminApi.admin;
  const { createTemplate } = useDocusignApi();

  const schema = z.object({
    saleName: z.string().min(1, 'Enter sale name'),
    saleTagline: z.string().min(1, 'Enter sale tagline'),
    selectedOption: z.string().nonempty('Select a sale strategy'),
    propertyLogoUrl: z.string().min(1, 'Enter property logo'),
    mainPropertyImageUrl: z.string().min(1, 'Enter main property image'),
    startDate: z.string().min(1, 'Enter start date'),
    endDate: z.string().min(1, 'Enter end date'),
    saleSummary: z.object({
      paragraphs: z
        .array(
          z.object({
            title: z.string().optional(),
            tag: z.string().optional(),
            text: z.string().optional(),
            image: z.string().optional(),
          })
        )
        .min(1, 'Sale summary must contain at least one paragraph'),
    }),
    raising: z.string().min(1, 'Raising amount is required'),
    pricePerUnit: z.string().min(1, 'Price per unit is required'),
    maxInvestment: z.string().min(1, 'Maximum investment is required'),
    totalRaiseGoal: z.string().min(1, 'Total raise goal is required'),
    contractFile: z.custom<File | null>((file) => file instanceof File, {
      message: 'Upload a contract file',
    }),
  });

  const [saleName, setSaleName] = useState<string>('The Retreat Bali');
  const [saleTagline, setSaleTagline] = useState<string>(
    'The Retreat is a game which allows you to trade non-existent items.'
  );
  const [saleSummary, setSaleSummary] = useState({
    terms: [
      { title: 'Investment type', value: '' },
      { title: 'Accepted tiers', value: '' },
      { title: 'Funding cap', value: '' },
      { title: 'Individual cap', value: '' },
      { title: 'Token', value: '' },
      { title: 'Token price', value: '' },
      { title: 'Tokens for sale', value: '' },
      { title: 'Payment accepted', value: '' },
    ],
    paragraphs: [],
  });

  const [propertyLogo, setPropertyLogo] = useState<File | null>(null);
  const [mainPropertyImage, setMainPropertyImage] = useState<File | null>(null);
  const [propertyLogoUrl, setPropertyLogoUrl] = useState<string | null>(null);
  const [mainPropertyImageUrl, setMainPropertyImageUrl] = useState<
    string | null
  >(null);

  const [raising, setRaising] = useState<string>('USDC');
  const [pricePerUnit, setPricePerUnit] = useState<string>('0.5');
  const [maxInvestment, setMaxInvestment] = useState<string>('1');
  const [totalRaiseGoal, setTotalRaiseGoal] = useState<string>('2');

  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const [isRegisteredMembership, setIsRegisteredMembership] = useState(false);

  const [startDate, setStartDate] = useState<string | null>(null);
  const [endDate, setEndDate] = useState<string | null>(null);
  const [selectedChain, setSelectedChain] =
    useState<string>('Polygon (testnet)');

  const [contractFile, setContractFile] = useState<File | null>(null);

  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [errors, setErrors] = useState<Record<string, string>>({});

  const inputFields = [
    // {
    //   label: 'Raising in (axlUSDC)',
    //   value: raising,
    //   placeholder: 'USDC',
    //   id: 'raising',
    //   setter: setRaising,
    // },
    {
      label: 'Price per unit',
      value: pricePerUnit,
      placeholder: '0.5',
      id: 'pricePerUnit',
      setter: setPricePerUnit,
    },
    {
      label: 'Individual investment',
      value: maxInvestment,
      placeholder: '1',
      id: 'maxInvestment',
      setter: setMaxInvestment,
    },
    {
      label: 'Total raise goal',
      value: totalRaiseGoal,
      placeholder: '2',
      id: 'totalRaiseGoal',
      setter: setTotalRaiseGoal,
    },
  ];

  const saleOptions: SaleOption[] = [
    { title: 'Open Sale', maxWinners: 25 },
    { title: 'Private', maxWinners: 50 },
    { title: 'Auction Sale', maxWinners: 100 },
  ];

  const handleDateClick = (inputRef: RefObject<HTMLInputElement>) => {
    if (inputRef.current) {
      inputRef.current.showPicker();
    }
  };

  const handleDateChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    setState: React.Dispatch<React.SetStateAction<string | null>>
  ) => {
    setState(event.target.value);
  };

  const handleChainChange = (chain: string) => setSelectedChain(chain);

  const handleContractChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setContractFile(file);
    }
  };

  const handleAreaClick = (inputRef: React.RefObject<HTMLInputElement>) => {
    inputRef.current?.click();
  };

  const handleDrop = (
    event: React.DragEvent<HTMLDivElement>,
    setImage: React.Dispatch<React.SetStateAction<File | null>>
  ) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file) {
      setImage(file);
    }
  };

  const handleFileChange = (
    event: ChangeEvent<HTMLInputElement>,
    setImage: React.Dispatch<React.SetStateAction<File | null>>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      setImage(file);
    }
  };

  const handleParagraphData = (data: any) => {
    const parsedParagraphs = editorStateToFsParagraphs(data);

    setSaleSummary((prevSummary: any) => ({
      ...prevSummary,
      paragraphs: parsedParagraphs,
    }));
  };

  const uploadImageToCloudinary = async (file: File) => {
    const uploadPreset =
      process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET ?? 'default_upload_preset';
    const cloudName =
      process.env.REACT_APP_CLOUDINARY_CLOUD_NAME ?? 'default_cloud_name';

    const data = new FormData();
    data.append('file', file);
    data.append('upload_preset', uploadPreset);
    data.append('cloud_name', cloudName);

    const response = await fetch(
      `https://api.cloudinary.com/v1_1/${cloudName}/image/upload`,
      {
        method: 'POST',
        body: data,
      }
    );
    const result = await response.json();
    return result.secure_url;
  };

  const handleCreateTemplate = useCallback(
    async (projectId: string) => {
      if (!contractFile) return;
      try {
        toast.info('Creating template...', {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
        const result = await createTemplate(
          `${projectId} template`,
          contractFile
        );
        if (result.templateId) {
          toast.success('Template created successfully', {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
        } else {
          toast.error('Failed to create template', {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
        }
      } catch (error) {
        console.error('Error creating template:', error);
        toast.error('Error creating template. Please try again.', {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [contractFile, createTemplate]
  );

  const handleCancel = () => {
    console.log('Cancel clicked');
  };

  const handleSave = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setIsUploading(true);

    try {
      let uploadedPropertyLogoUrl = propertyLogoUrl;
      let uploadedMainPropertyImageUrl = mainPropertyImageUrl;

      if (propertyLogo) {
        uploadedPropertyLogoUrl = await uploadImageToCloudinary(propertyLogo);
        setPropertyLogoUrl(uploadedPropertyLogoUrl);
      }
      if (mainPropertyImage) {
        uploadedMainPropertyImageUrl =
          await uploadImageToCloudinary(mainPropertyImage);
        setMainPropertyImageUrl(uploadedMainPropertyImageUrl);
      }

      const result = schema.safeParse({
        saleName,
        saleTagline,
        selectedOption: selectedOption || '',
        propertyLogoUrl: uploadedPropertyLogoUrl || '',
        mainPropertyImageUrl: uploadedMainPropertyImageUrl || '',
        startDate: startDate || '',
        endDate: endDate || '',
        saleSummary,
        raising,
        pricePerUnit,
        maxInvestment,
        totalRaiseGoal,
        contractFile,
      });

      if (result.success) {
        setErrors({});

        const apiResult = await createProject({
          name: `${saleName} --rwa`,
          description: saleTagline,
          tags: [],
          chain: '80002', // todo: mainnet testnet chain id depending on the deployment
          logo_image_url: uploadedPropertyLogoUrl || '',
          main_image_url: uploadedMainPropertyImageUrl || '',
          start_date_whitelist: null,
          end_date_whitelist: null,
          start_date_funding: startDate,
          end_date_funding: endDate,
          ido_token_symbol: 'NFT',
          pay_token_symbol: raising,
          cap_total_dollars: totalRaiseGoal,
          cap_individual_dollars: maxInvestment,
          ratio_idotoken_per_paytoken: 1 / Number(pricePerUnit),
          ratio_paytoken_per_idotoken: pricePerUnit,
          sale_version: 3,
          with_whitelist: selectedOption === 'Private',
          with_lottery: selectedOption === 'Auction',
          only_staked: false,
          with_kyc: false,
          header_image_url: uploadedMainPropertyImageUrl,
          company: companyName,
          location: null,
        });

        if (apiResult.status === 200) {
          toast.success('Project Successfully Created!', {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });

          const createdProjectId = apiResult.data.result.data.projectId;

          try {
            const response = await updateProjectDetails({
              id: createdProjectId,
              project_id: createdProjectId,
              name: `${saleName} --rwa`,
              tags: [],
              logo_image_url: uploadedPropertyLogoUrl || '',
              chain: 80002,
              pay_token_symbol: raising,
              cap_total_dollars: totalRaiseGoal,
              cap_individual_dollars: maxInvestment,
              ratio_paytoken_per_idotoken: pricePerUnit,
            });

            if (response.status === 200) {
              toast.success('Updated Project Details!', {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
              });
              localStorage.setItem(`editStepDone-${createdProjectId}`, 'true');
            } else {
              toast.error('Failed to update details!', {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
              });
            }
          } catch (error) {
            console.error('Error updating project details:', error);
            toast.error(
              'Failed to update details, please do it from sales page!',
              {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
              }
            );
          }

          try {
            await saveProjectSummary(createdProjectId, saleSummary);
          } catch (error) {
            console.error('Error saving project summary:', error);
            toast.info(
              'Could not save summary, please do it from sales page!',
              {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
              }
            );
          }

          try {
            await handleCreateTemplate(createdProjectId);
          } catch (error) {
            console.error('Error creating template:', error);
            toast.info(
              'Could not save summary, please do it from sales page!',
              {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
              }
            );
          }

          navigate('/sales');
        } else {
          toast.error('Failed to create Project', {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
        }
      } else {
        const validationErrors: Record<string, string> = {};
        result.error.errors.forEach((err) => {
          if (err.path[0]) {
            validationErrors[err.path[0]] = err.message;
          }
        });
        setErrors(validationErrors);
      }
    } catch (error) {
      console.error('Error uploading images or creating project:', error);
    } finally {
      setIsUploading(false);
    }
  };

  const tabItems = [
    {
      src: 'https://cdn.builder.io/api/v1/image/assets/TEMP/e98aaee6bb8eb975c92dc8798003b5e038590ed7f8d33dd17d2de682a01de03c?placeholderIfAbsent=true&apiKey=466c088da2e142d481f76ae5c06a8ddd',
      alt: 'First tab icon',
    },
    {
      src: 'https://cdn.builder.io/api/v1/image/assets/TEMP/aa4964814c8a3ef1b54e4635e2e0a7e7072c968d923011c44ce6fd9f6f2f3d6e?placeholderIfAbsent=true&apiKey=466c088da2e142d481f76ae5c06a8ddd',
      alt: 'Second tab icon',
    },
  ];

  const mapTabItems = [
    {
      src: 'https://cdn.builder.io/api/v1/image/assets/TEMP/03f8166f128f1cd09bd3e35c9217c2a920c2e407a0d9bb2cb36ede575efb2bef?placeholderIfAbsent=true&apiKey=466c088da2e142d481f76ae5c06a8ddd',
      alt: 'Map tab icon 1',
    },
    {
      src: 'https://cdn.builder.io/api/v1/image/assets/TEMP/4231b5d9551e336ef0a2bd17b7dc5964ed8ac3c23c05fcf68dd3bb658dff84c0?placeholderIfAbsent=true&apiKey=466c088da2e142d481f76ae5c06a8ddd',
      alt: 'Map tab icon 2',
    },
  ];

  return (
    <DesktopLayout>
      <main className="pt-6 sm:px-32 px-5 flex flex-col sm:flex-row gap-5 sm:gap-20 items-start sm:items-center">
        <form className="w-full mt-16" onSubmit={handleSave}>
          <section className="sticky top-20 z-40 flex justify-between gap-4">
            <button
              type="button"
              onClick={handleCancel}
              className="btn-text w-fit text-base items-center font-medium py-2"
            >
              <span>
                <img src={arrowLeft} alt="Back" className="mr-2" />
              </span>
              Back
            </button>
            <button
              type="submit"
              className={`btn-primary w-32 h-10 text-base font-medium py-2 ${
                isUploading ? 'opacity-50' : ''
              }`}
              disabled={isUploading}
            >
              {isUploading ? 'Saving...' : 'Save'}
            </button>
          </section>

          <header className="flex flex-col mt-4 text-black">
            <h1 className="text-3xl font-semibold tracking-tight leading-tight max-md:max-w-full">
              Create a sale
            </h1>
            <p className="mt-1 text-base font-medium tracking-normal max-md:max-w-full">
              Ensure all fields are filled out to create a sale
            </p>
          </header>

          <section className="flex flex-wrap gap- mt-10 items-center p-4 bg-slate-50">
            <img
              loading="lazy"
              src={sparkles}
              alt=""
              className="object-contain shrink-0 self-stretch my-auto aspect-square w-[46px]"
            />
            <div className="flex flex-col flex-1 shrink self-stretch my-auto text-sm leading-none basis-0 min-w-[240px] max-md:max-w-full">
              <h2 className="font-medium text-slate-900">
                {'Import Sale from your website'}
              </h2>
              <p className="mt-1.5 text-slate-500 max-md:max-w-full">
                {`Our AI agent will retrieve the sale's key details to streamline the process.`}
              </p>
            </div>
            <section className="flex flex-wrap gap-2 items-start self-stretch my-auto min-w-[240px] max-md:max-w-full">
              <div className="flex flex-col text-base whitespace-nowrap min-w-[240px] text-slate-900 w-[400px]">
                <label htmlFor="urlInput" className="sr-only">
                  Enter URL
                </label>
                <input
                  id="urlInput"
                  type="url"
                  placeholder={
                    'https://your-website.com/properties/bella-villa'
                  }
                  className="self-stretch py-2 pr-14 pl-3 max-w-full bg-white rounded-md border border-solid border-slate-300 w-[400px] max-md:pr-5"
                />
              </div>
              <button
                type="button"
                className="gap-2.5 self-stretch px-4 py-2 text-sm font-medium leading-6 text-white rounded-md bg-slate-900"
              >
                {'Fill Details with AI'}
              </button>
            </section>
          </section>

          <section className="flex flex-row mt-10 gap-10">
            <section className="w-3/5 flex flex-col gap-10">
              {/* Sale Description */}
              <section className="flex flex-col px-6 py-4 text-sm leading-none rounded-md border border-solid bg-slate-50 border-slate-300 max-md:px-5">
                <header className="flex flex-col w-full font-semibold whitespace-nowrap text-slate-900 max-md:max-w-full">
                  <div className="flex flex-col w-full max-md:max-w-full">
                    <div className="flex gap-2 items-center w-full max-md:max-w-full">
                      <h2 className="flex-1 shrink gap-1 self-stretch my-auto w-full min-w-[240px] max-md:max-w-full">
                        Property details
                      </h2>
                    </div>
                  </div>
                  <div className="mt-4 w-full border border-solid border-slate-200 min-h-[1px] max-md:max-w-full" />

                  <section className="mt-8 flex flex-col">
                    <div className="flex flex-col w-full">
                      <label className="text-sm font-medium text-black">
                        Company logo
                      </label>
                      <div
                        className={`flex flex-col justify-center items-center px-3 py-6 mt-1.5 w-full bg-white rounded-md border ${
                          errors.propertyLogoUrl
                            ? 'border-red-500'
                            : 'border-[#CBD5E1]'
                        } cursor-pointer hover:bg-gray-50 transition-colors duration-200`}
                        onDrop={(e) => handleDrop(e, setPropertyLogo)}
                        onDragOver={(e) => e.preventDefault()}
                        onClick={() => handleAreaClick(propertyLogoInputRef)}
                      >
                        <input
                          ref={propertyLogoInputRef}
                          type="file"
                          accept="image/png, image/jpeg"
                          className="hidden"
                          onChange={(e) => handleFileChange(e, setPropertyLogo)}
                        />
                        <div className="flex flex-col items-center w-60 max-w-full">
                          {propertyLogo ? (
                            <img
                              src={URL.createObjectURL(propertyLogo)}
                              alt="Uploaded property logo preview"
                              className="object-contain w-full aspect-square"
                            />
                          ) : (
                            <img
                              loading="lazy"
                              src={upload}
                              className="object-contain w-6 aspect-square"
                              alt="Default placeholder"
                            />
                          )}
                          <div className="flex gap-1 justify-center items-center w-full text-base font-medium mt-2 whitespace-nowrap">
                            <span>Drag image here or browse</span>
                          </div>
                          <p className="text-xs text-center text-[#334155] mt-1">
                            PNG or JPG (max. 512x512px)
                          </p>
                        </div>
                      </div>
                      {errors.propertyLogoUrl && (
                        <p className="text-red-500 text-xs mt-1">
                          {errors.propertyLogoUrl}
                        </p>
                      )}
                    </div>

                    {propertyLogo && (
                      <div className="flex flex-col justify-center p-4 mt-2 max-w-full bg-white rounded-md border border-solid border-slate-300 w-[512px]">
                        <div className="flex gap-10 justify-between items-center w-full max-md:max-w-full">
                          <span className="self-stretch my-auto text-sm leading-none text-slate-900">
                            {propertyLogo.name}
                          </span>
                          <div className="flex gap-2 items-center self-stretch my-auto">
                            <img
                              loading="lazy"
                              src="https://cdn.builder.io/api/v1/image/assets/TEMP/7d64dc0b5c41b4ad21d3a3a6c1110bcc51c1375331ae19a5fbd77a873923b50d?placeholderIfAbsent=true&apiKey=466c088da2e142d481f76ae5c06a8ddd"
                              alt=""
                              className="object-contain shrink-0 self-stretch my-auto w-6 aspect-square cursor-pointer"
                              onClick={() => {
                                const url = URL.createObjectURL(propertyLogo);
                                const a = document.createElement('a');
                                a.href = url;
                                a.download = propertyLogo.name;
                                a.click();
                              }}
                            />
                            <img
                              loading="lazy"
                              src="https://cdn.builder.io/api/v1/image/assets/TEMP/f24d1a80e0c5cbe95148118ad9d2ef37c61f9f22dd8710978d894d02b51ba1df?placeholderIfAbsent=true&apiKey=466c088da2e142d481f76ae5c06a8ddd"
                              alt=""
                              className="object-contain shrink-0 self-stretch my-auto w-6 aspect-square cursor-pointer"
                              onClick={() => {
                                setPropertyLogo(null);
                                if (propertyLogoInputRef.current) {
                                  propertyLogoInputRef.current.value = '';
                                }
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </section>

                  <div className="mt-4 flex flex-col">
                    <label
                      htmlFor="saleInput"
                      className="text-sm font-medium leading-none text-slate-900"
                    >
                      {'Property name'}
                    </label>
                    <div className="flex gap-2 items-start mt-1.5 w-full text-base text-slate-900 max-md:max-w-full">
                      <div className="flex flex-col flex-1 shrink w-full basis-0 min-w-[240px] max-md:max-w-full">
                        <input
                          id="saleInput"
                          type="text"
                          value={saleName}
                          placeholder="Add a sale name"
                          onChange={(e) => setSaleName(e.target.value)}
                          className="self-stretch py-2 pr-14 pl-3 w-full bg-white rounded-md border border-solid border-slate-300 max-md:pr-5 max-md:max-w-full"
                          aria-label={'Sale name'}
                        />
                      </div>
                    </div>
                  </div>

                  <section className="mt-4 flex flex-col items-start">
                    <div className="flex flex-col w-full mt-4">
                      <label className="text-sm font-medium text-black">
                        Main property image
                      </label>
                      <div
                        className={`flex flex-col justify-center items-center px-3 py-6 mt-1.5 w-full bg-white rounded-md border ${
                          errors.mainPropertyImageUrl
                            ? 'border-red-500'
                            : 'border-[#CBD5E1]'
                        } cursor-pointer hover:bg-gray-50 transition-colors duration-200`}
                        onDrop={(e) => handleDrop(e, setMainPropertyImage)}
                        onDragOver={(e) => e.preventDefault()}
                        onClick={() =>
                          handleAreaClick(mainPropertyImageInputRef)
                        }
                      >
                        <input
                          ref={mainPropertyImageInputRef}
                          type="file"
                          accept="image/png, image/jpeg"
                          className="hidden"
                          onChange={(e) =>
                            handleFileChange(e, setMainPropertyImage)
                          }
                        />
                        <div className="flex flex-col items-center max-w-full">
                          {mainPropertyImage ? (
                            <img
                              src={URL.createObjectURL(mainPropertyImage)}
                              alt="Uploaded main property preview"
                              className="object-contain w-full"
                            />
                          ) : (
                            <img
                              loading="lazy"
                              src={upload}
                              className="object-contain w-6"
                              alt="Default placeholder"
                            />
                          )}
                          <div className="flex gap-1 justify-center items-center w-full text-base font-medium text-slate-900 mt-2 whitespace-nowrap">
                            <span>Drag image here or browse</span>
                          </div>
                          <p className="text-xs text-center text-[#334155] mt-1">
                            PNG or JPG (max. 512x512px)
                          </p>
                        </div>
                      </div>
                      {errors.mainPropertyImageUrl && (
                        <p className="text-red-500 text-xs mt-1">
                          {errors.mainPropertyImageUrl}
                        </p>
                      )}
                    </div>

                    {mainPropertyImage && (
                      <div className="flex flex-col justify-center p-4 mt-2 max-w-full bg-white rounded-md border border-solid border-slate-300 w-[512px]">
                        <div className="flex gap-10 justify-between items-center w-full max-md:max-w-full">
                          <span className="self-stretch my-auto text-sm leading-none text-slate-900">
                            {mainPropertyImage.name}
                          </span>
                          <div className="flex gap-2 items-center self-stretch my-auto">
                            <img
                              loading="lazy"
                              src="https://cdn.builder.io/api/v1/image/assets/TEMP/7d64dc0b5c41b4ad21d3a3a6c1110bcc51c1375331ae19a5fbd77a873923b50d?placeholderIfAbsent=true&apiKey=466c088da2e142d481f76ae5c06a8ddd"
                              alt=""
                              className="object-contain shrink-0 self-stretch my-auto w-6 aspect-square cursor-pointer"
                              onClick={() => {
                                const url =
                                  URL.createObjectURL(mainPropertyImage);
                                const a = document.createElement('a');
                                a.href = url;
                                a.download = mainPropertyImage.name;
                                a.click();
                              }}
                            />
                            <img
                              loading="lazy"
                              src="https://cdn.builder.io/api/v1/image/assets/TEMP/f24d1a80e0c5cbe95148118ad9d2ef37c61f9f22dd8710978d894d02b51ba1df?placeholderIfAbsent=true&apiKey=466c088da2e142d481f76ae5c06a8ddd"
                              alt=""
                              className="object-contain shrink-0 self-stretch my-auto w-6 aspect-square cursor-pointer"
                              onClick={() => {
                                setMainPropertyImage(null);
                                if (mainPropertyImageInputRef.current) {
                                  mainPropertyImageInputRef.current.value = '';
                                }
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </section>

                  <div className="mt-4 first-letter:flex flex-col text-sm text-slate-900">
                    <div className="flex flex-col w-full max-md:max-w-full">
                      <div className="flex flex-col w-full rounded-md max-md:max-w-full">
                        <label
                          htmlFor="saleTagline"
                          className="self-start font-medium leading-none"
                        >
                          Property tagline
                        </label>
                        <textarea
                          id="saleTagline"
                          className="flex-1 shrink gap-2.5 px-3 py-2 pb-14 mt-1.5 leading-none bg-white rounded-md border border-slate-300 min-h-[80px] max-md:max-w-full"
                          value={saleTagline}
                          placeholder="Add a sale tagline"
                          onChange={(e) => setSaleTagline(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-col mt-6 bg-white">
                    <label
                      htmlFor="salesSummary"
                      className="text-sm font-medium leading-none text-black"
                    >
                      Property summary
                    </label>

                    <TextEditor
                      initialData={saleSummary.paragraphs}
                      withDescription={false}
                      getDatas={handleParagraphData}
                    />
                    {errors.saleSummary && (
                      <p className="text-red-500 text-xs mt-1">
                        {errors.saleSummary}
                      </p>
                    )}
                  </div>
                  {/* <p className="mt-2 text=[#AAAAAA] opacity-50 bottom-0 text-sm w-full whitespace-normal">
                    There are 3 types of paragraphs you can add - text paragraph
                    with a title, text paragraph without a title, image
                    paragraph
                  </p> */}
                </header>
              </section>

              {/* Sale Address */}
              <section className="flex flex-col px-6 py-4 rounded-md border border-solid bg-slate-50 border-slate-300 max-md:px-5">
                <header className="flex flex-wrap gap-1 items-center w-full h-9 max-md:max-w-full">
                  <h2 className="flex-1 text-sm font-semibold leading-5 whitespace-nowrap border-b border-slate-200 min-w-[240px] text-slate-900 max-md:max-w-full">
                    Property address
                  </h2>
                  <nav className="flex items-start p-1.5 rounded-md bg-slate-100">
                    {tabItems.map((item, index) => (
                      <div
                        key={index}
                        className={`flex justify-center items-center px-1.5 w-8 h-8 ${index === 0 ? 'bg-white' : ''} rounded`}
                      >
                        <img
                          loading="lazy"
                          src={item.src}
                          alt={item.alt}
                          className="object-contain w-5 aspect-square"
                        />
                      </div>
                    ))}
                  </nav>
                </header>

                <div className="flex flex-col self-end mt-8 max-w-full rounded-none w-[1024px]">
                  <div className="flex relative flex-col p-4 w-full min-h-[376px] max-md:max-w-full">
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/9706985de66b2e08ec8e36fef282cfd183c76d2fdc973e721205f25c6c93af41?placeholderIfAbsent=true&apiKey=466c088da2e142d481f76ae5c06a8ddd"
                      alt="Map background"
                      className="object-cover absolute inset-0 size-full"
                    />
                    <div className="flex relative flex-col px-2 max-w-full text-sm leading-none bg-white rounded-md text-slate-400 w-[472px]">
                      <div className="flex gap-2 items-center px-2 py-2.5 w-full bg-white max-md:max-w-full">
                        <img
                          loading="lazy"
                          src="https://cdn.builder.io/api/v1/image/assets/TEMP/8cf8aa911d59a89f755a031ab95f570ccb3b28ae2bb73a32ab4132816110942b?placeholderIfAbsent=true&apiKey=466c088da2e142d481f76ae5c06a8ddd"
                          alt="Search icon"
                          className="object-contain shrink-0 w-4 aspect-square"
                        />
                        <input
                          type="text"
                          placeholder="Search or pin location on map..."
                          className="flex-1 bg-transparent border-none outline-none opacity-50"
                          aria-label="Search or pin location on map"
                        />
                      </div>
                    </div>

                    <nav className="flex relative flex-col gap-1 self-end p-1.5 mt-56 rounded-md bg-slate-100 w-[42px] max-md:mt-10">
                      {mapTabItems.map((item, index) => (
                        <div
                          key={index}
                          className="flex justify-center items-center px-1.5 w-8 h-8 bg-white rounded"
                        >
                          <img
                            loading="lazy"
                            src={item.src}
                            alt={item.alt}
                            className="object-contain w-5 aspect-square"
                          />
                        </div>
                      ))}
                    </nav>
                  </div>
                </div>
              </section>
            </section>

            <section className="w-2/5 flex flex-col gap-4">
              {/* Sale price card */}
              <section className="bg-slate-50 flex flex-col px-6 py-4 text-sm leading-none rounded-md border border-solid border-slate-300 max-w-[552px] max-md:px-5">
                <section className="flex flex-col w-full font-semibold text-black whitespace-nowrap max-md:max-w-full">
                  <header className="flex flex-col w-full max-md:max-w-full">
                    <h2 className="flex gap-2 items-center w-full max-md:max-w-full">
                      <span className="flex-1 shrink gap-1 self-stretch my-auto w-full min-w-[240px] max-md:max-w-full">
                        Price
                      </span>
                    </h2>
                  </header>
                  <div className="mt-4 w-full border border-solid border-slate-200 min-h-[1px] max-md:max-w-full" />
                </section>

                <section className="flex flex-col mt-8 w-full max-md:max-w-full">
                  {inputFields.map((field) => (
                    <div
                      key={field.id}
                      className="flex flex-col justify-center mt-4 first:mt-0 w-full max-md:max-w-full"
                    >
                      <div className="flex flex-wrap gap-4 items-center w-full max-md:max-w-full">
                        <label
                          htmlFor={field.id}
                          className="flex-1 shrink self-stretch my-auto font-medium text-black basis-0"
                        >
                          {field.label}
                        </label>
                        <div className="flex flex-col self-stretch my-auto whitespace-nowrap min-w-[240px] text-black w-[244px]">
                          <input
                            type={field.id === 'raising' ? 'text' : 'number'}
                            id={field.id}
                            value={field.value}
                            placeholder={field.placeholder}
                            onChange={(e) => field.setter(e.target.value)}
                            className="self-stretch py-2 pr-14 pl-3 w-full bg-white rounded-md border border-solid border-slate-300 max-md:pr-5"
                            aria-label={field.label}
                          />
                          {errors[field.id] && (
                            <span className="text-red-500 text-xs mt-1">
                              {errors[field.id]}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </section>
              </section>

              {/* Sale strategy card */}
              <section className="bg-slate-50 flex flex-col px-6 py-4 mt-6 w-full bg-slate- rounded-md border border-[#CBD5E1]">
                <header className="w-full text-sm font-semibold text-black">
                  <h2 className="text-sm">Strategy</h2>
                  <hr
                    className="mt-4 w-full border-slate-200"
                    aria-hidden="true"
                  />
                </header>
                <div className="flex flex-col mt-8 w-full">
                  <div className="flex gap-4 items-center w-full">
                    {saleOptions.map((option, index) => (
                      <div
                        key={index}
                        className={`flex flex-col flex-1 p-3 bg-white rounded-md border min-h-[128px] ${
                          errors.selectedOption
                            ? 'border-red-500'
                            : 'border-[#CBD5E1]'
                        }`}
                      >
                        <label className="flex flex-col flex-1 justify-between cursor-pointer">
                          <input
                            type="radio"
                            name="saleOption"
                            checked={selectedOption === option.title}
                            onChange={() => setSelectedOption(option.title)}
                            className="sr-only"
                            aria-label={`Select sale strategy: ${option.title}`}
                          />
                          <div className="flex items-start justify-start mb-2">
                            <div
                              className={`w-4 h-4 rounded-full border ${
                                selectedOption === option.title
                                  ? 'bg-[#334155] border-[#334155]'
                                  : 'border-gray-300'
                              }`}
                            ></div>
                          </div>
                          <h3 className="self-center text-base font-medium text-black">
                            {option.title}
                          </h3>
                          <p className="mt-10 text-xs font-light text-center text-[#334155]">
                            Max buyers: {option.maxWinners}
                          </p>
                        </label>
                      </div>
                    ))}
                  </div>
                  {errors.selectedOption && (
                    <p className="text-red-500 text-xs mt-1">
                      {errors.selectedOption}
                    </p>
                  )}
                </div>
                <div className="flex gap-1.5 items-start mt-4 w-full">
                  <label
                    htmlFor="registeredMembership"
                    className="flex-1 text-sm font-medium text-black"
                  >
                    Registered membership?
                  </label>
                  <div className="flex gap-2 items-center">
                    <button
                      id="registeredMembership"
                      onClick={() =>
                        setIsRegisteredMembership(!isRegisteredMembership)
                      }
                      className={`flex justify-center items-start p-0.5 w-11 h-6 rounded-full transition-colors duration-200 ${
                        isRegisteredMembership ? 'bg-[#334155]' : 'bg-slate-200'
                      }`}
                      aria-checked={isRegisteredMembership}
                      role="switch"
                      type="button"
                    >
                      <span
                        className={`w-5 h-5 bg-white rounded-full shadow-sm transform transition-transform duration-200 ${
                          isRegisteredMembership
                            ? 'translate-x-2.5'
                            : '-translate-x-2.5'
                        }`}
                      />
                    </button>
                    <span className="text-sm font-medium text-black w-6">
                      {isRegisteredMembership ? 'Yes' : 'No'}
                    </span>
                  </div>
                </div>
              </section>

              {/* Sale Other details */}
              <section className="flex flex-col px-6 py-4 rounded-md border border-solid bg-slate-50 border-slate-300 max-w-[552px] max-md:px-5">
                <header className="flex flex-col w-full text-sm font-semibold leading-none text-slate-900 max-md:max-w-full">
                  <h2 className="flex gap-2 items-center w-full max-md:max-w-full">
                    <span className="flex-1 shrink gap-1 self-stretch my-auto w-full min-w-[240px] max-md:max-w-full">
                      Sale calendar
                    </span>
                  </h2>
                  <hr className="mt-4 w-full border border-solid border-slate-200 min-h-[1px] max-md:max-w-full" />
                </header>

                <div className="flex flex-col mt-8 w-full max-md:max-w-full">
                  <div className="flex flex-col w-full">
                    <div className="flex gap-4 items-center w-full">
                      <section className="flex flex-col flex-1">
                        <div className="mb-1 text-sm font-medium text-black">
                          Start Date
                        </div>
                        <div
                          className={`flex flex-row-reverse gap-2 items-center justify-start px-2 py-2 bg-white rounded-md border max-w-[206px] flex-1 cursor-pointer hover:bg-gray-50 transition-colors duration-200 ${
                            errors.startDate
                              ? 'border-red-500'
                              : 'border-[#CBD5E1]'
                          }`}
                          onClick={() => handleDateClick(startDateInputRef)}
                        >
                          <img
                            loading="lazy"
                            src={calendar}
                            alt="Start date icon"
                            className="w-6 aspect-square"
                          />
                          <input
                            type="date"
                            value={startDate || ''}
                            onChange={(e) => handleDateChange(e, setStartDate)}
                            className="h-0 w-0 opacity-0 absolute"
                            ref={startDateInputRef}
                          />
                          <div className="flex-1 text-base text-[#334155]">
                            {startDate === '' || startDate === null
                              ? 'No date selected'
                              : startDate}
                          </div>
                        </div>
                      </section>
                      <section className="flex flex-1 flex-col">
                        <div className="mb-1 text-sm font-medium text-black">
                          End Date
                        </div>
                        <div
                          className={`flex flex-row-reverse items-center justify-start py-2 px-2 bg-white rounded-md border max-w-[206px] flex-1 cursor-pointer hover:bg-gray-50 transition-colors duration-200 ${
                            errors.endDate
                              ? 'border-red-500'
                              : 'border-[#CBD5E1]'
                          }`}
                          onClick={() => handleDateClick(endDateInputRef)}
                        >
                          <img
                            loading="lazy"
                            src={calendar}
                            alt="End date icon"
                            className="w-6 aspect-square"
                          />
                          <input
                            type="date"
                            value={endDate || ''}
                            onChange={(e) => handleDateChange(e, setEndDate)}
                            className="h-0 w-0 opacity-0 absolute"
                            ref={endDateInputRef}
                          />
                          <div className="flex-1 text-base text-[#334155]">
                            {endDate === '' || endDate === null
                              ? 'No date selected'
                              : endDate}
                          </div>
                        </div>
                      </section>
                    </div>
                    {errors.startDate && (
                      <p className="text-red-500 text-xs mt-1">
                        {errors.startDate}
                      </p>
                    )}
                    {errors.endDate && (
                      <p className="text-red-500 text-xs mt-1">
                        {errors.endDate}
                      </p>
                    )}
                  </div>

                  {/* <div className="flex flex-col justify-center mt-4 w-full text-sm max-md:max-w-full">
                    <label
                      htmlFor="chain-selector"
                      className="leading-none text-black max-md:max-w-full"
                    >
                      <span className="font-medium text-slate-900">Chain </span>
                      <span className="font-medium text-slate-900">
                        (optional)
                      </span>
                    </label>
                    <button
                      type="button"
                      id="chain-selector"
                      className="flex flex-wrap gap-2 items-center px-4 py-2 mt-1.5 w-full font-medium leading-6 rounded-md border border-solid border-slate-300 text-slate-900 max-md:max-w-full"
                      onClick={() =>
                        handleChainChange(
                          selectedChain === 'Polygon (testnet)'
                            ? 'Other Chain'
                            : 'Polygon (testnet)'
                        )
                      }
                    >
                      <span className="flex-1 shrink self-stretch my-auto basis-0 max-md:max-w-full">
                        {selectedChain}
                      </span>
                      <img
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/efd475aed0739ae64ef85401fb05aaae92b6a14dc3f68f267c6f9b2d7920581b?placeholderIfAbsent=true&apiKey=466c088da2e142d481f76ae5c06a8ddd"
                        alt="Dropdown arrow"
                        className="object-contain w-4"
                      />
                    </button>
                  </div> */}
                </div>
              </section>

              {/* Sale Contract */}
              <section className="flex flex-col mt-6 px-6 py-4 text-sm bg-slate-50 rounded-md border border-solid border-slate-300 max-w-[552px] max-md:px-5">
                <header className="flex flex-col w-full font-semibold leading-none text-black whitespace-nowrap max-md:max-w-full">
                  <h2 className="flex gap-2 items-center w-full max-md:max-w-full">
                    Sale contract
                  </h2>
                  <hr className="mt-4 w-full border border-solid border-slate-200 min-h-[1px] max-md:max-w-full" />
                </header>

                <section className="flex flex-col mt-8 w-full font-medium max-md:max-w-full">
                  {!contractFile && (
                    <div className="flex flex-wrap gap-2 items-center w-full max-md:max-w-full">
                      <label htmlFor="fileInput" className="sr-only">
                        Upload file
                      </label>
                      <button
                        type="button"
                        onClick={() =>
                          document.getElementById('fileInput')?.click()
                        }
                        className="gap-2.5 self-stretch px-4 py-2 my-auto leading-6 whitespace-nowrap rounded-md bg-slate-100 text-slate-900 w-[94px]"
                        tabIndex={0}
                      >
                        Browse...
                      </button>
                      <input
                        type="file"
                        id="fileInput"
                        className="hidden"
                        onChange={handleContractChange}
                        aria-label="File upload input"
                      />
                      <p className="flex-1 shrink self-stretch my-auto leading-none basis-8 text-slate-500">
                        {'No file uploaded.'}
                      </p>
                    </div>
                  )}

                  {contractFile && (
                    <article className="flex flex-col justify-center py-2 pr-2 pl-4 bg-white rounded-md border border-solid border-slate-300">
                      <div className="flex flex-col">
                        <div className="flex justify-between gap-4 items-center">
                          <button className="gap-2.5 whitespace-nowrap truncate py-2 my-auto text-sm font-medium leading-6 rounded-md bg-white bg-opacity-0 text-slate-900">
                            {contractFile.name}
                          </button>
                          <div className="flex flex-col justify-center my-auto w-8">
                            <button className="flex gap-2 justify-center items-center p-2 w-8 h-8 bg-white rounded-md border-solid border-[length:var(--sds-size-stroke-border)] border-slate-100">
                              <img
                                loading="lazy"
                                src="https://cdn.builder.io/api/v1/image/assets/TEMP/fd41c5fed5e3fdbf39e675c73a95a9cf3c1e0c4bbce5335f3a603288ab907dab?placeholderIfAbsent=true&apiKey=466c088da2e142d481f76ae5c06a8ddd"
                                alt=""
                                className="object-contain my-auto w-4 aspect-square"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                    </article>
                  )}

                  {errors.contractFile && (
                    <span className="text-red-500 mt-2">
                      {errors.contractFile}
                    </span>
                  )}
                </section>
              </section>
            </section>
          </section>
        </form>
      </main>
    </DesktopLayout>
  );
};

export default CreateV2;
