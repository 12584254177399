import React from 'react';

import { useActiveWallet } from 'thirdweb/react';
import { Login } from '../views/Auth';
import useToken from '../hooks/useToken';
import useSessionTimeout from '../hooks/useSessionTimeout';

function withValidLogin(WrappedComponent: React.FC) {
  return function WithValidLoginComponent({ ...props }) {
    const { token } = useToken();
    const activeWallet = useActiveWallet();
    useSessionTimeout(activeWallet);

    if (!token) {
      return (
        <>
          <Login />
        </>
      );
    }

    return <WrappedComponent {...props} />;
  };
}

export default withValidLogin;
