import React, { useState, useEffect, useCallback } from 'react';
import { createPublicClient, formatUnits, http, parseUnits } from 'viem';
import { useActiveAccount, useSendTransaction } from 'thirdweb/react';
import { polygonAmoy } from 'thirdweb/chains';

import { polygonAmoy as viemPolygonAmoy } from '../../config/wagmiConfig';
import { cross, checked } from '../../assets';
import { erc20ABI } from 'wagmi';
import { toast } from 'react-toastify';
import { getContract, prepareContractCall, waitForReceipt } from 'thirdweb';
import { client } from '../../config/thirdwebConfig';

interface WithdrawComponentProps {
  projects: any;
  onCancel: () => void;
}

const WalletWithdraw: React.FC<WithdrawComponentProps> = ({
  projects,
  onCancel,
}) => {
  const circleUsdcAddress = '0x41E94Eb019C0762f9Bfcf9Fb1E58725BfB0e7582';
  const activeAccount = useActiveAccount();
  const { mutateAsync: sendTx } = useSendTransaction();

  const [projectsWithoutWithdraws, setProjectsWithoutWithdraws] = useState<
    any[]
  >([]);
  const [projectWithoutWithdrawsMap, setProjectWithoutWithdrawsMap] =
    useState<any>({});
  const [amount, setAmount] = useState<number>(0);
  const [availableAmount, setAvailableAmount] = useState<number>(0);
  const [selectedProjectId, setSelectedProjectId] = useState<string>('');
  const [isExecuting, setIsExecuting] = useState<boolean>(false);

  const [isClaimingStepDone, setIsClaimingStepDone] = useState<boolean>(false);

  const [refecthActionsLocalStorageData, setRefetchActionsLocalStorageData] =
    useState<string>('');

  const handleSaleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedProjectId(e.target.value);
  };

  const handleWalletWithdrawal = useCallback(async () => {
    if (!isClaimingStepDone) {
      toast.error('Claiming step not done!', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
      return;
    }

    const selectedProject = projectWithoutWithdrawsMap[selectedProjectId];
    if (!selectedProject) {
      toast.error('Project not selected!', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
      return;
    }
    const saleAddress = selectedProject.sale_contract_address;
    if (!saleAddress) {
      toast.error('Sale address not found!', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
      return;
    }
    const address = activeAccount?.address;
    if (!address) {
      toast.error('Address not found!', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
      return;
    }

    setIsExecuting(true);
    try {
      const parsedRetrievableBalance = parseUnits(amount.toString(), 6);
      const saleContract = getContract({
        address: saleAddress,
        chain: polygonAmoy,
        client,
      });
      const withdrawPayTokensTransaction = prepareContractCall({
        contract: saleContract,
        method:
          'function withdrawPayTokens(address _owner, uint256 _payTokensToWithdraw)',
        params: [address, parsedRetrievableBalance],
      });
      const withdrawPayTokensTx = await sendTx(withdrawPayTokensTransaction);
      await waitForReceipt({
        client,
        chain: polygonAmoy,
        transactionHash: withdrawPayTokensTx.transactionHash,
      });

      toast.success('Tokens retrieved successfully!', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
      localStorage.setItem(
        `withdrawPayTokensStepDone-${selectedProjectId}`,
        'true'
      );
      setRefetchActionsLocalStorageData(
        `refetchActionsLocalStorageData - ${Date.now().toString()}`
      );
    } catch (error) {
      console.error('Error retrieving contract balance:', error);
      toast.error('Error retrieving contract balance. Please try again.', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
    setIsExecuting(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amount, client, sendTx, isClaimingStepDone]);

  useEffect(() => {
    const enableClaimingStepDone = localStorage.getItem(
      `enableClaimingStepDone-${selectedProjectId}`
    );
    if (enableClaimingStepDone) {
      setIsClaimingStepDone(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refecthActionsLocalStorageData, selectedProjectId]);

  useEffect(() => {
    const filteredProjects = projects.filter(
      (project: any) =>
        new Date(project.end_date_funding).getTime() < Date.now()
    );
    setProjectsWithoutWithdraws(filteredProjects);
    const projectMap = filteredProjects.reduce((acc: any, project: any) => {
      acc[project.id] = project;
      return acc;
    }, {});
    setProjectWithoutWithdrawsMap(projectMap);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projects]);

  useEffect(() => {
    const fetchContractBalance = async (saleAddress: string) => {
      try {
        const publicClient = createPublicClient({
          chain: viemPolygonAmoy,
          transport: http(),
        });
        const balance = await publicClient.readContract({
          address: circleUsdcAddress as `0x${string}`,
          abi: erc20ABI,
          functionName: 'balanceOf',
          args: [saleAddress as `0x${string}`],
        });
        const formattedBalance = Number(formatUnits(balance, 6));
        setAmount(formattedBalance);
        setAvailableAmount(formattedBalance);
      } catch (err) {
        console.log(
          'Something went wrong while retrieving user balance: ',
          err
        );
      }
    };

    const selectedProject = projectWithoutWithdrawsMap[selectedProjectId];
    if (selectedProject) {
      const saleAddress = selectedProject.sale_contract_address;
      if (saleAddress) {
        fetchContractBalance(saleAddress);
      }
    }
  }, [selectedProjectId, projectWithoutWithdrawsMap]);

  return (
    <main className="flex flex-col justify-center p-6 bg-white rounded-lg border border-slate-300 max-w-[425px] z-50">
      <div className="flex flex-col w-full max-w-[377px]">
        <header className="flex justify-between items-center w-full text-lg font-semibold text-slate-900">
          <h1>Withdraw from Smart Contract</h1>
          <img
            loading="lazy"
            src={cross}
            alt="Close"
            className="w-6 h-6 cursor-pointer"
            onClick={onCancel}
          />
        </header>
        <p className="mt-2 text-sm text-slate-500">
          Please ensure that all details are correct before proceeding, as this
          action cannot be undone.
        </p>

        <section className="flex flex-col mt-8 text-center">
          <h2 className="text-sm text-slate-900 font-medium">
            Available amount
          </h2>
          <p className="mt-1 text-3xl font-bold text-slate-900">
            $
            {availableAmount.toLocaleString('en-US', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </p>
          <p className="mt-1 text-base text-slate-600">
            {availableAmount.toLocaleString('en-US', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}{' '}
            USDC
          </p>
        </section>

        <form
          className="flex flex-col mt-8 text-sm"
          onSubmit={(e) => {
            e.preventDefault();
            handleWalletWithdrawal();
          }}
        >
          <div className="">
            <label htmlFor="sale" className="font-medium text-black">
              Sale
            </label>
            <div className="mt-2 w-full bg-white px-3 py-2 border border-slate-300 rounded-md text-slate-900 cursor-pointer">
              <select
                id="sale"
                value={selectedProjectId}
                onChange={handleSaleChange}
                className="w-full bg-white cursor-pointer"
              >
                <option value="" disabled>
                  Select a sale
                </option>
                {projectsWithoutWithdraws.map((project: any) => (
                  <option key={project.id} value={project.id}>
                    {project.id}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <label
            htmlFor="withdrawAmount"
            className="font-medium text-black mt-6"
          >
            Withdraw Amount
          </label>
          <div className="mt-2 py-2 px-3 flex items-center border border-slate-300 rounded-md">
            <span className=" text-slate-900">$</span>
            <span className="w-[1.5px] h-5 bg-black mx-1"></span>
            <input
              type="number"
              id="withdrawAmount"
              value={amount}
              className="flex-1 text-slate-400 bg-transparent border-none outline-none"
              placeholder="0"
              aria-label="Withdraw Amount"
              onChange={(e) => {
                const value = e.target.value;
                if (Number(value) <= availableAmount) {
                  setAmount(Number(value));
                } else {
                  setAmount(availableAmount);
                }
              }}
            />
          </div>
          <div className="flex items-center mt-2">
            <img
              src={checked}
              alt={'Checked'}
              className="w-4 h-4 cursor-pointer"
            />
            <label htmlFor="withdrawAll" className="ml-2 text-black">
              Withdraw all available amount
            </label>
          </div>
          <div className="mt-6">
            <label htmlFor="withdrawAccount" className="font-medium text-black">
              Withdrawn to Main Account
            </label>
            <div className="mt-2 w-full bg-white px-3 py-2 border border-slate-300 rounded-md text-slate-900 cursor-pointer">
              <select
                id="withdrawAccount"
                className="w-full bg-white cursor-pointer"
              >
                <option value="">{activeAccount?.address || ''}</option>
              </select>
            </div>
          </div>
        </form>

        <div className="flex gap-2.5 mt-8">
          <button
            type="button"
            onClick={onCancel}
            className="flex-1 py-2 border border-slate-300 rounded-md text-slate-900"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="flex-1 py-2 bg-slate-900 text-white rounded-md"
            onClick={handleWalletWithdrawal}
          >
            {isExecuting ? 'Withdrawing...' : 'Withdraw'}
          </button>
        </div>
      </div>
    </main>
  );
};

export default WalletWithdraw;
