import { randomBytes } from '@ethersproject/random';

export function generateRandomHex(size: number = 32) {
  const bytes = randomBytes(32); // Generates 32 random bytes
  const hexString = bytes.reduce(
    (str, byte) => str + byte.toString(16).padStart(2, '0'),
    ''
  );
  return hexString;
}

export function formatContractsDate(
  isoDate: string,
  justData: boolean = false
): string {
  const date = new Date(isoDate);

  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();

  if (justData) {
    return `${day}/${month}/${year}`;
  }

  let hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const ampm = hours >= 12 ? 'PM' : 'AM';

  hours = hours % 12;
  hours = hours ? hours : 12;

  const formattedTime = `${hours}:${minutes} ${ampm}`;

  return `${day}/${month}/${year} ${formattedTime}`;
}

export const obfuscateEmail = (email: string) => {
  const [localPart, domain] = email.split('@');
  const obfuscatedLocal = localPart.slice(0, 3) + '**';
  return `${obfuscatedLocal}@${domain}`;
};

export const shortenAddress = (address: string) => {
  return address.slice(0, 6) + '...' + address.slice(-4);
};
