import React, { useMemo } from 'react';
import {
  InitialConfigType,
  LexicalComposer,
} from '@lexical/react/LexicalComposer';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { HeadingNode } from '@lexical/rich-text';
import { LinkNode } from '@lexical/link';
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin';
import { AutoLinkNode } from '@lexical/link';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import CustomHistoryActions from '../EditorPlugIns/CustomHistoryActions';
import OnChangePlugin from '../EditorPlugIns/OnChangePlugin/OnChangePlugin';
import CustomHeadingActions from '../EditorPlugIns/CustomHeadingActions/CustomHeadingActions';
import CustomHeadingPlugin from '../EditorPlugIns/CustomHeadingPlugin/CustomHeadingPlugin';
import './TextEditor.css';
import ToolbarPlugin from '../EditorPlugIns/CustomImagesPlugIn/ImageToolbar';
import ImagesPlugin from '../EditorPlugIns/CustomImagesPlugIn/ImagesPlugin';
import { ImageNode } from '../EditorPlugIns/nodes/ImageNode';
import { fsParagraphsToEditorState } from '../../utils/editor';

interface TextEditorProps {
  initialData: any;
  withDescription?: boolean;
  getDatas: (data: object) => void;
}

const TextEditor: React.FC<TextEditorProps> = ({
  initialData,
  withDescription = true,
  getDatas,
}) => {
  const handleData = (data: any) => {
    getDatas(data);
  };

  const initialText =
    initialData.length === 0
      ? undefined
      : `${JSON.stringify(fsParagraphsToEditorState(initialData))}`;

  const lexicalConfig: InitialConfigType = {
    namespace: 'My Rich Text Editor',
    nodes: [HeadingNode, LinkNode, AutoLinkNode, ImageNode],
    theme: {
      text: {
        bold: 'text-bold',
        italic: 'text-italic',
        underline: 'text-underline',
        code: 'text-code',
        highlight: 'text-highlight',
        strikethrough: 'text-strikethrough',
        subscript: 'text-subscript',
        superscript: 'text-superscript',
      },
      heading: {
        h1: 'text-5xl font-extrabold text-black',
        h2: 'text-4xl font-bold text-black',
        h3: 'text-3xl font-bold text-black',
        h4: 'text-2xl font-bold text-black',
        h5: 'text-xl font-bold text-black',
      },
    },
    editorState: initialText,
    onError: (e) => {
      console.log('ERROR:', e);
    },
  };

  const CustomPlaceholder = useMemo(() => {
    return (
      <>
        <p className="absolute text=[#AAAAAA] opacity-50 top-[48px] left-1 z-50 p-5 text-sm pointer-events-none">
          Add a sale summary
        </p>
        {withDescription && (
          <p className="absolute text=[#AAAAAA] opacity-50 bottom-0 z-50 p-5 text-sm w-full whitespace-normal">
            There are 3 types of paragraphs you can add - text paragraph with a
            title, text paragraph without a title, image paragraph
          </p>
        )}
      </>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [withDescription]);

  return (
    <div className="relative mt-1.5 rounded-md border border-[#CBD5E1]">
      <LexicalComposer initialConfig={lexicalConfig}>
        <div className="sticky rounded-md z-10 w-full flex items-center gap-2 bg-transparent p-[10px]">
          <CustomHistoryActions />
          <CustomHeadingActions />
          <ToolbarPlugin />
        </div>
        <hr className="mb-4 border border-solid border-slate-200 mx-3" />
        <RichTextPlugin
          contentEditable={
            <ContentEditable className="w-full h-[352px] flex-1 px-5 bg-transparent outline-none overflow-y-scroll" />
          }
          placeholder={CustomPlaceholder}
          ErrorBoundary={LexicalErrorBoundary}
        />
        <HistoryPlugin />
        <CustomHeadingPlugin />
        <ImagesPlugin />
        <LinkPlugin />
        <OnChangePlugin getData={handleData} />
      </LexicalComposer>
    </div>
  );
};

export default TextEditor;
