import React from 'react';
import { WithdrawDetailsPopover } from '../../components/Popover';

interface IWithdrawTableData {
  transaction: string;
  transactionId: string;
  transactionType: string;
  transactionDate: string;
  fee: string;
  amount: string;
  status: string;
}

interface WithdrawTableProps {
  withdrawHeaders: string[];
  withdrawTableData: IWithdrawTableData[];
  onTransactionClick: (transactionId: string) => void;
  popoverVisible: boolean;
  popoverTransactionId: string | null;
  closePopover: () => void;
}

const WithdrawTable: React.FC<WithdrawTableProps> = ({
  withdrawHeaders,
  withdrawTableData,
  onTransactionClick,
  popoverVisible,
  popoverTransactionId,
  closePopover,
}) => {
  return (
    <section className="w-full overflow-x-auto">
      <div className="grid grid-cols-7 gap-0 bg-slate-50 w-full text-sm font-semibold text-slate-900 whitespace-nowrap">
        {withdrawHeaders.map((header, index) => (
          <div
            key={index}
            className="p-4 cursor-pointer flex justify-between items-center bg-slate-50"
          >
            {header}
          </div>
        ))}
      </div>

      <section className="w-full">
        {withdrawTableData.map((row, index) => (
          <section key={index}>
            <section className="grid grid-cols-7 gap-0 bg-white w-full text-sm whitespace-nowrap border-b">
              <div className="flex items-center mx-4 py-4 overflow-hidden text-ellipsis">
                {row.transaction}
              </div>
              <div
                className="flex items-center mx-4 py-4 overflow-hidden text-ellipsis underline cursor-pointer"
                onClick={() => onTransactionClick(row.transactionId)}
              >
                {row.transactionId}
              </div>
              <div className="flex items-center mx-4 py-4 overflow-hidden text-ellipsis">
                {row.transactionType}
              </div>
              <div className="flex items-center mx-4 py-4 overflow-hidden text-ellipsis">
                {row.transactionDate}
              </div>
              <div className="flex items-center mx-4 py-4 overflow-hidden text-ellipsis">
                {row.fee}
              </div>
              <div className="flex items-center mx-4 py-4 overflow-hidden text-ellipsis">
                {row.amount}
              </div>
              <div className="flex items-center mx-4 py-4 overflow-hidden text-ellipsis">
                {row.status}
              </div>
            </section>

            {popoverVisible && popoverTransactionId === row.transactionId && (
              <WithdrawDetailsPopover
                transactionId={row.transactionId}
                asset="USD"
                amount={row.amount}
                fee={row.fee}
                total={`$ ${parseFloat(row.amount.replace('$', '')) + parseFloat(row.fee.replace('$', ''))}`}
                date={row.transactionDate}
                onClose={closePopover}
              />
            )}
          </section>
        ))}
      </section>
    </section>
  );
};

export default WithdrawTable;
