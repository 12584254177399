import { useState, useEffect, useMemo } from 'react';
import debounce from 'lodash.debounce';

import { DesktopLayout } from '../../components/Layouts';
import { FundsOverview } from '../../components/FundsOverview';
import { Pagination } from '../../components/Pagination';
import { Tabs } from '../../components/Tabs';
import { SearchActions } from '../../components/SearchActions';
import { SalesTable, WithdrawTable } from '../../components/Table';
import useAllProjects from '../../hooks/useAllProjects';
import useFantomStarterAdminApi from '../../hooks/useFantomStarterAdminApi';
import useToken from '../../hooks/useToken';
import { formatWithdrawDate } from '../../utils/conversion';

export interface ISaleDetailItem {
  label: string;
  value: string;
}

export interface ISaleTableData {
  customer: string;
  saleId: string;
  saleType: string;
  status: string;
  saleDate: string;
  saleFees: string;
  saleAmount: string;
  detailItems: ISaleDetailItem[];
}

export interface IWithdrawTableData {
  transaction: string;
  transactionId: string;
  transactionType: string;
  transactionDate: string;
  fee: string;
  amount: string;
  status: string;
}

export default function Financials() {
  const { projects } = useAllProjects();
  const {
    circle: { getWithdrawsForSeller },
  } = useFantomStarterAdminApi();
  const { token } = useToken();

  const [filteredProjects, setFilteredProjects] = useState<any>([]);
  const [filteredWithdraws, setFilteredWithdraws] = useState<any>([]);

  const [saleTableData, setSaleTableData] = useState<ISaleTableData[]>([]);
  const [withdrawTableData, setWithdrawTableData] = useState<
    IWithdrawTableData[]
  >([]);
  const [toogleDetail, setToogleDetail] = useState<boolean>(false);
  const [toggleDetailIndex, setToggleDetailIndex] = useState<number>(-1);
  const [tabs, setTabs] = useState([
    { label: 'Sales History', isActive: true },
    { label: 'Withdraw History', isActive: false },
  ]);
  const [searchQuery, setSearchQuery] = useState<string>('');

  const [popoverVisible, setPopoverVisible] = useState<boolean>(false);
  const [popoverTransactionId, setPopoverTransactionId] = useState<
    string | null
  >(null);

  const salesHeaders = [
    'Customer',
    'Sale ID',
    'Sale Type',
    'Status',
    'Sale Date',
    'Sale Fees',
    'Sale Amount',
  ];
  const withdrawHeaders = [
    'Transaction',
    'Transaction ID',
    'Transaction Type',
    'Transaction Date',
    'Fee',
    'Amount',
    'Status',
  ];

  const handleTabClick = (index: number) => {
    const updatedTabs = tabs.map((tab, i) => ({
      ...tab,
      isActive: i === index,
    }));
    setTabs(updatedTabs);
    setPopoverVisible(false);
    setPopoverTransactionId(null);
  };

  const handleDetailClick = (index: number) => {
    setToogleDetail(!toogleDetail || toggleDetailIndex !== index);
    setToggleDetailIndex(index);
  };

  const handleTransactionClick = (transactionId: string) => {
    setPopoverVisible(!popoverVisible);
    setPopoverTransactionId(popoverVisible ? null : transactionId);
  };

  const closePopover = () => {
    setPopoverVisible(false);
    setPopoverTransactionId(null);
  };

  const debouncedSearch = useMemo(
    () => debounce((query: string) => setSearchQuery(query), 300),
    []
  );

  useEffect(() => {
    let filtered = projects;

    if (searchQuery && tabs[0].isActive) {
      const lowercasedQuery = searchQuery.toLowerCase();
      filtered = filtered.filter(
        (project) =>
          project.name.toLowerCase().includes(lowercasedQuery) ||
          project.id.toLowerCase().includes(lowercasedQuery)
      );
    }

    setFilteredProjects(filtered);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery, projects, tabs]);

  useEffect(() => {
    let filtered = withdrawTableData;

    if (searchQuery && tabs[1].isActive) {
      const lowercasedQuery = searchQuery.toLowerCase();
      filtered = filtered.filter(
        (withdraw) =>
          withdraw.transaction.toLowerCase().includes(lowercasedQuery) ||
          withdraw.transactionId.toLowerCase().includes(lowercasedQuery) ||
          withdraw.status.toLowerCase().includes(lowercasedQuery)
      );
    }

    setFilteredWithdraws(filtered);
  }, [searchQuery, withdrawTableData, tabs]);

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  useEffect(() => {
    if (filteredProjects) {
      const mappedSalesData = filteredProjects.map((project: any) => ({
        customer: 'Unknown',
        saleId: project.project_id,
        saleType: project.with_lottery
          ? 'Auction'
          : project.with_whitelist
            ? 'Registered'
            : 'Open',
        status: project.total_raised > 0 ? 'Sent' : 'Pending',
        saleDate: new Date(project.start_date_funding).toLocaleDateString(),
        saleFees: `$${parseFloat(project.cap_individual_dollars).toFixed(2)}`,
        saleAmount: `$${parseFloat(project.cap_individual_dollars).toFixed(2)}`,
        detailItems: [
          { label: 'Sale Name', value: project.name },
          {
            label: 'Fee Name',
            value: `$${parseFloat(project.cap_individual_dollars).toFixed(2)}`,
          },
          { label: 'Withdraw Date', value: 'N/A' },
        ],
      }));
      setSaleTableData(mappedSalesData);
    }
  }, [filteredProjects]);

  useEffect(() => {
    (async () => {
      if (token) {
        const result = await getWithdrawsForSeller();
        const sellerWithdraws = result.data.data;

        const mappedWithdrawData = sellerWithdraws.map((withdraw: any) => ({
          transaction: `Withdraw ${withdraw.amount.currency}`,
          transactionId: withdraw.id.slice(-5),
          transactionType: 'Bank Account',
          transactionDate: formatWithdrawDate(withdraw.createDate),
          fee: `$${withdraw.fees?.amount ? withdraw.fees.amount : '0'}`,
          amount: `$${withdraw.amount.amount}`,
          status: withdraw.status,
        }));

        setWithdrawTableData(mappedWithdrawData);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return (
    <DesktopLayout>
      <main className="pt-24 sm:px-32 px-5 flex flex-col items-start">
        <FundsOverview />

        <Tabs tabs={tabs} onTabClick={handleTabClick} />

        <SearchActions
          onSearchChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            debouncedSearch(event.target.value);
          }}
          onFilterClick={() => {}}
          onExportClick={() => {}}
          onStartSaleClick={() => {}}
        />

        {tabs[0].isActive ? (
          <SalesTable
            salesHeaders={salesHeaders}
            saleTableData={saleTableData}
            onDetailClick={handleDetailClick}
            toggleDetailIndex={toggleDetailIndex}
            toogleDetail={toogleDetail}
          />
        ) : (
          <WithdrawTable
            withdrawHeaders={withdrawHeaders}
            withdrawTableData={filteredWithdraws}
            onTransactionClick={handleTransactionClick}
            popoverVisible={popoverVisible}
            popoverTransactionId={popoverTransactionId}
            closePopover={closePopover}
          />
        )}

        <Pagination />
      </main>
    </DesktopLayout>
  );
}
