import React, { useState, useEffect, useCallback } from 'react';
import { createPublicClient, formatUnits, http, parseUnits } from 'viem';
import { useActiveAccount, useSendTransaction } from 'thirdweb/react';
import { v4 as uuidv4 } from 'uuid';
import { polygonAmoy } from 'thirdweb/chains';

import { polygonAmoy as viemPolygonAmoy } from '../../config/wagmiConfig';
import { cross, checked, unchecked } from '../../assets';
import { erc20ABI } from 'wagmi';
import useFantomStarterAdminApi from '../../hooks/useFantomStarterAdminApi';
import { toast } from 'react-toastify';
import { getContract, prepareContractCall, waitForReceipt } from 'thirdweb';
import { client } from '../../config/thirdwebConfig';
import type { ICreateTransferParams } from '../../hooks/useFantomStarterAdminApi';

interface WithdrawComponentProps {
  projects: any;
  onCancel: () => void;
}

interface RegisteredBankAccount {
  id: string;
  accountNumber: string;
  name: string;
}

const WithdrawComponent: React.FC<WithdrawComponentProps> = ({
  projects,
  onCancel,
}) => {
  const circleUsdcAddress = '0x41E94Eb019C0762f9Bfcf9Fb1E58725BfB0e7582';
  const circleDepositAddress = '0x5e28b28c1e57b88ef92a9b8ab90e622010a7ba4d';
  const {
    circle: {
      createProjectFundDeposit,
      initiateWithdrawToBank,
      getDepositForProjectId,
    },
    bridge: { createTransfer },
  } = useFantomStarterAdminApi();
  const activeAccount = useActiveAccount();
  const { mutateAsync: sendTx } = useSendTransaction();

  const [projectsWithoutWithdraws, setProjectsWithoutWithdraws] = useState<
    any[]
  >([]);
  const [amount, setAmount] = useState<number>(0);
  const [availableAmount, setAvailableAmount] = useState<number>(0);
  const [selectedProjectId, setSelectedProjectId] = useState<string>('');
  const [isExecuting, setIsExecuting] = useState<boolean>(false);
  const [selectedBankId, setSelectedBankId] = useState<string>('');

  const customerId = localStorage.getItem(
    `${activeAccount?.address}-customerId`
  );
  const registeredBanks: RegisteredBankAccount[] = JSON.parse(
    localStorage.getItem(`${activeAccount?.address}-banks`) || '[]'
  );

  const handleSaleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedProjectId(e.target.value);
  };

  const handleBankChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    console.log(e.target.value);
    setSelectedBankId(e.target.value);
  };

  const handleBankWithdrawal = useCallback(async () => {
    const address = activeAccount?.address;
    if (!address) {
      toast.error('Address not found!', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
      return;
    }
    if (!amount) {
      toast.error('No amount found!', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
      return;
    }
    if (!selectedProjectId) {
      toast.error('No project found!', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
      return;
    }

    setIsExecuting(true);
    const idempotencyKey = uuidv4();
    try {
      const depositResult = await getDepositForProjectId(selectedProjectId);
      if (
        depositResult.status === 400 ||
        depositResult.status === 401 ||
        depositResult.status === 500
      ) {
        toast.error('Error fetching deposit!', {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
        setIsExecuting(false);
        return;
      }
      const hasDeposit =
        depositResult.data && depositResult.data.tx_hash ? true : false;
      if (hasDeposit && depositResult.data.withdrawn_to_bank === false) {
        toast.info('Initiating withdrawal to bank...', {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 5000,
        });
        const initiateResult = await initiateWithdrawToBank(
          selectedProjectId,
          idempotencyKey
        );
        if (
          initiateResult.status === 400 ||
          initiateResult.status === 401 ||
          initiateResult.status === 500
        ) {
          toast.error('Error initiating withdrawal!', {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
          setIsExecuting(false);
          return;
        }
        toast.success('Withdrawal initiated successfully!', {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
        localStorage.setItem(
          `withdrawToBankStepDone-${selectedProjectId}`,
          'true'
        );
      } else {
        const localDepositHash = localStorage.getItem(
          `${address}-${selectedProjectId}-circle-deposit-hash`
        );
        if (localDepositHash) {
          const depositResult = await createProjectFundDeposit(
            selectedProjectId,
            localDepositHash
          );
          if (
            depositResult.status === 400 ||
            depositResult.status === 401 ||
            depositResult.status === 500
          ) {
            toast.error('Error creating deposit!', {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
            });
            setIsExecuting(false);
            return;
          }
          toast.info('Initiating withdrawal to bank...', {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });
          const intiateResult = await initiateWithdrawToBank(
            selectedProjectId,
            idempotencyKey
          );
          if (intiateResult.status !== 200 || intiateResult.data === !201) {
            toast.error('Error initiating withdrawal!', {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
            });
            setIsExecuting(false);
            return;
          }
          toast.success('Withdrawal initiated successfully!', {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
          localStorage.setItem(
            `withdrawToBankStepDone-${selectedProjectId}`,
            'true'
          );
        } else {
          toast.info('Depositing user balance to Circle deposit address...', {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });
          const payTokenContract = getContract({
            address: circleUsdcAddress as `0x${string}`,
            chain: polygonAmoy,
            client,
          });
          const depositTransaction = prepareContractCall({
            contract: payTokenContract,
            method: 'function transfer(address to, uint256 amount)',
            params: [circleDepositAddress, parseUnits(amount.toString(), 6)],
          });
          const depositTx = await sendTx(depositTransaction);
          await waitForReceipt({
            client,
            chain: polygonAmoy,
            transactionHash: depositTx.transactionHash,
          });
          localStorage.setItem(
            `${address}-${selectedProjectId}-circle-deposit-hash`,
            depositTx.transactionHash
          );

          const depositResult = await createProjectFundDeposit(
            selectedProjectId,
            depositTx.transactionHash
          );
          if (
            depositResult.status === 400 ||
            depositResult.status === 401 ||
            depositResult.status === 500
          ) {
            toast.error('Error creating deposit!', {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
            });
            setIsExecuting(false);
            return;
          }

          toast.info('Initiating withdrawal to bank...', {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });
          const initiateResult = await initiateWithdrawToBank(
            selectedProjectId,
            idempotencyKey
          );
          if (
            initiateResult.status === 400 ||
            initiateResult.status === 401 ||
            initiateResult.status === 500
          ) {
            toast.error('Error initiating withdrawal!', {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
            });
            setIsExecuting(false);
            return;
          }
          toast.success('Withdrawal initiated successfully!', {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
          localStorage.setItem(
            `withdrawToBankStepDone-${selectedProjectId}`,
            'true'
          );
        }
      }
    } catch (error) {
      console.error('Error withdrawing to bank:', error);
      toast.error('Error withdrawing to bank. Please try again.', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
    setIsExecuting(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProjectId, activeAccount, amount, sendTx]);

  const handleBankWithdrawalWithBridge = useCallback(async () => {
    const address = activeAccount?.address;
    if (!address) {
      toast.error('Address not found!', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
      return;
    }
    if (!amount) {
      toast.error('No amount found!', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
      return;
    }
    if (selectedBankId === '') {
      toast.error('No bank account selected!', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
      return;
    }
    if (!customerId) {
      toast.error('No customer ID found!', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
      return;
    }

    setIsExecuting(true);
    try {
      const idempotencyKey = uuidv4();
      const transferParams: ICreateTransferParams = {
        amount: amount.toString(),
        developer_fee: '0',
        on_behalf_of: customerId,
        source: {
          currency: 'usdc',
          payment_rail: 'polygon',
          from_address: address,
        },
        destination: {
          currency: 'usd',
          payment_rail: 'wire',
          external_account_id: selectedBankId,
        },
        idempotencyKey,
      };
      const response = await createTransfer(transferParams);
      if (response.status === 400 || response.status === 401) {
        toast.error('Error while creating transfer!', {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
        setIsExecuting(false);
        return;
      }

      if (!response.data?.source_deposit_instructions?.to_address) {
        toast.error(`Error retrieving deposit address!`, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
        setIsExecuting(false);
        return;
      }

      const payTokenContract = getContract({
        address: circleUsdcAddress as `0x${string}`,
        chain: polygonAmoy,
        client,
      });

      const depositTransaction = prepareContractCall({
        contract: payTokenContract,
        method: 'function transfer(address to, uint256 amount)',
        params: [
          response.data.source_deposit_instructions.to_address,
          parseUnits(amount.toString(), 6),
        ],
      });

      const depositTx = await sendTx(depositTransaction);
      await waitForReceipt({
        client,
        chain: polygonAmoy,
        transactionHash: depositTx.transactionHash,
      });
      toast.success('Withdrawal initiated successfully!', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    } catch (error) {
      console.error('Error withdrawing to bank:', error);
      toast.error('Error withdrawing to bank. Please try again.', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    } finally {
      setIsExecuting(false);
    }
  }, [
    activeAccount,
    amount,
    customerId,
    createTransfer,
    sendTx,
    selectedBankId,
  ]);

  useEffect(() => {
    (async () => {
      const projectsWithSaleContract = projects.filter(
        (project: any) => project.sale_contract_address !== null
      );
      const projectsWithoutDeposit = await Promise.all(
        projectsWithSaleContract.map(async (project: any) => {
          const deposit = await getDepositForProjectId(project.id);
          if (deposit.data === null) {
            return project;
          }
        })
      );
      const filteredProjects = projectsWithoutDeposit.filter(
        (project) => project !== undefined
      );
      setProjectsWithoutWithdraws(filteredProjects);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projects]);

  useEffect(() => {
    (async () => {
      const address = activeAccount?.address;
      if (address) {
        try {
          const publicClient = createPublicClient({
            chain: viemPolygonAmoy,
            transport: http(),
          });
          const balance = await publicClient.readContract({
            address: circleUsdcAddress as `0x${string}`,
            abi: erc20ABI,
            functionName: 'balanceOf',
            args: [address as `0x${string}`],
          });
          const formattedBalance = Number(formatUnits(balance, 6));
          setAmount(formattedBalance);
          setAvailableAmount(formattedBalance);
        } catch (err) {
          console.log(
            'Something went wrong while retrieving user balance: ',
            err
          );
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeAccount]);

  return (
    <main className="flex flex-col justify-center p-6 bg-white rounded-lg border border-slate-300 max-w-[425px] z-50">
      <div className="flex flex-col w-full max-w-[377px]">
        <header className="flex justify-between items-center w-full text-lg font-semibold text-slate-900">
          <h1>Withdraw to Bank Account</h1>
          <img
            loading="lazy"
            src={cross}
            alt="Close"
            className="w-6 h-6 cursor-pointer"
            onClick={onCancel}
          />
        </header>
        <p className="mt-2 text-sm text-slate-500">
          Please ensure that all details are correct before proceeding, as this
          action cannot be undone.
        </p>

        <section className="flex flex-col mt-8 text-center">
          <h2 className="text-sm text-slate-900 font-medium">
            Available amount
          </h2>
          <p className="mt-1 text-3xl font-bold text-slate-900">
            $
            {availableAmount.toLocaleString('en-US', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </p>
          <p className="mt-1 text-base text-slate-600">
            {availableAmount.toLocaleString('en-US', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}{' '}
            USDC
          </p>
        </section>

        <form
          className="flex flex-col mt-8 text-sm"
          onSubmit={(e) => {
            e.preventDefault();
            handleBankWithdrawalWithBridge();
          }}
        >
          {/* <div className="">
            <label htmlFor="sale" className="font-medium text-black">
              Sale
            </label>
            <div className="mt-2 w-full bg-white px-3 py-2 border border-slate-300 rounded-md text-slate-900 cursor-pointer">
              <select
                id="sale"
                value={selectedProjectId}
                onChange={handleSaleChange}
                className="w-full bg-white cursor-pointer"
              >
                <option value="" disabled>
                  Select a sale
                </option>
                {projectsWithoutWithdraws.map((project: any) => (
                  <option key={project.id} value={project.id}>
                    {project.id}
                  </option>
                ))}
              </select>
            </div>
          </div> */}

          <label
            htmlFor="withdrawAmount"
            className="font-medium text-black mt-6"
          >
            Withdraw Amount
          </label>
          <div className="mt-2 py-2 px-3 flex items-center border border-slate-300 rounded-md">
            <span className=" text-slate-900">$</span>
            <span className="w-[1.5px] h-5 bg-black mx-1"></span>
            <input
              type="number"
              id="withdrawAmount"
              value={amount}
              className="flex-1 text-slate-400 bg-transparent border-none outline-none"
              placeholder="0"
              aria-label="Withdraw Amount"
              onChange={(e) => {
                const value = e.target.value;
                if (Number(value) <= availableAmount) {
                  setAmount(Number(value));
                } else {
                  setAmount(availableAmount);
                }
              }}
            />
          </div>
          <div className="flex items-center mt-2">
            <img
              src={availableAmount === amount ? checked : unchecked}
              alt={availableAmount === amount ? 'unchecked' : 'checked'}
              className="w-4 h-4 cursor-pointer"
              onClick={() => setAmount(availableAmount)}
            />
            <label htmlFor="withdrawAll" className="ml-2 text-black">
              Withdraw all available amount
            </label>
          </div>
          <div className="mt-6">
            <label htmlFor="withdrawAccount" className="font-medium text-black">
              Withdraw Account
            </label>
            <div className="mt-2 w-full bg-white px-3 py-2 border border-slate-300 rounded-md text-slate-900 cursor-pointer">
              <select
                id="withdrawAccount"
                className="w-full bg-white cursor-pointer"
                value={selectedBankId}
                onChange={handleBankChange}
              >
                <option value="" disabled>
                  Select a bank account
                </option>
                {registeredBanks.map((bank) => (
                  <option key={bank.id} value={bank.id}>
                    {`Account ****${bank.accountNumber.slice(-4)} · ${bank.name.toUpperCase()}`}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </form>

        <div className="flex gap-2.5 mt-8">
          <button
            type="button"
            onClick={onCancel}
            className="flex-1 py-2 border border-slate-300 rounded-md text-slate-900"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="flex-1 py-2 bg-slate-900 text-white rounded-md"
            onClick={handleBankWithdrawalWithBridge}
          >
            {isExecuting ? 'Withdrawing...' : 'Withdraw'}
          </button>
        </div>
      </div>
    </main>
  );
};

export default WithdrawComponent;
