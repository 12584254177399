import React from 'react';

import { chevronLeft, chevronRight, arrowDown } from '../../assets';

const Pagination: React.FC = () => {
  return (
    <footer className="flex w-full items-center bg-white border-b border-slate-200">
      <div className="flex gap-2 items-center pl-4 text-sm text-slate-900">
        <label htmlFor="contractsPerPage" className="py-4">
          Contracts per page:
        </label>
        <select
          id="contractsPerPage"
          className="min-h-[48px] bg-transparent border-none appearance-none px-2 py-3"
        >
          <option>100</option>
        </select>
        <img loading="lazy" src={arrowDown} className="w-4" alt="" />
        <div className="w-0 h-12 border-l border-neutral-200" />
      </div>

      <div className="flex-1 pl-4 text-sm min-w-[240px] text-slate-900">
        1 – 100 of 100 contracts
      </div>

      <nav className="flex items-center" aria-label="Pagination">
        <div className="flex items-center border-l border-neutral-200 mr-2">
          <select
            aria-label="Select page"
            className="min-h-[48px] bg-transparent border-none appearance-none px-2 py-3 text-sm text-slate-900"
          >
            <option>1</option>
          </select>
          <img loading="lazy" src={arrowDown} className="w-4" alt="" />
          <span className="py-4 text-sm text-slate-900 ml-2">of 10 pages</span>
        </div>
        <div className="border-l border-r border-neutral-200 p-4">
          <img
            loading="lazy"
            src={chevronLeft}
            className="w-4"
            alt="Pagination controls"
          />
        </div>
        <div className="border-r border-neutral-200 p-4">
          <img
            loading="lazy"
            src={chevronRight}
            className="w-4"
            alt="Pagination controls"
          />
        </div>
      </nav>
    </footer>
  );
};

export default Pagination;
