import { useState, useEffect, useMemo, useRef } from 'react';
import debounce from 'lodash.debounce';
import { Link } from 'react-router-dom';
import { DesktopLayout } from '../../components/Layouts';
import { Pagination } from '../../components/Pagination';
import {
  search,
  repeat,
  archive,
  downlaod,
  circleSlashed,
  trash,
  checked,
  unchecked,
  arrowUpDown,
  clock,
  arrowDown,
  activeRepeat,
  activeArchive,
  activeDownload,
  activeCircleSlashed,
  activeTrash,
  archiveRestore,
  activeArchiveRestore,
  restore,
  activeRestore,
} from '../../assets';
import useDocusignApi from '../../hooks/useDocusign';
import useAllProjects from '../../hooks/useAllProjects';
import type { TableData } from '../../components/Table/Table';
import { formatContractsDate } from '../../utils';
import { NoContentFound } from '../../components/NoContentFound';

export default function Contracts() {
  const { projects } = useAllProjects();
  const { getTemplates, getTemplateName } = useDocusignApi();

  const headerRef = useRef<HTMLDivElement | null>(null);
  const [selectedSection, setSelectedSection] = useState<string>('Owner');
  const [columnWidth, setColumnWidth] = useState<number>(0);
  const [tabs, setTabs] = useState([
    { label: 'Active', isActive: true },
    { label: 'Archived', isActive: false },
    { label: 'Voided', isActive: false },
    { label: 'Deleted', isActive: false },
  ]);
  const [templateHash, setTemplateHash] = useState<{
    [templateName: string]: any;
  }>({});
  const [contracts, setContracts] = useState<{
    [projectName: string]: any;
  }>({});
  const [contractsTableData, setContractsTableData] = useState<TableData[]>([]);
  const [allCheckedActive, setAllCheckedActive] = useState(false);
  const [allCheckedArchived, setAllCheckedArchived] = useState(false);
  const [allCheckedVoided, setAllCheckedVoided] = useState(false);
  const [allCheckedDeleted, setAllCheckedDeleted] = useState(false);
  const [selectedContracts, setSelectedContracts] = useState<{
    [id: string]: boolean;
  }>({});
  const [archivedContracts, setArchivedContracts] = useState<TableData[]>([]);
  const [voidedContracts, setVoidedContracts] = useState<TableData[]>([]);
  const [deletedContracts, setDeletedContracts] = useState<TableData[]>([]);
  const [deletedSource, setDeletedSource] = useState<{
    [id: string]: string;
  }>({});
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [activeContracts, setActiveContracts] = useState<TableData[]>([]);

  const debouncedSearch = useMemo(
    () => debounce((query: string) => setSearchQuery(query), 300),
    []
  );

  const toggleSection = (section: string) => {
    setSelectedSection(selectedSection === section ? selectedSection : section);
  };

  const handleTabClick = (index: number) => {
    const updatedTabs = tabs.map((tab, i) => ({
      ...tab,
      isActive: i === index,
    }));
    setTabs(updatedTabs);
    setSelectedContracts({});

    if (tabs[index].label === 'Active') {
      setAllCheckedArchived(false);
      setAllCheckedVoided(false);
      setAllCheckedDeleted(false);
    } else if (tabs[index].label === 'Archived') {
      setAllCheckedActive(false);
      setAllCheckedVoided(false);
      setAllCheckedDeleted(false);
    } else if (tabs[index].label === 'Voided') {
      setAllCheckedActive(false);
      setAllCheckedArchived(false);
      setAllCheckedDeleted(false);
    } else if (tabs[index].label === 'Deleted') {
      setAllCheckedActive(false);
      setAllCheckedArchived(false);
      setAllCheckedVoided(false);
    }
  };

  const toggleAll = () => {
    const isArchiveTabActive = tabs.find(
      (tab) => tab.label === 'Archived'
    )?.isActive;
    const isVoidedTabActive = tabs.find(
      (tab) => tab.label === 'Voided'
    )?.isActive;
    const isDeletedTabActive = tabs.find(
      (tab) => tab.label === 'Deleted'
    )?.isActive;

    const currentTableData = isArchiveTabActive
      ? archivedContracts
      : isVoidedTabActive
        ? voidedContracts
        : isDeletedTabActive
          ? deletedContracts
          : contractsTableData;

    if (
      isArchiveTabActive
        ? allCheckedArchived
        : isVoidedTabActive
          ? allCheckedVoided
          : isDeletedTabActive
            ? allCheckedDeleted
            : allCheckedActive
    ) {
      setSelectedContracts({});
    } else {
      const allSelected = currentTableData.reduce(
        (acc, contract) => {
          acc[contract.id] = true;
          return acc;
        },
        {} as { [id: string]: boolean }
      );
      setSelectedContracts(allSelected);
    }

    if (isArchiveTabActive) {
      setAllCheckedArchived(!allCheckedArchived);
    } else if (isVoidedTabActive) {
      setAllCheckedVoided(!allCheckedVoided);
    } else if (isDeletedTabActive) {
      setAllCheckedDeleted(!allCheckedDeleted);
    } else {
      setAllCheckedActive(!allCheckedActive);
    }
  };

  const toggleContract = (id: string) => {
    const isArchiveTabActive = tabs.find(
      (tab) => tab.label === 'Archived'
    )?.isActive;
    const isVoidedTabActive = tabs.find(
      (tab) => tab.label === 'Voided'
    )?.isActive;
    const isDeletedTabActive = tabs.find(
      (tab) => tab.label === 'Deleted'
    )?.isActive;

    setSelectedContracts((prev) => {
      const updated = { ...prev, [id]: !prev[id] };
      const areAllDeselected = Object.values(updated).every(
        (isSelected) => !isSelected
      );
      if (areAllDeselected) {
        if (isArchiveTabActive) {
          setAllCheckedArchived(false);
        } else if (isVoidedTabActive) {
          setAllCheckedVoided(false);
        } else if (isDeletedTabActive) {
          setAllCheckedDeleted(false);
        } else {
          setAllCheckedActive(false);
        }
        return {};
      }

      const currentTableData = isArchiveTabActive
        ? archivedContracts
        : isVoidedTabActive
          ? voidedContracts
          : isDeletedTabActive
            ? deletedContracts
            : contractsTableData;
      if (isArchiveTabActive) {
        setAllCheckedArchived(
          Object.keys(updated).length === currentTableData.length &&
            Object.values(updated).every(Boolean)
        );
      } else if (isVoidedTabActive) {
        setAllCheckedVoided(
          Object.keys(updated).length === currentTableData.length &&
            Object.values(updated).every(Boolean)
        );
      } else if (isDeletedTabActive) {
        setAllCheckedDeleted(
          Object.keys(updated).length === currentTableData.length &&
            Object.values(updated).every(Boolean)
        );
      } else {
        setAllCheckedActive(
          Object.keys(updated).length === currentTableData.length &&
            Object.values(updated).every(Boolean)
        );
      }
      return updated;
    });
  };

  const archiveSelectedContracts = () => {
    const toArchive = contractsTableData
      .filter((contract) => selectedContracts[contract.id])
      .map((contract) => ({
        ...contract,
        archivedAt: new Date().toISOString(),
      }));

    const remainingContracts = contractsTableData.filter(
      (contract) => !selectedContracts[contract.id]
    );

    const updatedArchivedContracts = [...archivedContracts, ...toArchive];
    setArchivedContracts(updatedArchivedContracts);
    localStorage.setItem(
      'archivedContracts',
      JSON.stringify(updatedArchivedContracts)
    );

    setSelectedContracts({});
    setContractsTableData(remainingContracts);
    setAllCheckedActive(false);
  };

  const unarchiveSelectedContracts = () => {
    const toUnarchive = archivedContracts
      .filter((contract) => selectedContracts[contract.id])
      .map((contract) => {
        const { archivedAt, ...rest } = contract;
        return rest;
      });

    const remainingArchivedContracts = archivedContracts.filter(
      (contract) => !selectedContracts[contract.id]
    );

    const updatedActiveContracts = [...contractsTableData, ...toUnarchive];
    setContractsTableData(updatedActiveContracts);
    setArchivedContracts(remainingArchivedContracts);

    localStorage.setItem(
      'archivedContracts',
      JSON.stringify(remainingArchivedContracts)
    );

    setSelectedContracts({});
    setAllCheckedArchived(false);
  };

  const voidSelectedContracts = () => {
    const toVoid = contractsTableData
      .filter((contract) => selectedContracts[contract.id])
      .map((contract) => ({
        ...contract,
        voidedAt: new Date().toISOString(),
      }));

    const remainingContracts = contractsTableData.filter(
      (contract) => !selectedContracts[contract.id]
    );

    const updatedVoidedContracts = [...voidedContracts, ...toVoid];
    setVoidedContracts(updatedVoidedContracts);
    localStorage.setItem(
      'voidedContracts',
      JSON.stringify(updatedVoidedContracts)
    );

    setSelectedContracts({});
    setContractsTableData(remainingContracts);
    setAllCheckedActive(false);
  };

  const deleteSelectedContracts = () => {
    const isArchiveTabActive = tabs.find(
      (tab) => tab.label === 'Archived'
    )?.isActive;
    const isVoidedTabActive = tabs.find(
      (tab) => tab.label === 'Voided'
    )?.isActive;

    const toDelete = isArchiveTabActive
      ? archivedContracts.filter((contract) => selectedContracts[contract.id])
      : isVoidedTabActive
        ? voidedContracts.filter((contract) => selectedContracts[contract.id])
        : contractsTableData.filter(
            (contract) => selectedContracts[contract.id]
          );

    const toDeleteWithDeletedAt = toDelete.map((contract) => ({
      ...contract,
      deletedAt: new Date().toISOString(),
    }));

    const remainingContracts = isArchiveTabActive
      ? archivedContracts.filter((contract) => !selectedContracts[contract.id])
      : isVoidedTabActive
        ? voidedContracts.filter((contract) => !selectedContracts[contract.id])
        : contractsTableData.filter(
            (contract) => !selectedContracts[contract.id]
          );

    const updatedDeletedContracts = [
      ...deletedContracts,
      ...toDeleteWithDeletedAt,
    ];

    const updatedDeletedSource = { ...deletedSource };
    toDelete.forEach((contract) => {
      updatedDeletedSource[contract.id] = isArchiveTabActive
        ? 'Archived'
        : isVoidedTabActive
          ? 'Voided'
          : 'Active';
    });

    setDeletedSource(updatedDeletedSource);
    setDeletedContracts(updatedDeletedContracts);
    localStorage.setItem(
      'deletedContracts',
      JSON.stringify(updatedDeletedContracts)
    );

    if (isArchiveTabActive) {
      setArchivedContracts(remainingContracts);
      localStorage.setItem(
        'archivedContracts',
        JSON.stringify(remainingContracts)
      );
    } else if (isVoidedTabActive) {
      setVoidedContracts(remainingContracts);
      localStorage.setItem(
        'voidedContracts',
        JSON.stringify(remainingContracts)
      );
    } else {
      setContractsTableData(remainingContracts);
    }

    setSelectedContracts({});
    setAllCheckedActive(false);
  };

  const undeleteSelectedContracts = () => {
    const toUndelete = deletedContracts.filter(
      (contract) => selectedContracts[contract.id]
    );

    const remainingDeletedContracts = deletedContracts.filter(
      (contract) => !selectedContracts[contract.id]
    );

    const updatedActiveContracts = [...contractsTableData];
    const updatedArchivedContracts = [...archivedContracts];
    const updatedVoidedContracts = [...voidedContracts];

    toUndelete.forEach((contract) => {
      const source = deletedSource[contract.id];
      const { deletedAt, ...rest } = contract;
      if (source === 'Archived') {
        updatedArchivedContracts.push(rest);
      } else if (source === 'Voided') {
        updatedVoidedContracts.push(rest);
      } else {
        updatedActiveContracts.push(rest);
      }
    });

    setContractsTableData(updatedActiveContracts);
    setArchivedContracts(updatedArchivedContracts);
    setVoidedContracts(updatedVoidedContracts);
    setDeletedContracts(remainingDeletedContracts);

    localStorage.setItem(
      'deletedContracts',
      JSON.stringify(remainingDeletedContracts)
    );
    localStorage.setItem(
      'archivedContracts',
      JSON.stringify(updatedArchivedContracts)
    );
    localStorage.setItem(
      'voidedContracts',
      JSON.stringify(updatedVoidedContracts)
    );

    setSelectedContracts({});
    setAllCheckedDeleted(false);
  };

  const filterArchivedContracts = (contractsArray: TableData[]) => {
    const archivedContractIds = archivedContracts.map(
      (contract) => contract.id
    );
    return contractsArray.filter(
      (contract) => !archivedContractIds.includes(contract.id)
    );
  };

  const filterVoidedContracts = (contractsArray: TableData[]) => {
    const voidedContractIds = voidedContracts.map((contract) => contract.id);
    return contractsArray.filter(
      (contract) => !voidedContractIds.includes(contract.id)
    );
  };

  const filterDeletedContracts = (contractsArray: TableData[]) => {
    const deletedContractIds = deletedContracts.map((contract) => contract.id);
    return contractsArray.filter(
      (contract) => !deletedContractIds.includes(contract.id)
    );
  };

  useEffect(() => {
    if (headerRef.current) {
      const width = headerRef.current.getBoundingClientRect().width;
      setColumnWidth(width);
    }
  }, []);

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  useEffect(() => {
    const filterContracts = (contractsArray: TableData[]) => {
      if (!searchQuery) return contractsArray;

      const lowercasedQuery = searchQuery.toLowerCase();
      return contractsArray.filter(
        (contract) =>
          contract.id.toLowerCase().includes(lowercasedQuery) ||
          contract.email.toLowerCase().includes(lowercasedQuery) ||
          contract.name.toLowerCase().includes(lowercasedQuery)
      );
    };

    if (tabs.find((tab) => tab.label === 'Active')?.isActive) {
      setContractsTableData(filterContracts(activeContracts));
    } else if (tabs.find((tab) => tab.label === 'Archived')?.isActive) {
      setArchivedContracts(filterContracts(archivedContracts));
    } else if (tabs.find((tab) => tab.label === 'Voided')?.isActive) {
      setVoidedContracts(filterContracts(voidedContracts));
    } else if (tabs.find((tab) => tab.label === 'Deleted')?.isActive) {
      setDeletedContracts(filterContracts(deletedContracts));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery, tabs, activeContracts]);

  useEffect(() => {
    getTemplates().then((result: any) => {
      if (!result || !result.envelopeTemplates) return;
      const templates: any[] = result.envelopeTemplates;
      const hash: { [templateName: string]: any } = {};
      templates.forEach((template) => (hash[template.name] = template));
      setTemplateHash(hash);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const archived = localStorage.getItem('archivedContracts');
    const voided = localStorage.getItem('voidedContracts');
    const deleted = localStorage.getItem('deletedContracts');
    if (archived) {
      setArchivedContracts(JSON.parse(archived));
    }
    if (voided) {
      setVoidedContracts(JSON.parse(voided));
    }
    if (deleted) {
      setDeletedContracts(JSON.parse(deleted));
    }
  }, []);

  useEffect(() => {
    if (projects.length > 0 && Object.values(templateHash).length > 0) {
      projects.forEach((project) => {
        const templateName = getTemplateName(`${project.name} template`);
        const template = templateHash[templateName];
        if (template) {
          setContracts((prev) => ({ ...prev, [templateName]: template }));
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projects, templateHash]);

  useEffect(() => {
    if (Object.values(contracts).length > 0) {
      const contractsArray = Object.values(contracts);
      const mappedTableData = contractsArray.map((contract) => ({
        date: formatContractsDate(contract.created, true),
        id: contract.templateId,
        email: contract.owner.email,
        timestamp: formatContractsDate(contract.lastModified),
        status: 'Sent',
        name: contract.name,
      }));

      const activeContracts = filterArchivedContracts(
        filterVoidedContracts(filterDeletedContracts(mappedTableData))
      );
      if (
        JSON.stringify(activeContracts) !== JSON.stringify(contractsTableData)
      ) {
        setActiveContracts(activeContracts);
        setContractsTableData(activeContracts);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contracts, archivedContracts, voidedContracts, deletedContracts]);

  const isArchiveTabActive = tabs.find(
    (tab) => tab.label === 'Archived'
  )?.isActive;

  const isVoidedTabActive = tabs.find(
    (tab) => tab.label === 'Voided'
  )?.isActive;

  const isDeletedTabActive = tabs.find(
    (tab) => tab.label === 'Deleted'
  )?.isActive;

  const iconButtons = isArchiveTabActive
    ? [
        {
          src:
            Object.values(selectedContracts).length > 0
              ? activeArchiveRestore
              : archiveRestore,
          alt: 'restore',
          onClick: unarchiveSelectedContracts,
        },
        {
          src:
            Object.values(selectedContracts).length > 0 ? activeTrash : trash,
          alt: 'trash',
          onClick: deleteSelectedContracts,
        },
      ]
    : isVoidedTabActive
      ? [
          {
            src:
              Object.values(selectedContracts).length > 0 ? activeTrash : trash,
            alt: 'trash',
            onClick: deleteSelectedContracts,
          },
        ]
      : isDeletedTabActive
        ? [
            {
              src:
                Object.values(selectedContracts).length > 0
                  ? activeRestore
                  : restore,
              alt: 'restore',
              onClick: undeleteSelectedContracts,
            },
          ]
        : [
            {
              src:
                Object.values(selectedContracts).length > 0
                  ? activeRepeat
                  : repeat,
              alt: 'repeat',
            },
            {
              src:
                Object.values(selectedContracts).length > 0
                  ? activeArchive
                  : archive,
              alt: 'archive',
              onClick: archiveSelectedContracts,
            },
            {
              src:
                Object.values(selectedContracts).length > 0
                  ? activeDownload
                  : downlaod,
              alt: 'download',
            },
            {
              src:
                Object.values(selectedContracts).length > 0
                  ? activeCircleSlashed
                  : circleSlashed,
              alt: 'void',
              onClick: voidSelectedContracts,
            },
            {
              src:
                Object.values(selectedContracts).length > 0
                  ? activeTrash
                  : trash,
              alt: 'delete',
              onClick: deleteSelectedContracts,
            },
          ];

  let headers: string[] = [];

  if (isArchiveTabActive) {
    headers = ['Contract Date', 'Sale ID', 'Owner', 'Archived At'];
  } else if (isVoidedTabActive) {
    headers = ['Contract Date', 'Sale ID', 'Owner', 'Voided At'];
  } else if (isDeletedTabActive) {
    headers = ['Contract Date', 'Sale ID', 'Owner', 'Deleted At'];
  } else {
    headers = [
      'Contract Date',
      'Sale ID',
      'Owner',
      'Last Updated',
      'Status',
      'Sale Status',
    ];
  }

  const filteredContractsTableData = isArchiveTabActive
    ? archivedContracts
    : isVoidedTabActive
      ? voidedContracts
      : isDeletedTabActive
        ? deletedContracts
        : contractsTableData;

  const renderCellContent = (row: TableData, header: string) => {
    switch (header) {
      case 'Contract Date':
        return row.date;
      case 'Sale ID':
        return row.id;
      case 'Owner':
        return row.email;
      case 'Last Updated':
        return row.timestamp;
      case 'Status':
        return (
          <div className="flex gap-2 items-center">
            <img loading="lazy" src={clock} alt={row.status} className="w-4" />
            <span>{row.status}</span>
          </div>
        );
      case 'Sale Status':
        return (
          <div className="flex justify-end items-center">
            <div className="flex gap-2 items-center border border-slate-300 rounded-md px-4 py-2 w-[124px]">
              <span className="flex-1">Select</span>
              <img loading="lazy" src={arrowDown} alt="" className="w-4" />
            </div>
          </div>
        );
      case 'Archived At':
        return row.archivedAt ? formatContractsDate(row.archivedAt) : '';
      case 'Voided At':
        return row.voidedAt ? formatContractsDate(row.voidedAt) : '';
      case 'Deleted At':
        return row.deletedAt ? formatContractsDate(row.deletedAt) : '';
      default:
        return '';
    }
  };

  return (
    <DesktopLayout>
      <main className="pt-24 sm:px-32 px-5 flex flex-col items-start">
        <header className="flex flex-col px-4 pt-4 pb-6 bg-white">
          <h1 className="text-xl text-slate-900 max-md:max-w-full">
            Sales Contract Management
          </h1>
          <p className="text-sm text-neutral-600 max-md:max-w-full">
            Track and manage the status of sales contracts, with sortable and
            filterable fields for easy pipeline management.
          </p>
        </header>

        <nav className="flex py-1.5 px-1.5 rounded-md bg-slate-100 text-slate-700">
          {tabs.map((tab, index) => (
            <button
              key={index}
              onClick={() => handleTabClick(index)}
              className={`px-3 py-1.5 rounded ${
                tab.isActive ? 'bg-white text-slate-900' : 'text-slate-700'
              }`}
            >
              {tab.label}
            </button>
          ))}
        </nav>

        <nav className="flex items-center justify-end gap-4 py-2 bg-white w-full">
          <div className="flex items-center gap-2 px-2 py-2.5 w-[280px] text-sm leading-none border rounded-md shadow-sm">
            <img
              loading="lazy"
              src={search}
              alt=""
              className="w-4 h-4 object-contain"
            />
            <input
              type="text"
              placeholder="Search for a sale"
              className="flex-1 text-slate-400 bg-transparent border-none outline-none placeholder-opacity-50"
              aria-label="Search for a sale"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                debouncedSearch(event.target.value);
              }}
            />
          </div>

          {iconButtons.map((button, index) => (
            <button
              key={index}
              className="flex justify-center items-center w-10 h-10 bg-white border rounded-md"
              onClick={button.onClick}
            >
              <img
                loading="lazy"
                src={button.src}
                alt={button.alt}
                className="w-4 h-4 object-contain"
              />
            </button>
          ))}
          <Link to="/sales/create">
            <button className="btn-primary">Start a sale</button>
          </Link>
        </nav>
        <section className="w-full overflow-x-auto">
          <div className="grid grid-cols-[56px_repeat(6,1fr)] gap-0 bg-slate-50 w-full text-sm font-semibold text-slate-900 whitespace-nowrap">
            <div className="flex items-center justify-center">
              <img
                loading="lazy"
                src={
                  isArchiveTabActive
                    ? allCheckedArchived
                      ? checked
                      : unchecked
                    : isVoidedTabActive
                      ? allCheckedVoided
                        ? checked
                        : unchecked
                      : isDeletedTabActive
                        ? allCheckedDeleted
                          ? checked
                          : unchecked
                        : allCheckedActive
                          ? checked
                          : unchecked
                }
                className="w-4 aspect-square mx-auto cursor-pointer"
                alt="check-box"
                onClick={toggleAll}
              />
            </div>

            {headers.map((header, index) => (
              <div
                key={index}
                ref={index === 0 ? headerRef : null}
                onClick={() => toggleSection(header)}
                className={`p-4 cursor-pointer flex justify-between items-center
                ${selectedSection === header ? 'bg-slate-300 text-black' : 'bg-slate-50'}`}
              >
                {header}
                {selectedSection === header && (
                  <img
                    src={arrowUpDown}
                    alt="Arrow"
                    className="w-6 h-6 object-contain ml-2"
                  />
                )}
              </div>
            ))}
          </div>

          {filteredContractsTableData.length === 0 ? (
            <NoContentFound contentTitle="contracts" />
          ) : (
            <>
              <section className="w-full">
                {filteredContractsTableData.map((row, index) => (
                  <section
                    className="grid grid-cols-[56px_repeat(6,1fr)] gap-0 bg-white w-full text-sm whitespace-nowrap border-b-[1px]"
                    key={index}
                  >
                    <div className="h-full flex items-center">
                      <img
                        loading="lazy"
                        src={selectedContracts[row.id] ? checked : unchecked}
                        alt="check-box"
                        className="w-4 aspect-square mx-auto cursor-pointer"
                        onClick={() => toggleContract(row.id)}
                      />
                    </div>

                    {headers.map((header, index) => (
                      <div
                        key={index}
                        className={`flex items-center mx-4 py-4 max-w-[${columnWidth}px] overflow-hidden text-ellipsis`}
                      >
                        {renderCellContent(row, header)}
                      </div>
                    ))}
                  </section>
                ))}
              </section>
              <Pagination />
            </>
          )}
        </section>
      </main>
    </DesktopLayout>
  );
}
