import * as React from 'react';

interface TestimonialProps {
  imageSrc: string;
  imageAlt: string;
  name: string;
  company: string;
  testimonial: string;
}

export const SuccessStories: React.FC = () => {
  const testimonials: TestimonialProps[] = [
    {
      imageSrc:
        'https://cdn.builder.io/api/v1/image/assets/TEMP/21afb0a1c52bf95014753cef6874f280e67b78c778ac477b23c9212b88d82f64?placeholderIfAbsent=true&apiKey=466c088da2e142d481f76ae5c06a8ddd',
      imageAlt: 'Profile picture of Ketut Arjuna',
      name: 'Ketut Arjuna',
      company: 'Paradise Island Investments',
      testimonial:
        'Selling our development projects through G8WY has been a game-changer. The process was efficient, and accepting cryptocurrency payments saved us on transaction fees.',
    },
    {
      imageSrc:
        'https://cdn.builder.io/api/v1/image/assets/TEMP/5bdda7a58e09eae921935202ae4027a8d2731489c0b8fcd79a0e66b31cdf337c?placeholderIfAbsent=true&apiKey=466c088da2e142d481f76ae5c06a8ddd',
      imageAlt: 'Profile picture of Maria Johnson',
      name: 'Maria Johnson',
      company: 'Real Estate Manager',
      testimonial:
        'Thanks to G8WY, we sold multiple properties in record time. The transparent process and excellent support team made everything seamless.',
    },
    {
      imageSrc:
        'https://cdn.builder.io/api/v1/image/assets/TEMP/515c300c415792d81a76fec14f4c630068251d1f64898f1fb23308211fa2372f?placeholderIfAbsent=true&apiKey=466c088da2e142d481f76ae5c06a8ddd',
      imageAlt: 'Profile picture of Andreas Santoso',
      name: 'Andreas Santoso',
      company: 'Bought a Bali Villa using stablecoins.',
      testimonial:
        'I bought my first investment property through G8WY, and it was a fantastic experience! The process was smooth, and the option to pay with crypto provided great flexibility.',
    },
  ];

  const TestimonialCard: React.FC<TestimonialProps> = ({
    imageSrc,
    imageAlt,
    name,
    company,
    testimonial,
  }) => (
    <article className="flex flex-col flex-1 shrink p-6 rounded-md basis-0 bg-stone-300 bg-opacity-10 min-w-[240px] max-md:px-5 max-md:max-w-full">
      <header className="flex gap-2 self-start">
        <img
          loading="lazy"
          src={imageSrc}
          alt={imageAlt}
          className="object-contain shrink-0 self-start w-14 aspect-square"
        />
        <div className="flex flex-col justify-between">
          <h3 className="text-2xl font-semibold tracking-normal leading-none">
            {name}
          </h3>
          <p className="mt-1.5 text-base leading-none">{company}</p>
        </div>
      </header>
      <blockquote className="flex-1 shrink gap-2 self-stretch mt-6 w-full text-base leading-6 max-md:max-w-full">
        "{testimonial}"
      </blockquote>
    </article>
  );

  return (
    <section className="flex gap-10 py-6 text-slate-900">
      <div className="flex flex-col flex-1 shrink w-full basis-0 min-w-[240px] max-md:max-w-full">
        <h2 className="text-5xl font-extrabold tracking-tight leading-none max-md:max-w-full max-md:text-4xl">
          Success Stories
        </h2>
        <div className="flex flex-wrap gap-6 mt-6 w-full max-md:max-w-full">
          {testimonials.map((testimonial, index) => (
            <TestimonialCard key={index} {...testimonial} />
          ))}
        </div>
      </div>
    </section>
  );
};
