import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useActiveAccount } from 'thirdweb/react';
import { getUserEmail } from 'thirdweb/wallets/in-app';
import { client } from '../../config/thirdwebConfig';
import useAllProjects from '../../hooks/useAllProjects';

const Profile: React.FC = () => {
  const activeAccount = useActiveAccount();
  const { projects } = useAllProjects();

  const [userEmail, setUserEmail] = useState<string | undefined>(undefined);
  const [companyName, setCompanyName] = useState<string>(
    localStorage.getItem(`${activeAccount?.address}-companyName`) || ''
  );
  const [storedCompanyName, setStoredCompanyName] = useState<string>('');

  const [isSaved, setIsSaved] = useState(
    localStorage.getItem(`${activeAccount?.address}-companyName`) !== ''
  );

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    setIsSaved(false);
    if (id === 'companyName') setCompanyName(value);
  };

  const handleSave = useCallback(() => {
    if (companyName === '') return;
    localStorage.setItem(`${activeAccount?.address}-companyName`, companyName);
    setIsSaved(true);
  }, [activeAccount, companyName]);

  const formFields = [
    {
      label: 'Company Name',
      id: 'companyName',
      type: 'text',
      value: companyName,
      placeholder: 'Set company name here',
      editable: true,
    },
    {
      label: 'Email',
      id: 'email',
      type: 'email',
      value: userEmail,
      placeholder: '',
      editable: false,
    },
    {
      label: 'Address',
      id: 'address',
      type: 'text',
      value: activeAccount?.address,
      placeholder: '',
      editable: false,
    },
    // { label: 'Password', id: 'password', type: 'password', value: '*********' },
  ];

  const dontAllowChanges = useMemo(
    () =>
      companyName === '' ||
      storedCompanyName !== '' ||
      companyName === storedCompanyName ||
      activeAccount === undefined ||
      isSaved,
    [companyName, storedCompanyName, activeAccount, isSaved]
  );

  useEffect(() => {
    if (activeAccount) {
      getUserEmail({ client }).then((email) => setUserEmail(email));
    }
  }, [activeAccount]);

  useEffect(() => {
    const findCompanyName = (projects: any[]): string | null => {
      for (const project of projects) {
        if (project.company) {
          return project.company;
        }
      }
      return null;
    };

    const companyName = findCompanyName(projects);
    if (companyName) {
      setStoredCompanyName(companyName);
      setCompanyName(companyName);
      localStorage.setItem(
        `${activeAccount?.address}-companyName`,
        companyName
      );
    }
  }, [projects, activeAccount]);

  return (
    <section className="flex flex-1 flex-col py-2 gap-10">
      <section>
        <h1 className="text-base font-semibold">Profile</h1>

        <form className="flex flex-col mt-2 text-sm">
          {formFields.map((field) => (
            <div key={field.id} className="flex items-center mt-4">
              <label htmlFor={field.id} className="font-medium w-1/3">
                {field.label}
              </label>
              <input
                type={field.type}
                id={field.id}
                value={field.value}
                placeholder={field.placeholder}
                onChange={field.editable ? handleInputChange : undefined}
                readOnly={!field.editable}
                className={`py-2 px-3 w-full border bg-white border-slate-300 rounded-md ${
                  field.editable ? '' : 'cursor-not-allowed'
                }`}
              />
            </div>
          ))}
          {/* <button
            type="button"
            className="mt-4 py-2 px-4 bg-slate-100 text-slate-900 rounded-md"
          >
            Change password
          </button> */}
        </form>

        {/* <section className="mt-6">
          <h2 className="font-medium">Delete account</h2>
          <p className="mt-1 text-slate-700">
            Permanently removes your account and all data. Ensure you have
            backed up important information. This action cannot be undone.
          </p>
          <button className="mt-4 py-2 px-4 bg-red-500 text-white rounded-md">
            Delete account
          </button>
        </section> */}
      </section>

      <div className="self-end">
        <button
          type="button"
          className={`py-2 px-4 bg-slate-900 rounded-md ${dontAllowChanges ? 'bg-opacity-20' : 'text-white'}`}
          onClick={handleSave}
          disabled={dontAllowChanges}
        >
          {isSaved ? 'Saved' : 'Save changes'}
        </button>
      </div>
    </section>
  );
};

export default Profile;
