import React from 'react';
import { arrowDown } from '../../assets';

interface ISaleDetailItem {
  label: string;
  value: string;
}

interface ISaleTableData {
  customer: string;
  saleId: string;
  saleType: string;
  status: string;
  saleDate: string;
  saleFees: string;
  saleAmount: string;
  detailItems: ISaleDetailItem[];
}

interface SalesTableProps {
  salesHeaders: string[];
  saleTableData: ISaleTableData[];
  onDetailClick: (index: number) => void;
  toggleDetailIndex: number;
  toogleDetail: boolean;
}

interface ISaleDetailItem {
  label: string;
  value: string;
}

interface DetailRowProps {
  detailItems: ISaleDetailItem[];
}

export const DetailRow: React.FC<DetailRowProps> = ({ detailItems }) => {
  return (
    <section className="flex flex-wrap items-start pl-12 text-sm max-md:pl-5 border-b border-slate-200">
      <div className="flex flex-col flex-1 min-w-[240px] max-md:w-full">
        <div className="border-t border-slate-200" />
        <div className="flex flex-col px-4 w-full">
          {detailItems.map((item, index) => (
            <div key={index} className="flex flex-wrap gap-4 py-2 w-full">
              <div className="text-neutral-900 w-[140px]">{item.label}:</div>
              <div className="text-right text-neutral-900">{item.value}</div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

const SalesTable: React.FC<SalesTableProps> = ({
  salesHeaders,
  saleTableData,
  onDetailClick,
  toggleDetailIndex,
  toogleDetail,
}) => {
  return (
    <section className="w-full overflow-x-auto">
      <div className="grid grid-cols-[56px_repeat(7,1fr)] gap-0 bg-slate-50 w-full text-sm font-semibold text-slate-900 whitespace-nowrap">
        <div />

        {salesHeaders.map((header, index) => (
          <div
            key={index}
            className="p-4 cursor-pointer flex justify-between items-center bg-slate-50"
          >
            {header}
          </div>
        ))}
      </div>

      <section className="w-full">
        {saleTableData.map((row, index) => (
          <section key={index}>
            <section
              className={`grid grid-cols-[56px_repeat(7,1fr)] gap-0 bg-white w-full text-sm whitespace-nowrap ${
                toggleDetailIndex !== index && 'border-b'
              }`}
            >
              <div
                className="h-full flex items-center cursor-pointer"
                onClick={() => onDetailClick(index)}
              >
                <img
                  loading="lazy"
                  src={arrowDown}
                  alt="arrow-down"
                  className="w-4 aspect-square mx-auto"
                />
              </div>

              <div className="flex items-center mx-4 py-4 overflow-hidden text-ellipsis">
                {row.customer}
              </div>
              <div className="flex items-center mx-4 py-4 overflow-hidden text-ellipsis">
                {row.saleId}
              </div>
              <div className="flex items-center mx-4 py-4 overflow-hidden text-ellipsis">
                {row.saleType}
              </div>
              <div className="flex items-center mx-4 py-4 overflow-hidden text-ellipsis">
                {row.status}
              </div>
              <div className="flex items-center mx-4 py-4 overflow-hidden text-ellipsis">
                {row.saleDate}
              </div>
              <div className="flex items-center mx-4 py-4 overflow-hidden text-ellipsis">
                {row.saleFees}
              </div>
              <div className="flex items-center mx-4 py-4 overflow-hidden text-ellipsis">
                {row.saleAmount}
              </div>
            </section>

            {toogleDetail && toggleDetailIndex === index && (
              <DetailRow detailItems={row.detailItems} />
            )}
          </section>
        ))}
      </section>
    </section>
  );
};

export default SalesTable;
